import {computed, ref, ssrRef} from '@nuxtjs/composition-api'
import {ProductDetailType} from '~/types/ProductData'
import {mapSavedCartItemToProductDetails} from '~/utils/mapProductToProductDetails'
import {getSavedCarts} from '~/modules/savedCarts/composables/useSavedCarts/getSavedCarts';
import {SavedCart} from '~/types/SavedCart'
import {addSavedCart} from "~/modules/savedCarts/composables/useSavedCarts/addSavedCart";
import {dumpSavedCartToCart} from "~/modules/savedCarts/composables/useSavedCarts/dumpSavedCartToCart";
import {deleteSavedCartById} from "~/modules/savedCarts/composables/useSavedCarts/deleteSavedCart";
import {Pagination, useCart} from "~/composables";
import {useConfirmationModal} from "~/composables/useConfirmationModal";
import productGetters from "~/modules/catalog/product/getters/productGetters";
import {perPageOptions} from "~/modules/catalog/category/composables/useFacet/perPageOptions";


const details: ProductDetailType[] = [
  ProductDetailType.ARTICLE_NUMBER,
  ProductDetailType.ORDERED_QUANTITY,
  ProductDetailType.PRICE,
  ProductDetailType.PRICE_TOTAL
]

const defaultDumpCartErrorMessageValue = 'Der gespeicherte Warenkorb konnte nicht in den aktuellen Warenkorb überführt werden. Falls dieses Problem bestehen bleibt, kontaktieren Sie bitte den Support.';
const defaultDumpCartSuccessMessageValue = 'Der gespeicherte Warenkorb wurde in den aktuellen Warenkorb überführt.';
const dumpCartErrorNotificationTitleValue = 'Fehler';
const defaultSuccessNotificationTitleValue = 'Erfolg!';
const savedCartToCartErrorTitle = (cartName) => `Fehler: Der gespeicherte Warenkorb "${cartName}" konnte nicht, oder nicht vollständig, in den aktuellen Warenkorb überführt werden.`;
const savedCartToCartSuccessTitle = (cartName) => `Erfolg! Der gespeicherte Warenkorb "${cartName}" wurde in den aktuellen Warenkorb überführt.`;
const defaultAddCartErrorMessageValue = 'Da ist leider was schief gegangen. Tut uns sehr leid.';

const noProductsInCartGraphQlErrorString = "Could not fetch quote: No products in customer's cart.";
const cartContainsOnlySpecialProductGraphQlErrorString = "Could not save shopping list: The cart contains only one special product with custom options and cannot be saved.";

export const useSavedCarts = () => {
  const {getSavedCartsQuery} = getSavedCarts();
  const {loadTotalQty, load: loadCart} = useCart();

  const cartName = ref('')
  const isValidCartName = ref(true)
  const isOpenTooltipSaveCart = ref(false)

  const notificationTitle = ref('')
  const notificationMessage = ref('')
  const isVisibleNotification = ref(false)
  const notificationType = ref('')
  const items = ssrRef([]);
  const rawSavedCarts = ssrRef([]);

  const loading = ref(false)

  async function fetchSavedCarts(variables = {pageSize: null, currentPage: null}) {
    loading.value = true

    let result = await getSavedCartsQuery({
      "pageSize": variables.pageSize || pagination.value.itemsPerPage,
      "currentPage": variables.currentPage || pagination.value.currentPage
    });

    if (result['errors'] !== undefined) {
      result['errors'].forEach(error => console.log(`graphql error: ${error.message}`));
    }

    const savedCartEntities = result.data?.['shoppingLists']['entities'] || [];
    rawSavedCarts.value = result.data?.['shoppingLists'] || [];

    const savedCartsData = [];
    savedCartEntities.forEach(savedCartEntity =>
      savedCartsData.push(parseCart(savedCartEntity))
    )
    items.value = savedCartsData;

    loading.value = false
  }

  const pagination = computed(() => getPagination(rawSavedCarts.value));

  const getPagination = (shoppingLists): Pagination => ({
    currentPage: shoppingLists?.page_info?.current_page || 1,
    totalPages: shoppingLists?.page_info?.total_pages || 1,
    totalItems: shoppingLists?.total_count || 0,
    itemsPerPage: shoppingLists?.page_info?.page_size || perPageOptions[0],
    pageOptions: perPageOptions,
  });

  const paginatedSavedCarts = computed(() => items.value.map(cart => ({
    name: cart.name,
    orderDate: cart.created_at,
    id: cart.id,
    items: cart.items.map(item => ({
      sku: item.product.sku,
      name: item.product.name,
      url: productGetters.getUrl(item.product),
      text: item.product.description.html,
      imgSrc: productGetters.getProductThumbnailImage(item.product),
      details: mapSavedCartItemToProductDetails(item, details),
      error: item.error
    })),
  })))


  const {mutation: dumpSavedCartToCartMutation} = dumpSavedCartToCart();

  async function addSavedCartToCart(cartId, cartName) {
    loading.value = true

    const result = await dumpSavedCartToCartMutation({
      "listId": cartId
    });

    // @ts-ignore
    const successMessage = result.data.shoppingListToCart.success_message
    // @ts-ignore
    const errorMessage = result.data.shoppingListToCart.error_message

    if(errorMessage !== null){
      isVisibleNotification.value = true
      notificationTitle.value = savedCartToCartErrorTitle(cartName)
      notificationMessage.value = errorMessage
      notificationType.value = 'error'
      loading.value = false

      await loadCart({})
      await loadTotalQty()

      return
    }

    if(successMessage !== null){
      isVisibleNotification.value = true
      notificationTitle.value = savedCartToCartSuccessTitle(cartName)
      notificationMessage.value = successMessage
      notificationType.value = 'success'
    }

    loading.value = false

    await loadCart({})
    await loadTotalQty()
  }

  const {mutation: addSavedCartMutation} = addSavedCart();

  async function saveCart() {

    if (cartName.value === '') {
      isValidCartName.value = false
      return
    }

    const result = await addSavedCartMutation({
      "listName": cartName.value
    });

    if (result['errors'] !== undefined) {
      if (result['errors'][0]['message'] === noProductsInCartGraphQlErrorString) {
        fillNotificationParameters(
          dumpCartErrorNotificationTitleValue,
          `Warenkorb "${cartName.value}" konnte nicht gespeichert werden, weil sich keine Produkte im aktuellen Warenkorb befinden.`,
          'error'
        );
      } else if (result['errors'][0]['message'] === cartContainsOnlySpecialProductGraphQlErrorString) {
        fillNotificationParameters(
          dumpCartErrorNotificationTitleValue,
          `Warenkorb "${cartName.value}" konnte nicht gespeichert werden. Der Warenkorb enthält nur einen Sonderposter-Artikel und kann nicht gespeichert werden.`,
          'error'
        );
      } else {
        result['errors'].forEach(error => console.log(`graphql error: ${error.message}`));
        fillNotificationParameters(
          dumpCartErrorNotificationTitleValue,
          defaultAddCartErrorMessageValue,
          'error'
        )
      }
    } else {
      notificationTitle.value = defaultSuccessNotificationTitleValue
      notificationMessage.value = `Warenkorb "${cartName.value}" wurde erfolgreich gespeichert.`
      notificationType.value = 'success'
    }
    isVisibleNotification.value = true
    resetCartName()

    function fillNotificationParameters(notificationTitleParam, notificationMessageParam, notificationTypeParam) {
      notificationTitle.value = notificationTitleParam;
      notificationMessage.value = notificationMessageParam;
      notificationType.value = notificationTypeParam;
    }
  }

  const {deleteSavedCartMutation} = deleteSavedCartById();

  function confirmDeleteSavedCart(name, cartId) {
    const {setConfirmationModal, showConfirmationModal} = useConfirmationModal()

    const confirmationModal = ({
      title: `Möchten Sie den gespeicherten Warenkorb \"${name.trim()}\" wirklich löschen?`,
      agreeButtonTextScreenReader: `${name} löschen bestätigen`,
      cancelButtonTextScreenReader: `${name} löschen abbrechen`,
      closeHandler: () => showConfirmationModal(false),
      confirmHandler: () => deleteSavedCart(cartId)
    })

    setConfirmationModal(confirmationModal)
  }

  async function deleteSavedCart(cartId) {

    const result = await deleteSavedCartMutation({
      "listId": cartId
    });

    if (result['errors'] !== undefined) {
      result['errors'].forEach(error => console.log(`graphql error: ${error.message}`));
    }

    items.value = items.value.filter(cart => cart.id != cartId)
    await fetchSavedCarts()
  }

  function resetCartName() {
    cartName.value = ''
    isValidCartName.value = true
    isOpenTooltipSaveCart.value = false
  }

  function closeNotification() {
    notificationTitle.value = ''
    notificationMessage.value = ''
    isVisibleNotification.value = false
  }


  function parseCart(savedCartEntity) {
    const originalDate = new Date(savedCartEntity.created_at)
    const formattedDate = `${originalDate.getDate()}. ${originalDate.toLocaleString('de-DE', {month: 'long'})} ${originalDate.getFullYear()}`

    return <SavedCart>{
      name: savedCartEntity.shoppinglist_name,
      created_at: formattedDate,
      id: savedCartEntity.shoppinglist_entity_id,
      items: parseCartItems(savedCartEntity.shopping_list_items)
    }
  }

  function parseCartItems(savedCartItems) {
    let extractedItems = [];
    savedCartItems.forEach(cartItem =>
      extractedItems.push({
        product: {
          name: cartItem.product?.name,
          sku: cartItem.product?.sku,
          description: {html: ''},
          url_key: '/',
          thumbnail: {url: cartItem.product?.thumbnail?.url || ''},
          is_singular_version: cartItem.product?.is_singular_version,
          price: productGetters.getPrice(cartItem.product)?.final
        },
        quantity: cartItem.quantity,
        error: cartItem.error
      })
    )

    return extractedItems
  }


  return {
    addSavedCartToCart,
    cartName,
    // cartsOnPage,
    closeNotification,
    deleteSavedCart,
    isOpenTooltipSaveCart,
    isValidCartName,
    isVisibleNotification,
    notificationMessage,
    notificationTitle,
    notificationType,
    pagination,
    paginatedSavedCarts,
    resetCartName,
    saveCart,
    loadCart,
    loadTotalQty,
    confirmDeleteSavedCart,
    fetchSavedCarts,
    items: computed(() => items.value),
    loading
  }
}
