import { Logger } from '~/helpers/logger';
import { Cart, RemoveItemFromCartInput, RemoveItemFromCartMutation } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import { CustomQuery, CustomHeaders } from '~/types/core';
import {removeItemFromCart} from "~/modules/checkout/composables/useCart/customQueries/removeItemFromCart";

export const removeItemCommand = {
  execute: async (
    context: VsfContext,
    {
      currentCart,
      product,
      customQuery,
      customHeaders,
    },
  ) => {
    Logger.debug('[Magento]: Remove item from cart', {
      product,
      currentCart,
    });

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);

    if (!item) {
      return;
    }

    const customRemoveItemFromCartMutation = removeItemFromCart;

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_uid: item.uid,
    };

    const { data } = await context.$magento.api.customMutation({ mutation: customRemoveItemFromCartMutation, mutationVariables: {input: removeItemParams}})

    Logger.debug('[Result]:', { data });

    // eslint-disable-next-line consistent-return
    return (data as RemoveItemFromCartMutation)
      .removeItemFromCart
      .cart as unknown as Cart;
  },
};
