import {useApi} from "~/composables";

export const removeProductFromPdfCollection = () => {
  const {
    mutate
  } = useApi();

  const removeProductFromPdfCollectionQuery = `
  mutation removeProductFromPdfCollection ($productSku: String) {
  removeProductFromPdfCollection(input: {product_sku: $productSku}) {
    message
        success
    }
  }`;

  const removeProduct = async (variables) => {
    return await mutate(
      removeProductFromPdfCollectionQuery,
      variables
    )
  }

  return {
    mutation: removeProduct,
  };
};
