import {Middleware} from '@nuxt/types';

const middleware: Middleware = (context) => {

  const redirectUrl = '/invalid-licenses';
  if (context.app.$vsf.$magento.config.state.getCustomerToken()) {
    if (context.app.$cookies.get('Accepted-Licenses') == false) {
      context.redirect(redirectUrl);
    }
  }
};
export default middleware;
