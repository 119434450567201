










































import { PropType, ref, watch, defineComponent } from "@nuxtjs/composition-api";
import { SfModal, SfButton } from "@storefront-ui/vue";
import { Action } from "~/types/Action";
import { PageCategory } from "~/types/PageCategory";
import { useDownloadAction } from "~/composables";
import { ProductGeneral } from "~/types/ProductCategories";
import { useErrorMessage } from "~/composables/useErrorMessage";
import { useLicense } from "~/composables";
import { useProductVersion } from "~/composables/useProductVersion";
import DsbConfirmationModal from "~/components/Modal/DsbConfirmationModal.vue";
import productGetters from "~/modules/catalog/product/getters/productGetters";

export default defineComponent({
  name: "DownloadAction",
  components: { SfModal, SfButton, DsbConfirmationModal },
  props: {
    product: {
      required: true,
      type: Object,
    },
    name: {
      required: true,
      type: String,
    },
    action: {
      required: true,
      type: Object as PropType<Action>,
    },
    active: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  setup(props) {
    const { acknowledgeLicenseAgreement } = useDownloadAction(
      "actions_" + props.product.sku
    );

    const downloadInProgress = ref(false);
    const isEnabled = ref(true);
    const errorMessage = ref("");

    const licenseDialogIsVisible = ref(false);
    const licenseMessage = ref("");

    const { setErrorMessage } = useErrorMessage("actions_" + props.product.sku);

    const product = ref(props.product);
    const {
      licenceTerms,
      licenceAgreementModalVisible,
      hasLicense,
      getLicenseDate,
    } = useLicense(product);

    const formattedLicenseDate = (): string => {
      const d = new Date(getLicenseDate.value);
      return d.toLocaleDateString("de-DE");
    };

    const licenceTermsModalVisible = ref(false);

    const { skuDigital } = useProductVersion(product);

    const downloadSuccessCallback = (): void => {
      isEnabled.value = true;
      downloadInProgress.value = false;
      licenseDialogIsVisible.value = false;

      errorMessage.value = "";
      licenseMessage.value = "";
    };

    const licenseAgreementCallback = (message: string): void => {
      licenseDialogIsVisible.value = true;
      downloadInProgress.value = false;
      isEnabled.value = true;

      errorMessage.value = message;
      licenseMessage.value = message;
    };

    const downloadErrorCallback = (message: string): void => {
      downloadInProgress.value = false;
      isEnabled.value = false;

      errorMessage.value = message;
      setErrorMessage({ name: "download", message });

      isEnabled.value = true;
    };

    const handleDownload = function () {
      if (downloadInProgress.value === true) {
        return;
      }

      if (isEnabled.value === false) {
        return;
      }

      if (hasLicense.value) {
        licenceTermsModalVisible.value = true;
        return;
      }

      downloadAction();
    };

    const downloadAction = (): void => {
      errorMessage.value = "";
      setErrorMessage({ name: "download", message: "" });
      downloadInProgress.value = true;
      props.action.onClick(
        skuDigital.value,
        downloadSuccessCallback,
        licenseAgreementCallback,
        downloadErrorCallback
      );
    };

    const agreeToLicense = (): void => {
      licenseMessage.value = "";
      errorMessage.value = "";
      setErrorMessage({ name: "download", message: "" });

      licenseDialogIsVisible.value = false;
      isEnabled.value = true;
      downloadInProgress.value = false;

      acknowledgeLicenseAgreement(skuDigital.value).then(() => {
        downloadAction();
      });
    };

    const dismissLicenseDialog = (): void => {
      licenseMessage.value = "";
      errorMessage.value = "";
      setErrorMessage({ name: "download", message: "" });
      licenseDialogIsVisible.value = false;
      isEnabled.value = true;
      downloadInProgress.value = false;
    };

    const buttonClasses = ref(
      "action-download font-bold flex flex-nowrap items-center font-sf-regular"
    );

    watch(isEnabled, (value) => {
      if (value) {
        buttonClasses.value = buttonClasses.value.replace(" disabled", "");
      } else {
        buttonClasses.value += " disabled";
      }
    });

    watch(downloadInProgress, (value) => {
      if (value) {
        buttonClasses.value += " in-progress";
      } else {
        buttonClasses.value = buttonClasses.value.replace(" in-progress", "");
      }
    });

    return {
      buttonClasses,
      skuDigital,
      isEnabled,
      errorMessage,
      downloadInProgress,
      licenseMessage,
      licenseDialogIsVisible,
      agreeToLicense,
      dismissLicenseDialog,
      handleDownload,
      PageCategory,
      ProductGeneral,
      licenceTerms,
      licenceTermsModalVisible,
      licenceAgreementModalVisible,
      formattedLicenseDate,
      productGetters
    };
  },
});
