import {useApi} from "~/composables";

export const addSavedCart = () => {
  const {
    mutate,
  } = useApi();

  const addShoppingList = `
  mutation addShoppingList ($listName: String)
  {
    addShoppingList(
      input: {
         list_name: $listName
      }
    ){
        success_message
    }
  }`;

  const mutation = async (variables) => {
    return await mutate(
      addShoppingList,
      variables
    )
  }

  return {
    mutation,
  };
};
