export enum General {
  TITLE = 'general_title',
  LINK_HOME = 'link_home',
  DAY = 'general_day',
  TIME_NUMERIC = 'general_time_numeric'
}

export enum Header {
  CART = 'header_cart',
  PRODUCTS_IN_CART = 'header_products_in_cart',
  ONE_PRODUCT_IN_CART = 'header_one_product_in_cart',
  ONE_POSTION_ONE_PRODUCT_IN_CART = 'header_one_position_one_product_in_cart',
  ONE_POSITION_PRODUCTS_IN_CART = 'header_one_position_products_in_cart',
  POSITIONS_PRODUCTS_IN_CART = 'header_positions_products_in_cart',
  ORDERS = 'header_orders',
  ORDER_DETAILS = 'header_order_details',
  SHOPPING_LIST = 'header_shopping_list',
}

export enum UserMenu {
  REGISTERED_AS = 'user_menu_registered_as',
  DEBITOR_ID = 'user_menu_debitor_id',
  SHOPPING_LISTS = 'user_menu_shopping_lists',
  SAVED_CARTS = 'user_menu_saved_carts',
  DATA_AND_PROFILE = 'user_menu_data_and_profile ',
  SETTINGS = 'user_menu_settings',
  LOGOUT = 'user_menu_logout',
}

export enum Navigation {
  TITLE = 'navigation_title',
  CATALOG = 'navigation_catalog',
  FAVORITES = 'navigation_favorites',
  COLLECTIONS = 'navigation_collections',
  PERSONALIZED_DOCS = 'navigation_personalized_docs',
  LICENCES = 'navigation_licences',
}

export enum Footer {
  LEGAL = 'footer_legal',
  PORTAL_RESPONSIBILITY = 'footer_portal_responsibility',
  IMPRINT = 'footer_imprint',
  PRIVACY_POLICY = 'footer_privacy_policy',

  NEED_HELP = 'footer_need_help',
  TO_LEARNING_PORTAL = 'footer_to_learning_portal',
  QUESTIONS_AND_ANSWERS = 'footer_questions_and_answers',
  HOTLINE = 'footer_hotline',
  HOTLINE_ACCESSIBILITY = 'footer_hotline_accessibility',
  PHONE = 'footer_phone',
  WRITE_US = 'footer_write_us'
}

export enum FooterScreenReader {
  CONTACT_US = 'footer_contact_us',
  HOTLINE_ACCESSIBILITY = 'footer_screenreader_hotline_accessibility',
  WRITE_EMAIL = 'footer_write_email'
}

export enum CustomerPagerUsage {
  YOUR_CURRENT_USAGE = 'usage_your_current_usage',
  CURRENT_USAGE = 'usage_current_usage',
  AVERAGE_PER_AGENCY = 'usage_average_PER_AGENCY'
}
