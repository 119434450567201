import {MagentoProduct} from "~/types/Product";
import {DeliveryType} from "~/types/DeliveryType";

export function isConfigurable(product: MagentoProduct){
  return (product?.__typename === 'ConfigurableProduct')
}

export function getChildProductBySku(product, sku) {
  if(product?.__typename === 'SimpleProduct'){
    return {product: product};
  }

  if (typeof(sku) === 'undefined' || sku === '') {
    return {product: null};
  }

  if (isConfigurable(product)) {
    const childProduct = product.variants?.find((item) => {
      if (item.product.sku === sku) {
        return item.product
      }
    })

    // fallback if no active version is found
    if (childProduct === undefined) {
      return {product: product};
    }

    return childProduct;
  } else {
    return {product: product};
  }
}

export function isPhysicalOnly(deliveryType: string){
  return deliveryType === DeliveryType.PHYSICAL_ONLY
}

export function isDigitalOnly(deliveryType: string){
  return deliveryType === DeliveryType.DIGITAL_ONLY
}

export function isHybrid(deliveryType: string){
  return deliveryType === DeliveryType.DIGITAL_OR_PHYSICAL
}
