function log (message: string) {
  // eslint-disable-next-line no-console
  console.log(message)
}

export function useLogger () {
  return {
    log,
  }
}
