import { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';
import {
  Cart,
  CartItemInput,
} from '~/modules/GraphQL/types';
import { CustomQuery, CustomHeaders } from '~/types/core';
import {customAddProductsToCart} from "~/modules/checkout/composables/useCart/customQueries/addProductsToCart";
import { CartIsInActive } from '~/types/CustomError';
import {useCustomOptions} from "~/modules/catalog/product/composables/useCustomOptions";
import {EnteredOptions, useCustomOptionsStore} from "~/modules/catalog/product/store/customOptionsStore";

/** Params object used to add items to a cart */
export declare type AddProductsToCartInput = {
  cartId: string;
  cartItems: CartItemInput[];
};

export const addItemCommand = {
  execute: async (
    context: VsfContext,
    {
      product,
      quantity,
      currentCart,
      productConfiguration,
      customQuery,
      customHeaders,
    },
  ) => {
    Logger.debug('[Magento]: Add item to cart', {
      product,
      quantity,
      currentCart,
    });

    const apiState = context.$magento.config.state;
    let cartId = apiState.getCartId();
    if (!apiState.getCartId() || typeof apiState.getCartId() === undefined) {
      cartId = currentCart.id;
    }

    if (!product) {
      return;
    }
    // eslint-disable-next-line no-underscore-dangle
    switch (product.__typename) {
      case 'SimpleProduct':
        let simpleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };
        const { hasCustomOptions } = useCustomOptions(product)
        if(hasCustomOptions){
          const customOptionsStore = useCustomOptionsStore()
          simpleCartInput.cartItems.find(item => item.sku === customOptionsStore.sku).entered_options = customOptionsStore.entered_options
        }



        const simpleProduct = await context.$magento.api.addProductsToCart(
          simpleCartInput,
          customQuery as CustomQuery,
          customHeaders as CustomHeaders,
        );

        if(!!simpleProduct.errors?.length) {
          throw new Error(simpleProduct.errors?.[0].message)
        }

        Logger.debug('[Result]:', { data: simpleProduct.data });

        if (simpleProduct.data.addProductsToCart.user_errors.length > 0) {
          throw new Error(String(simpleProduct.data.addProductsToCart.user_errors[0].message));
        }

        // eslint-disable-next-line consistent-return
        return simpleProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'ConfigurableProduct':
        const cartItems = [
          {
            parent_sku: product.sku,
            quantity,
            sku: product?.active_versions?.physical?.sku || '',
          },
        ];

        const configurableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems
        };

        // const configurableProduct = await context.$magento.api.addProductsToCart(
        //   configurableCartInput,
        //   customQuery as CustomQuery,
        //   customHeaders as CustomHeaders,
        // );

        const { data: configurableProductData, errors: configurableProductErrors} = await context.$magento.api.customQuery({ query: customAddProductsToCart, queryVariables: configurableCartInput });
        const configurableProduct = { data: null, errors: null }
        configurableProduct.data = configurableProductData
        configurableProduct.errors = configurableProductErrors

        Logger.debug('[Result]:', { data: configurableProduct.data });

        if (configurableProduct.data.addProductsToCart?.user_errors?.length > 0) {
          throw new Error(String(configurableProduct.data.addProductsToCart.user_errors[0].message));
        }

        if(!!configurableProduct.errors?.length) {
          if (configurableProduct.errors.some((error) => error?.extensions?.category === "graphql-no-such-entity")) {
            throw new CartIsInActive(configurableProduct.errors?.[0].message);
          }
          throw new Error(configurableProduct.errors?.[0].message)
        }

        // eslint-disable-next-line consistent-return
        return configurableProduct.data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'BundleProduct':
        const createEnteredOptions = () =>
        // eslint-disable-next-line implicit-arrow-linebreak
          product.bundle_options.map((bundleOption) => ({
            ...bundleOption,
            value: bundleOption.value.toString(),
          }));

        const bundleCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
              entered_options: createEnteredOptions(),
            },
          ],
        };

        // const bundleProduct = await context.$magento.api.addProductsToCart(
        //   bundleCartInput,
        //   customQuery as CustomQuery,
        //   customHeaders as CustomHeaders,
        // );

        const { data: bundleProductData, errors: bundleProductErrors} = await context.$magento.api.customQuery({ query: customAddProductsToCart, queryVariables: bundleCartInput });
        const bundleProduct = { data: null, errors: null }
        bundleProduct.data = bundleProductData
        bundleProduct.errors = bundleProductErrors

        Logger.debug('[Result]:', { data: bundleProduct });

        if (bundleProduct.data.addProductsToCart?.user_errors.length > 0) {
          throw new Error(String(bundleProduct.data.addProductsToCart.user_errors[0].message));
        }

        if(!!bundleProduct.errors?.length) {
          throw new Error(bundleProduct.errors?.[0].message)
        }

        // eslint-disable-next-line consistent-return
        return bundleProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'DownloadableProduct':
        const downloadableCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };

        // const downloadableProduct = await context.$magento.api.addProductsToCart(
        //   downloadableCartInput,
        //   customQuery as CustomQuery,
        //   customHeaders as CustomHeaders,
        // );

        const { data: downloadableProductData, errors: downloadableProductErrors} = await context.$magento.api.customQuery({ query: customAddProductsToCart, queryVariables: downloadableCartInput });
        const downloadableProduct = { data: null, errors: null }
        downloadableProduct.data = downloadableProductData
        downloadableProduct.errors = downloadableProductErrors

        Logger.debug('[Result DownloadableProduct]:', { data: downloadableProduct });

        if (downloadableProduct.data.addProductsToCart?.user_errors?.length > 0) {
          throw new Error(String(downloadableProduct.data.addProductsToCart.user_errors[0].message));
        }

        if(!!downloadableProduct.errors?.length) {
          throw new Error(downloadableProduct.errors?.[0].message)
        }

        // eslint-disable-next-line consistent-return
        return downloadableProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'VirtualProduct':
        const virtualCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: [
            {
              quantity,
              sku: product.sku,
            },
          ],
        };
        // const virtualProduct = await context.$magento.api.addProductsToCart(
        //   virtualCartInput,
        //   customQuery as CustomQuery,
        //   customHeaders as CustomHeaders,
        // );

        const { data: virtualProductData, errors: virtualProductErrors} = await context.$magento.api.customQuery({ query: customAddProductsToCart, queryVariables: virtualCartInput });
        const virtualProduct = { data: null, errors: null }
        virtualProduct.data = virtualProductData
        virtualProduct.errors = virtualProductErrors

        Logger.debug('[Result VirtualProduct]:', { data: virtualProduct });

        if (virtualProduct.data.addProductsToCart?.user_errors?.length > 0) {
          throw new Error(String(virtualProduct.data.addProductsToCart.user_errors[0].message));
        }

        if(!!virtualProduct.errors?.length) {
          throw new Error(virtualProduct.errors?.[0].message)
        }

        // eslint-disable-next-line consistent-return
        return virtualProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      case 'GroupedProduct':
        const groupedCartInput: AddProductsToCartInput = {
          cartId,
          cartItems: product.items.map((item) => ({
            quantity,
            sku: item.product.sku,
          })),
        };

        // const groupedProduct = await context.$magento.api.addProductsToCart(groupedCartInput, customQuery as CustomQuery);

        const { data: groupedProductData, errors: groupedProductErrors} = await context.$magento.api.customQuery({ query: customAddProductsToCart, queryVariables: groupedCartInput });
        const groupedProduct = { data: null, errors: null }
        groupedProduct.data = groupedProductData
        groupedProduct.errors = groupedProductErrors

        Logger.debug('[Result GroupedProduct]:', { data: groupedProduct });

        if (groupedProduct.data.addProductsToCart?.user_errors?.length > 0) {
          throw new Error(String(groupedProduct.data.addProductsToCart.user_errors[0].message));
        }

        // eslint-disable-next-line consistent-return
        return groupedProduct
          .data
          .addProductsToCart
          .cart as unknown as Cart;
      default:
        // eslint-disable-next-line no-underscore-dangle
        throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
    }
  },
};
