export enum CartButton {
  CHANGE_CLIENT = 'button_change_client',
  SAVE_CART = 'buttons_save_cart',
  SAVE_CART_SCREEN_READER = 'buttons_save_cart_screen_reader',
  DELETE_CART = 'buttons_delete_cart',
  COMPLETE_ORDER = 'button_complete_order'
}

export enum CartTooltip {
  LABEL_DEBITOR_ID = 'label_debitor_id',
  ERROR_DEBITOR_ID = 'error_debitor_id',
  LABEL_MESSAGE = 'label_message',
  ERROR_MESSAGE = 'error_message',
  PLACEHOLDER_MESSAGE = 'placeholder_message',
  LABEL_CART_NAME = 'label_cart_name',
  ERROR_CART_NAME = 'error_cart_name'
}
