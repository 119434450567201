import {General, Header, Footer, UserMenu, Navigation, CustomerPagerUsage, FooterScreenReader} from '../../types/homePage/HomepageCategories'
import { Device } from '../../types/PageCategory'
import { General as Page } from "../../types/Page";


export default {
  [Device.MOBILE]: {
    [Header.ORDERS]: 'Bestellungen',
  },

  [Page.TITLE]: 'Startseite',

  [General.TITLE]: 'Drucksachenbestellung',
  [General.LINK_HOME]: 'zur Startseite',
  [General.DAY]: '{start} bis {end}',
  [General.TIME_NUMERIC]: '{start} Uhr bis {end} Uhr',

  [Header.CART]: 'Warenkorb',
  [Header.PRODUCTS_IN_CART]: 'Es liegen {quantity} Produkte im Warenkorb',
  [Header.ONE_PRODUCT_IN_CART]: 'Es liegt ein Produkt im Warenkorb',
  [Header.ONE_POSITION_PRODUCTS_IN_CART]: 'Sie haben eine Position mit insgesamt {cartTotalItems} Produkten in ihrem Warenkorb',
  [Header.ONE_POSTION_ONE_PRODUCT_IN_CART]: 'Sie haben eine Position mit einem Produkt in ihrem Warenkorb',
  [Header.POSITIONS_PRODUCTS_IN_CART]: 'Sie haben {cartTotalPositions} Positionen mit insgesamt {cartTotalItems} Produkten in ihrem Warenkorb',
  [Header.ORDERS]: 'Meine Bestellungen',
  [Header.ORDER_DETAILS]: 'Bestellung',
  [Header.SHOPPING_LIST]: 'Einkaufsliste',

  [UserMenu.REGISTERED_AS]: 'Angemeldet als',
  [UserMenu.DEBITOR_ID]: 'Vermittlernummer',
  [UserMenu.SHOPPING_LISTS]: 'Meine Einkaufszettel',
  [UserMenu.SAVED_CARTS]: 'Gespeicherte Warenkörbe',
  [UserMenu.DATA_AND_PROFILE]: 'Meine Daten und Profile',
  [UserMenu.SETTINGS]: 'Einstellungen',
  [UserMenu.LOGOUT]: 'Abmelden',

  [Navigation.TITLE]: 'Navigation',
  [Navigation.CATALOG]: 'Katalog',
  [Navigation.FAVORITES]: 'Favoriten',
  [Navigation.COLLECTIONS]: 'PDF-Kollektion',
  [Navigation.PERSONALIZED_DOCS]: 'Personalisierte Dokumente',
  [Navigation.LICENCES]: 'Artikel mit Bildrechten',

  [Footer.LEGAL]: 'Rechtliches',
  [Footer.PORTAL_RESPONSIBILITY]: 'Portal-Verantwortung',
  [Footer.IMPRINT]: 'Impressum',
  [Footer.PRIVACY_POLICY]: 'Datenschutz',

  [Footer.NEED_HELP]: 'Brauchen Sie Hilfe?',
  [Footer.TO_LEARNING_PORTAL]: 'Zum Lernportal',
  [Footer.QUESTIONS_AND_ANSWERS]: 'Fragen und Antworten',

  [Footer.HOTLINE]: 'Hotline',
  [Footer.HOTLINE_ACCESSIBILITY]: 'Unsere Hotline erreichen Sie',
  [Footer.PHONE]: 'TELEFON',
  [Footer.WRITE_US]: 'SCHREIBEN SIE UNS',

  [FooterScreenReader.CONTACT_US]: 'So erreichen Sie uns',
  [FooterScreenReader.HOTLINE_ACCESSIBILITY]: 'Unsere Hotline erreichen Sie Montag bis Freitag von 8 bis 12 Uhr und von 13 bis 16 Uhr',
  [FooterScreenReader.WRITE_EMAIL]: 'oder schreiben Sie uns eine Email',

  [CustomerPagerUsage.YOUR_CURRENT_USAGE]: 'Ihr aktueller Verbrauch',
  [CustomerPagerUsage.CURRENT_USAGE]: 'Aktueller Verbrauch',
  [CustomerPagerUsage.AVERAGE_PER_AGENCY]: 'Verbrauch im Durchschnitt pro Agentur des Vorjahres',
}
