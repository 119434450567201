import {Middleware} from '@nuxt/types';
import {useCustomerStore} from "~/modules/customer/stores/customer";

const middleware: Middleware = (context) => {
  const customerStore = useCustomerStore();
  const redirectUrl = '/update-required-data';

  if (context.app.$vsf.$magento.config.state.getCustomerToken()) {
    if (customerStore.user && customerStore.isInternal && customerStore.user.default_shipping === null) {
      context.redirect(redirectUrl);
    }
  }
};
export default middleware;
