











import { defineComponent } from '@nuxtjs/composition-api'
import { useErrorMessage } from "~/composables/useErrorMessage";

export default defineComponent({
  name: "ErrorMessage",
  props: {
    sku: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props){
    return {
      ...useErrorMessage(`${props.type}_${props.sku}`)
    }
  }
})
