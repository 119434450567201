import { ProductDetailType } from '../../types/ProductData'
import { ProductAvailability, ProductGeneral, ProductPreview } from '../../types/ProductCategories'
import { ActionType, ActionTypeScreenreader } from '../../types/Action'
import { LegalInfo } from '../../types/LegalInfo'
import { PageCategory } from '../../types/PageCategory'
import { ProductActions } from '../../types/ProductActions';

export default {
  [ProductGeneral.LICENSE_AGREEMENT_REQUIRED]: 'Bitte stimmen Sie den Lizenzbedingungen zu.',
  [ProductGeneral.LICENSE_AGREEMENT_DEFAULT]: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
  [ProductGeneral.LICENSE_AGREEMENT_ACTION_SIGN]: 'Akzeptieren',
  [ProductGeneral.LICENSE_AGREEMENT_ACTION_DISMISS]: 'Ablehnen',

  [ProductGeneral.LICENCE]: 'Rechtshinweise lesen',
  [ProductGeneral.LICENCE_ENDS]: 'Bildrechte beachten',
  [ProductGeneral.LICENCE_END_DATE]: 'Das Produkt \"{name}\" ist nutzbar bis zum {licenceEndDate}',
  [ProductGeneral.BACK_TO_OVERVIEW]: 'Zurück zur Übersicht',

  [ProductDetailType.ARTICLE_NUMBER]: 'Artikelnummer',
  [ProductDetailType.VERSION]: 'Version',
  [ProductDetailType.VERSION_PRINT]: `@:${PageCategory.PRODUCT}.${ProductDetailType.VERSION} (Druck)`,
  [ProductDetailType.VERSION_DOWNLOAD]: `@:${PageCategory.PRODUCT}.${ProductDetailType.VERSION} (Download)`,
  [ProductDetailType.AVAILABILITY]: 'Verfügbarkeit',
  [ProductDetailType.LICENCE]: 'Nutzbar bis:',
  [ProductDetailType.LICENCE_EXPIRED]: 'Nutzbar bis:',
  [ProductDetailType.ORDERED_QUANTITY]: 'Menge',
  [ProductDetailType.DELIVERY_STATUS]: 'Status',
  [ProductDetailType.ORDERED]: 'bestellt',
  [ProductDetailType.OUTSTANDING]: 'ausstehend',
  [ProductDetailType.CANCELABLE]: 'stornierbar',
  [ProductDetailType.PRINT_PROFILE]: 'Personalisiert mit',
  [ProductDetailType.PRICE]: 'Preis (netto)',
  [ProductDetailType.PRICE_INCL_ZERO]: 'Preis (netto)',
  [ProductDetailType.PRICE_TOTAL]: 'Zwischensumme (netto)',
  [ProductDetailType.PRICE_TOTAL_INCL_ZERO]: 'Zwischensumme (netto)',

  [ProductAvailability.AVAILABLE]: 'Auf Lager',
  [ProductAvailability.NOT_AVAILABLE]: 'Derzeit nicht lieferbar',
  [ProductAvailability.STILL_PIECES_AVAILABLE]: 'noch {quantity} Stück verfügbar',
  [ProductAvailability.DELIVERY_FROM_WEEK]: 'Lieferung erfolgt ab KW {week}',

  [ActionType.ADD_TO_CART]: 'In den Warenkorb',
  [ActionType.PERSONALIZE]: 'Personalisieren',
  [ActionType.DOWNLOAD]: 'Download [PDF]',
  [ActionType.ACCEPT_LICENSE]: 'Lizenz akzeptieren',
  [ActionType.ADD_TO_COLLECTION]: 'Zu Kollektion hinzufügen',
  [ActionType.REMOVE_FROM_COLLECTION]: 'Aus Kollektion entfernen',
  [ActionType.FAVORITE]: 'Favorit',
  [ActionType.FAVORITE_REMOVE]: 'Aus den Favoriten entfernen',
  [ActionType.PREVIEW]: 'Vorschau',
  [ActionType.QUANTITY]: 'Menge',
  [ActionType.REMOVE_FROM_CART]: 'Löschen',
  [ActionType.DETAILS]: 'Details',
  [ActionType.MORE]: 'Mehr',

  [ActionTypeScreenreader.SET_QUANTITY_FOR_CART]: '{name} Bestellmenge festlegen',
  [ActionTypeScreenreader.ADD_TO_CART]: '{name} in den Warenkorb legen',
  [ActionTypeScreenreader.ADD_TO_CART_QUANTITY]: '{name} {quantity} mal in den Warenkorb legen',
  [ActionTypeScreenreader.ADD_TO_CART_CANCEL]: '{name} Bestellung abbrechen',
  [ActionTypeScreenreader.ADD_TO_CART_SUCCESS]: 'Produkt {name} wurde {quantity} mal in den Warenkorb gelegt',
  [ActionTypeScreenreader.PERSONALIZE]: '{name} personalisieren',
  [ActionTypeScreenreader.DOWNLOAD]: '{name} PDF Download',
  [ActionTypeScreenreader.ACCEPT_LICENSE]: '{name} Lizenz akzeptieren',
  [ActionTypeScreenreader.ADD_TO_COLLECTION]: '{name} zu Kollektion hinzufügen',
  [ActionTypeScreenreader.REMOVE_FROM_COLLECTION]: '{name} aus Kollektion entfernen',
  [ActionTypeScreenreader.FAVORITE]: '{name} zu Favoriten hinzufügen',
  [ActionTypeScreenreader.FAVORITE_REMOVE]: '{name} aus den Favoriten entfernen',
  [ActionTypeScreenreader.PREVIEW]: '{name} Vorschau',
  [ActionTypeScreenreader.QUANTITY]: '{name} Menge',
  [ActionTypeScreenreader.DECREMENT_QUANTITY]: '{name} Menge um eins verringern',
  [ActionTypeScreenreader.INCREMENT_QUANTITY]: '{name} Menge um eins erhöhen',
  [ActionTypeScreenreader.QUANTITY_INPUT_LABEL]: '{name} Menge festlegen',
  [ActionTypeScreenreader.REMOVE_FROM_CART]: '{name} aus dem Warenkorb löschen',
  [ActionTypeScreenreader.DETAILS]: '{name} Produktseite',
  [ActionTypeScreenreader.MORE]: '{name} Produktseite',

  [ProductPreview.ZOOM]: '[Lupe] Vorschau',

  [LegalInfo.LICENCE_CONDITION]: 'Lizenzbedingung',

  [ProductActions.AMOUNT]: 'Menge',
  [ProductActions.CANCEL]: 'Abbrechen'
}
