var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dsb-add-to-cart justify-center"},[(!_vm.isAddToCardInProcess)?_c('div',{staticClass:"dsb-add-to-cart__qty-wrapper"},[_c('SfButton',{staticClass:"dsb-add-to-cart__qty-button sf-button--outline no-underline",attrs:{"aria-label":_vm.$t(
            ((_vm.PageCategory.PRODUCT) + "." + (_vm.ActionTypeScreenreader.DECREMENT_QUANTITY)),
            { name: _vm.product.name }
          )},on:{"click":_vm.decrementQuantity}},[_c('span',{staticClass:"dsb-add-to-cart__qty-button--minus"})]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tempQuantity),expression:"tempQuantity"}],staticClass:"dsb-add-to-cart__qty-input",attrs:{"type":"number","min":"0","required":"","aria-label":_vm.$t(
            ((_vm.PageCategory.PRODUCT) + "." + (_vm.ActionTypeScreenreader.QUANTITY_INPUT_LABEL)),
            { name: _vm.product.name }
          )},domProps:{"value":(_vm.tempQuantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tempQuantity=$event.target.value}}}),_vm._v(" "),_c('SfButton',{staticClass:"dsb-add-to-cart__qty-button sf-button--outline no-underline",attrs:{"aria-label":_vm.$t(
            ((_vm.PageCategory.PRODUCT) + "." + (_vm.ActionTypeScreenreader.INCREMENT_QUANTITY)),
            { name: _vm.product.name }
          )},on:{"click":_vm.incrementQuantity}},[_c('span',{staticClass:"dsb-add-to-cart__qty-button--plus"})])],1):_c('div',{staticClass:"dsb-add-to-cart__qty-wrapper"},[_c('SpinnerSvg',{staticClass:"m-auto"})],1),_vm._v(" "),_c('SfButton',{staticClass:"addToCartButton dsb-add-to-cart__button",attrs:{"disabled":_vm.isAddToCardInProcess || _vm.disabledComputed,"aria-label":_vm.$t(
          ((_vm.PageCategory.PRODUCT) + "." + (_vm.ActionTypeScreenreader.ADD_TO_CART_QUANTITY)),
          { name: _vm.product.name, quantity: _vm.quantity }
        )},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.$emit('tabOut')},"click":function($event){return _vm.addToCartValidation(_vm.product)}}},[_c('div',{staticClass:"dsb-add-to-cart__button-icon"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t(((_vm.PageCategory.PRODUCT) + "." + (_vm.ActionType.ADD_TO_CART)))))])])],1),_vm._v(" "),_c('div',{attrs:{"role":"status"}},[(_vm.addToCartScreenReaderMessage)?_c('span',{staticClass:"sr-only"},[_vm._v("\n      "+_vm._s(_vm.addToCartScreenReaderMessage)+"\n    ")]):_vm._e()]),_vm._v(" "),_c('DsbConfirmationModal',{attrs:{"visible":_vm.licenceTermsModalVisible,"bg-image-class":"timer-bg-image","title":_vm.licenceTerms.title,"subline":_vm.$t(((_vm.PageCategory.PRODUCT) + "." + (_vm.ProductGeneral.LICENCE_END_DATE)), {
        name: _vm.productGetters.getName(_vm.product),
        licenceEndDate: _vm.formattedLicenseDate(),
      }),"text":_vm.licenceTerms.text,"agree-button-text-screenreader":((_vm.productGetters.getName(_vm.product)) + " Bildrechte bestätigen und schließen")},on:{"close":function($event){_vm.licenceTermsModalVisible = false},"agree":function($event){return _vm.agreeToLicense(_vm.product)}}}),_vm._v(" "),_c('ErrorMessage',{attrs:{"sku":_vm.currentProduct.sku,"type":"addToCart"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }