import {LicencesFilter, LicencesGeneral, LicenceListItem, InvalidLicenceSubtitle} from '../../types/licences/licenceCategories'
import { General } from "../../types/Page";
import {SearchListItem} from "~/types/search/SearchCategories";

export default {
  [General.TITLE]: 'Artikel mit Rechtshinweisen zur Beachtung der Urheber-/Bildrechte',

  [LicencesGeneral.READ_TERMS]: 'Rechtshinweise lesen',
  [LicencesGeneral.INVALID_LICENSE_TITLE]: 'Geänderte Bildrechte',

  [InvalidLicenceSubtitle.INVALID_LICENSE_SUBTITLE_1]: 'Bei den von Ihnen bestellten Artikeln mit Bildrechten haben sich die Bildrechte geändert.',
  [InvalidLicenceSubtitle.INVALID_LICENSE_SUBTITLE_2]: 'Bitte bestätigen Sie die unten aufgeführten geänderten Bildrechte. Erst dann ist eine weitere Nutzung des Allianz Medien- und Drucksachenportals möglich.',

  [LicencesFilter.SHOW]: 'Anzeigen',
  [LicencesFilter.SORT_BY]: 'Sortierung',

  [LicenceListItem.ACCEPTED_DATE]: 'Bestätigt am:',
  [LicenceListItem.EXPIRATION_DATE]: 'Nutzbar bis:',
  [LicenceListItem.WEEKS_LEFT]: '(Noch {weeksLeft} Wochen)',
  [LicenceListItem.EXPIRED]: 'Abgelaufen',

  [LicenceListItem.DETAILS]: 'Details',
  [LicenceListItem.SCREENREADER_DETAILS]: '{name} Produktseite',
}
