import {  CartButton, CartTooltip } from '../../types/cart/CartCategories'
import { TooltipBox } from "../../types/TooltipBox";
import { General } from "../../types/Page";

export default {
  [General.TITLE]: 'Warenkorb',

  [CartButton.CHANGE_CLIENT]: 'Auftraggeber wechseln',
  [CartButton.SAVE_CART]: 'Warenkorb speichern',
  [CartButton.SAVE_CART_SCREEN_READER]: 'Warenkorb {name} speichern',
  [CartButton.DELETE_CART]: 'Warenkorb löschen',
  [CartButton.COMPLETE_ORDER]: 'Bestellung abschließen',

  [CartTooltip.LABEL_DEBITOR_ID]: 'Vermittlernummer',
  [CartTooltip.ERROR_DEBITOR_ID]: 'Bitte geben Sie eine valide Vermittlernummer ein',
  [CartTooltip.LABEL_MESSAGE]: 'Nachricht an den Auftraggeber',
  [CartTooltip.ERROR_MESSAGE]: 'Bitte schreiben Sie eine Nachricht',
  [CartTooltip.PLACEHOLDER_MESSAGE]: ' ',
  [CartTooltip.LABEL_CART_NAME]: 'Geben Sie Ihrem Warenkorb einen Namen',
  [CartTooltip.ERROR_CART_NAME]: 'Bitte geben Sie einen Namen ein',

  [TooltipBox.CANCEL]: 'Abbrechen'
}
