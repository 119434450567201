import {useApi} from "~/composables";

export const deleteProfile = () => {
  const {
    mutate
  } = useApi();

  const deleteProfileQuery = `
    mutation deleteProfile($id: Int!) {
      deleteProfile(id: $id)
    }
  `;

  const deleteProfile = async (variables) => {
    return await mutate(
      deleteProfileQuery,
      variables
    )
  }


  return {
    mutation: deleteProfile,
  };
}
