import {useApi} from "~/composables";
import {Logger} from '@vue-storefront/core';
import {ApolloQueryResult} from "@apollo/client/core";

export const useCampaignList = (): UseCampaignList => {
  const {
    query
  } = useApi();

  const GET_CAMPAIGNS_LIST_QUERY = `
  query {
    campaigns {
      children {
        uid
        id
        name
        short_description
        description
        url_key
        image
      }
    }
  }
`;

  const getCampaignsList = async () => {
    Logger.debug(`useCampaignsList`);

    const result = await query<ApolloQueryResult<CampaignCategoryList>>(
      GET_CAMPAIGNS_LIST_QUERY
    );

    // @ts-ignore
    return result.data?.campaigns?.children || [];
  };

  return {
    getCampaignsList
  };
};


interface UseCampaignList {
  getCampaignsList(): Promise<any>
}

export declare type CampaignCategoryList = {
  campaigns?: Array<{
    product_count?: number | null | undefined;
    name?: string | null | undefined;
    uid: string;
    children?: Array<{
      include_in_menu?: number | null | undefined;
      is_anchor?: number | null | undefined;
      level?: number | null | undefined;
      name?: string | null | undefined;
      position?: number | null | undefined;
      product_count?: number | null | undefined;
      uid: string;
      url_path?: string | null | undefined;
      url_suffix?: string | null | undefined;
      children?: Array<{
        include_in_menu?: number | null | undefined;
        is_anchor?: number | null | undefined;
        level?: number | null | undefined;
        name?: string | null | undefined;
        position?: number | null | undefined;
        product_count?: number | null | undefined;
        uid: string;
        url_path?: string | null | undefined;
        url_suffix?: string | null | undefined;
        children?: Array<{
          include_in_menu?: number | null | undefined;
          is_anchor?: number | null | undefined;
          level?: number | null | undefined;
          name?: string | null | undefined;
          position?: number | null | undefined;
          product_count?: number | null | undefined;
          uid: string;
          url_path?: string | null | undefined;
          url_suffix?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined> | null | undefined;
};
