import { ref, computed } from '@nuxtjs/composition-api'
import { AgnosticBreadcrumb } from "@vue-storefront/core";
import { customComputedRef } from "~/helpers/customComputedRef";


const breadcrumbs = customComputedRef(computed<AgnosticBreadcrumb[]>(() => []))

const pageTitle = ref('')

export function usePage () {
  return {
    breadcrumbs,
    pageTitle
  }
}
