export async function fetchLicenceProducts(query: Function, params, error) {
  const result = await query(params);

  if (result['graphQLErrors'] !== undefined) {
    result['graphQLErrors'].forEach(error => console.error(`graphql error: ${error.message}`));
    error.fetchLicenceProducts = new Error(result['graphQLErrors'][0].message)
  }

  if (result.data === undefined) {
    return
  }

  return result.data
}
