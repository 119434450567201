import {OrderDeliveryStatus, OrderDetails, OrdersFilter, OrdersSearchBar} from '../../types/orders/OrdersCategories'

export default {
  [OrdersFilter.TIME_PERIOD]: 'Zeitraum',
  [OrdersFilter.DELIVERY_STATUS]: 'Lieferstatus',

  [OrdersSearchBar.PLACEHOLDER]: 'Bestellnummer, Artikelnummer, Text',
  [OrdersSearchBar.BUTTON_TEXT]: 'Suche',

  [OrderDetails.DATE]: 'Bestellt am',
  [OrderDetails.ID]: 'Bestellnummer',
  [OrderDetails.DELIVERY_STATUS]: 'Status',
  [OrderDetails.DETAILS]: 'Bestelldetails',
  [OrderDetails.TOTAL_INCL_TAX]: 'Bestellsumme inkl. MwSt.',
  [OrderDetails.PAYMENT_METHOD]: 'Zahlungsmethode',

  // Status storniert
  [OrderDeliveryStatus.CANCELED]: 'Storniert',
  [OrderDeliveryStatus.PARTIAL_CANCELED]: 'Teilstorniert',
  [OrderDeliveryStatus.CLOSED]: 'Storniert',

  // Status offen
  [OrderDeliveryStatus.PENDING]: 'Offen',
  [OrderDeliveryStatus.BACKORDERED]: 'Offen',
  [OrderDeliveryStatus.ORDERED]: 'Offen',


  // Status in Bearbeitung
  [OrderDeliveryStatus.PROCESSING]: 'In Bearbeitung',
  [OrderDeliveryStatus.INVOICED]: 'In Bearbeitung',

  // Status teilgeliefert
  [OrderDeliveryStatus.PARTIAL_SHIPPED]: 'Teilgeliefert',

  // Status abgeschlossen
  [OrderDeliveryStatus.SHIPPED]: 'Abgeschlossen',
  [OrderDeliveryStatus.COMPLETE]: 'Abgeschlossen',

  [OrderDeliveryStatus.MIXED]: 'Fehler',

  // Zahlung auststehend
  [OrderDeliveryStatus.PENDING_PAYMENT]: 'Zahlung auststehend',
}
