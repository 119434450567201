import {computed, Ref, ref, watch} from "@nuxtjs/composition-api";
import {EnteredOptions, useCustomOptionsStore} from "~/modules/catalog/product/store/customOptionsStore";

export function useCustomOptions(product) {
  const customOptionsStore = useCustomOptionsStore();

  const formData = ref({}) as Ref<Array<EnteredOptions>>;

  watch(formData.value, (enteredOptions) => {
    customOptionsStore.sku = product.value.sku;

    customOptionsStore.entered_options = [];
    for (let [optionUid, optionValue] of Object.entries(enteredOptions)) {
      if(Array.isArray(optionValue)){
        optionValue = optionValue[0]
      }

      customOptionsStore.entered_options.push({
        // @ts-ignore
        uid: optionUid,
        // @ts-ignore
        value: optionValue
      });
    }
  });

  const hasCustomOptions = computed(() => {
    return (product.value?.options?.length > 0)
  })

  const sortedCustomOptions = computed(() => {
    return product.value.options?.sort((a, b) => {
      return a.sort_order - b.sort_order;
    })
  })


  return {
    hasCustomOptions,
    formData,
    sortedCustomOptions
  }
}
