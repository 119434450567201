import { useApi } from "~/composables";
import { ref } from "@nuxtjs/composition-api";
import { Logger } from "~/helpers/logger";

export const getLicenses = () => {
  const { query } = useApi();
  const loading = ref(false);

  const GET_LICENSES_QUERY = `
    query licenses($search: String = "", $filter: LicenseAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: LicenseAttributeSortInput) {
      licenses(
        search: $search,
        filter: $filter,
        pageSize: $pageSize,
        currentPage: $currentPage,
        sort: $sort
      ) {
        items {
          name
          sku
          parent_sku
          parent_url_key
          is_singular_version
          description {
            html
          }
          thumbnail {
            url
          }
          url_key
          active_versions {
            physical {
              version_name
              sku
            }
            digital {
              version_name
              sku
            }
          }
          version
          delivery_type
          license {
            acceptedAt
            expirationDate
            weeksLeft
            expired
            termsAgreed
          }
        }
        page_info {
          page_size
          current_page
        }
        total_count
      }
    }
  `;

  const load = async (params) => {
    try {
      Logger.debug("getLicenses/load");
      loading.value = true;

      return await query(GET_LICENSES_QUERY, params);
    } catch (error) {
      Logger.error("getLicenses/error", error);
    } finally {
      loading.value = false;
    }
  };

  return {
    load,
    loading,
  };
};
