import {useImage} from "~/composables";

export const addImageSizeToUrl = (url: string, type: string): string => {
  const {imageSizes} = useImage();
  let imageSizeParameter = `height=${imageSizes[type].height}&width=${imageSizes[type].width}`;
  if(url.includes('?')){
    url = url + `&${imageSizeParameter}`;
  }else {
    url = url + `?${imageSizeParameter}`;
  }
  return url;
}
