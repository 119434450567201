import {useApi} from "~/composables";

export const flushPdfCollection = () => {
  const {
    mutate,
  } = useApi();

  const flushPdfCollectionMutationQuery = `
  mutation flushPdfCollection {
    flushPdfCollection {
      message
      success
    }
  }
  `;


  const flushPdfCollection = async () => {
    return await mutate(
      flushPdfCollectionMutationQuery
    )
  }

  return {
    mutation: flushPdfCollection,
  };
};
