import {useShopApi} from "~/composables";
import {SSOUserResponse} from "~/modules/login/types";

export function useCustomerData() {
  const {shopApi} = useShopApi();

  async function getCustomerData(): Promise<SSOUserResponse> {
    const {data} = await shopApi.get( '/single-sign-on-callback/customer', {responseType: 'json'});

    return data
  }
  async function getCustomerSSOCompany(): Promise<string> {
    const {data} = await shopApi.get( '/single-sign-on-callback/customer/company', {responseType: 'json'});

    if (typeof (data) === 'undefined' || typeof(data.company) === 'undefined') {
      console.error('SSO Company could not be received');
      return '';
    }

    return data.company
  }

  return {
    getCustomerData,
    getCustomerSSOCompany
  }
}
