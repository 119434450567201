/** GraphQL Query that fetches customer addresses. */
const customSetShippingMethodsOnCart = `
  mutation setShippingMethodsOnCart($input: SetShippingMethodsOnCartInput) {
    setShippingMethodsOnCart(input: $input) {
      cart {
        id
        email
        is_virtual
        applied_coupons {
          code
          __typename
        }
        prices {
          subtotal_excluding_tax {
            value
            __typename
          }
          subtotal_including_tax {
            value
            __typename
          }
          applied_taxes {
            amount {
              value
              __typename
            }
            label
            __typename
          }
          discounts {
            amount {
              value
              __typename
            }
            label
            __typename
          }
          grand_total {
            value
            __typename
          }
          __typename
        }
        items {
          uid
          product {
            uid
            __typename
            sku
            name
            stock_status
            only_x_left_in_stock
            rating_summary
            thumbnail {
              url
              position
              disabled
              label
              __typename
            }
            url_key
            url_rewrites {
              url
              __typename
            }
            price_range {
              maximum_price {
                final_price {
                  currency
                  value
                  __typename
                }
                regular_price {
                  currency
                  value
                  __typename
                }
                __typename
              }
              minimum_price {
                final_price {
                  currency
                  value
                  __typename
                }
                regular_price {
                  currency
                  value
                  __typename
                }
                __typename
              }
              __typename
            }
            categories {
              uid
              name
              url_suffix
              url_path
              breadcrumbs {
                category_name
                category_url_path
                __typename
              }
              __typename
            }
            review_count
            reviews {
              items {
                average_rating
                ratings_breakdown {
                  name
                  value
                  __typename
                }
                __typename
              }
              __typename
            }
          }
          prices {
            row_total {
              value
              __typename
            }
            row_total_including_tax {
              value
              __typename
            }
            total_item_discount {
              value
              __typename
            }
            __typename
          }
          quantity
        ... on ConfigurableCartItem {
            configurable_options {
              configurable_product_option_uid
              option_label
              configurable_product_option_value_uid
              value_label
              __typename
            }
            configured_variant {
              sku
              thumbnail {
                url
                __typename
              }
              __typename
            }
            __typename
          }
        ... on BundleCartItem {
            bundle_options {
              uid
              label
              type
                values {
                id
                label
                price
                quantity
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        total_quantity
        shipping_addresses {
          firstname
          lastname
          street
          city
          address_line_2
          address_line_3
          sap_name
          company
          postcode
          telephone
          country {
            code
            label
            __typename
          }
          selected_shipping_method {
            carrier_code
            carrier_title
            method_code
            method_title
            amount {
              value
              currency
              __typename
            }
            __typename
          }
          __typename
        }
        billing_address {
          firstname
          lastname
          street
          city
          company
          postcode
          telephone
          country {
            code
            label
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export default customSetShippingMethodsOnCart
