import { Footer } from '../types/homePage/HomepageCategories'

export const loginFooterMock = [
  {
    title: Footer.LEGAL,
    children: [
      { label: Footer.PORTAL_RESPONSIBILITY, link: '/portal-responsibility' },
      { label: Footer.IMPRINT, link: 'imprint' },
      { label: Footer.PRIVACY_POLICY, link: '/privacy-policy' }],
  },
]
