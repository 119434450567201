import { UseImageInterface } from './useImage';

const imageSizes = {
  productCard: {
    width: 249,
    height: 249,
  },
  productCover: {
    width: 1240,
    height: 575,
  },
  productCardHorizontal: {
    width: 140,
    height: 200,
  },
  checkout: {
    imageWidth: 100,
    imageHeight: 100,
  },
  productGallery: {
    thumbWidth: 160,
    thumbHeight: 160,
    imageWidth: 1080,
    imageHeight: 1340,
  },
  cart: {
    imageWidth: 170,
    imageHeight: 170,
  },
  campaignHomePage: {
    width: 706,
    height: 400,
  },
};

/**
 * Allows extracting image paths from magento URL.
 *
 * See the {@link UseImageInterface} for a list of methods and values available in this composable.
 * */
export function useImage(): UseImageInterface {
  /**
   * Extract image path from Magento URL.
   *
   * @param fullImageUrl {string | null}
   *
   * @return {string}
   */
  const getMagentoImage = (fullImageUrl: string | null) => {
    if (!fullImageUrl) return '';

    return fullImageUrl;
  };

  return {
    getMagentoImage,
    imageSizes,
  };
}

export * from './useImage';
export default useImage;
