export enum FavoritesGeneral {
  DESCRIPTION = 'favorites_description',
}

export enum FavoritesContingent {
  CONTINGENT_INFO = 'contingent_info',
  CONTINGENT_FIX = 'contingent_fix'
}

export const maxWishlistItems = 100;
