import {computed, ComputedRef, Ref} from "@nuxtjs/composition-api";


export function usePrintOnDemand(product: Ref): UsePrintOnDemandInterface {
  const web2PrintSKU = computed(() => {
    return product.value?.Web2Print_SKU ?? ''
  })

  const pdfMergeType = computed(() => {
    let mergeType = product.value?.Web2Print_Merge ?? ''
    if (typeof mergeType === 'string' || mergeType instanceof String){
      mergeType = mergeType.toUpperCase()
    }

    return mergeType
  })

  const isPrintOnDemand = computed(() => {
    return parseInt(product.value?.print_on_demand) === 1
  })

  return {
    isPrintOnDemand,
    web2PrintSKU,
    pdfMergeType,
  }
}


export interface UsePrintOnDemandInterface {
  isPrintOnDemand : ComputedRef<boolean>
  web2PrintSKU: ComputedRef<string>
  pdfMergeType: ComputedRef<string>
}
