var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dsb-login-username-password my-sf-lg"},[_c('transition',{attrs:{"name":"sf-fade","mode":"out-in"}},[(_vm.isLogin)?_c('div',[_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleLogin)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DsbInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-email'),expression:"'login-email'"}],staticClass:"sf-input--outline",attrs:{"valid":!errors[0],"error-message":errors[0],"name":"email","label":"E-Mail","required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DsbInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-password'),expression:"'login-password'"}],staticClass:"sf-input--outline",attrs:{"valid":!errors[0],"error-message":errors[0],"name":"password","label":"Passwort","type":"password","has-show-password":"","required":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"dsb-error-message",attrs:{"role":"status"}},[(_vm.error.login)?_c('div',{staticClass:"dsb-error-icon text-danger"},[_vm._v("\n              "+_vm._s(_vm.error.login)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-submit'),expression:"'login-submit'"}],staticClass:"form__button mt-sf-base mx-auto w-full lg:w-1/2",attrs:{"type":"submit","disabled":_vm.loading}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Login')))])])],1)],1)]}}],null,false,1154744762)}),_vm._v(" "),_c('div',{staticClass:"action mt-sf-2xl"},[_c('button',{staticClass:"font-bold sf-link dsb-icon-left dsb-arrow-right-icon",on:{"click":function($event){return _vm.setIsForgottenValue(true)}}},[_vm._v("\n          "+_vm._s(_vm.$t('Forgotten password?'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"bottom mt-sf-lg"},[_c('a',{staticClass:"font-bold sf-link dsb-icon-left dsb-arrow-right-icon",attrs:{"href":"/userRegistration/User-Formular-Drucksachenbestellung.pdf","target":"_blank"}},[_vm._v("\n          Benutzerkonto beantragen\n        ")])])],1):(_vm.isForgotten)?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('Forgot Password')))]),_vm._v(" "),_c('ValidationObserver',{key:"log-in",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleForgotten)}}},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DsbInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('forgot-email'),expression:"'forgot-email'"}],staticClass:"form__element sf-input--outline",attrs:{"valid":!errors[0],"error-message":errors[0],"name":"email","label":_vm.$t('Forgot Password Modal Email'),"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"dsb-error-message",attrs:{"role":"status"}},[(_vm.forgotPasswordError.request)?_c('div',{staticClass:"dsb-error-icon text-danger"},[_vm._v("\n              "+_vm._s(_vm.forgotPasswordError.request.message)+"\n            ")]):_vm._e()]),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('forgot-submit'),expression:"'forgot-submit'"}],staticClass:"mt-sf-xl mb-sf-2xl mx-auto place-self-center w-full lg:w-2/3 sf-button",attrs:{"type":"submit","disabled":_vm.forgotPasswordLoading}},[_c('SfLoader',{class:{ loader: _vm.forgotPasswordLoading },attrs:{"loading":_vm.forgotPasswordLoading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Reset Password')))])])],1)],1)]}}])})],1):(_vm.isThankYouAfterForgotten)?_c('div',{staticClass:"thank-you"},[_c('i18n',{staticClass:"thank-you__paragraph",attrs:{"tag":"p","path":"forgotPasswordConfirmation"}},[_c('span',{staticClass:"thank-you__paragraph--bold"},[_vm._v(_vm._s(_vm.userEmail))])]),_vm._v(" "),_c('p',{staticClass:"thank-you__paragraph"},[_vm._v("\n        "+_vm._s(_vm.$t('Thank You Inbox'))+"\n      ")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }