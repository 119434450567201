/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      aggregations (filter: {category: {includeDirectChildrenOnly: true}}) {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
      items {
        __typename
        uid
        sku
        name
        stock_status
        only_x_left_in_stock
        stock_availability_from
        salable_qty
        license_expiration_date
        license_beginning_date
        url_key
        url_rewrites {
          url
        }
        categories {
          uid
          id
        }
        thumbnail {
          url
        }
        delivery_type
        active_versions {
          digital {
            version_name
            sku
            is_singular_version
          }
          physical {
            version_name
            sku
            is_singular_version
          }
        }
        permissions {
            job_level
            job_qualifications
            sales_channel
            sales_region
            user_groups
            user_type
        }
        short_description {
          html
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        ... on SimpleProduct {
          thumbnail {
            url
          }
        }
        ... on ConfigurableProduct {
          variants{
            product {
              sku
              delivery_type
              stock_status
              only_x_left_in_stock
              stock_availability_from
              salable_qty
              thumbnail {
                url
              }
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
