import { defineStore } from 'pinia';
import {Customer} from "~/modules/customer/composables/types";
import jwtDecode from "jwt-decode";


interface CustomerState {
  user: Customer | null,
  isLoggedIn: boolean,
  isInternal: boolean | null,
  tokenExpirationInMs: number | null
}

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false,
    isInternal: null,
    tokenExpirationInMs: null
  }),
  actions: {
    setIsLoggedIn(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn;
    },
    setIsInternal(isInternal: boolean) {
      this.isInternal = isInternal;
    },
    setTokenExpiration(token: string | null) {
      if(token) {
        const decodedToken = jwtDecode<any>(token);
        this.tokenExpirationInMs = decodedToken.exp * 1000;
      } else {
        this.tokenExpirationInMs = null
      }
    }
  },
});
