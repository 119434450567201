import {ProductDetail, ProductDetailType} from "~/types/ProductData";
import {CartItemInterface} from "@vue-storefront/magento-api/lib/types/GraphQL";
import {MagentoProduct} from "~/types/Product";
import {getActiveDigitalVersionName, getActivePhysicalVersionName} from "~/composables/useProductVersion";
import {getProductAvailability} from "~/composables/useProductDetails/productAvailability";
import {isDigitalOnly, isHybrid, isPhysicalOnly} from "~/composables/useProductDetails/productType";
import productGetters from '~/modules/catalog/product/getters/productGetters';
import {getFormattedDate} from "~/utils/dateFormatter";
import cartGetters from "~/modules/checkout/getters/cartGetters";
import {SavedCartItem } from "~/types/SavedCart";

export function getProductDetailText(product: MagentoProduct, detail: ProductDetailType) {
  switch (detail) {
    case ProductDetailType.ARTICLE_NUMBER:
      return {text: productGetters.getProductSkuText(product.is_singular_version, product.sku), options: null};
    case ProductDetailType.AVAILABILITY:
      return getProductAvailability(product);
    case ProductDetailType.LICENCE:
      const licenseExpirationDate = productGetters.getLicenseExpirationDate(product)
      return {text: getFormattedDate(licenseExpirationDate), options: null};
    case ProductDetailType.LICENCE_EXPIRED:
      return {text: "Abgelaufen", options: null};
    case ProductDetailType.VERSION:
      return {text: "", options: null};
    case ProductDetailType.VERSION_DOWNLOAD:
      return {text: getActiveDigitalVersionName(product), options: null};
    case ProductDetailType.VERSION_PRINT:
      return {text: getActivePhysicalVersionName(product), options: null};
    case ProductDetailType.LICENCE_VERSION:
      return {text: "", options: null};
    case ProductDetailType.PRICE:
      return {text: productGetters.getPrice(product)?.final || '0', options: null};
    case ProductDetailType.PRICE_INCL_ZERO:
      return {text: productGetters.getPrice(product)?.final || '0', options: null};
    default:
      return {text: "tbd", options: null};
  }
}

export function mapProductToProductDetails(product: any, details: ProductDetailType[]) {
  const productDetails: ProductDetail[] = [];
  for (const detail of details) {
    const {text, options} = getProductDetailText(product, detail);

    if (text) {
      productDetails.push({
        type: detail,
        text,
        options,
      });
    }
  }

  return productDetails;
}

export function mapLicenceItemsToProductDetails(product: any, details: ProductDetailType[]) {
  const productDetails: ProductDetail[] = [];
  for (let detail of details) {
    let {text} = getProductDetailText(product, detail);

    if (detail === ProductDetailType.ARTICLE_NUMBER) {
      text = product.parent_sku;
    }

    if (detail === ProductDetailType.LICENCE_VERSION) {
      text = productGetters.getProductSkuText(product.is_singular_version, product.version);

      if (isPhysicalOnly(product.delivery_type)) {
        detail = ProductDetailType.VERSION_PRINT;
      }

      if (isDigitalOnly(product.delivery_type)) {
        detail = ProductDetailType.VERSION_DOWNLOAD;
      }

      if (isHybrid(product.delivery_type)) {
        productDetails.push({
          type: ProductDetailType.VERSION_PRINT,
          text,
        });
        productDetails.push({
          type: ProductDetailType.VERSION_DOWNLOAD,
          text,
        });
        continue;
      }
    }

    if (text) {
      productDetails.push({
        type: detail,
        text,
      });
    }
  }

  return productDetails;
}


export function mapCartItemToProductDetails(cartItem: CartItemInterface, details: ProductDetailType[]) {
  const productDetails: ProductDetail[] = [];

  for (const detail of details) {
    let text = "";

    if (detail === ProductDetailType.ORDERED_QUANTITY) {
      text = cartItem.quantity?.toString() || "";
    }
    else if (detail === ProductDetailType.PRICE_TOTAL_INCL_ZERO) {
      text = cartGetters.getItemPrice(cartItem).regular.toString()
    }
    else {
      text = getProductDetailText(cartItem.product, detail).text as string;
    }

    if (text) {
      productDetails.push({
        type: detail,
        text,
      });
    }
  }

  return productDetails;
}

export function mapSavedCartItemToProductDetails(cartItem: SavedCartItem, details: ProductDetailType[]) {
  const productDetails: ProductDetail[] = [];
  for (const detail of details) {
    let text = "";

    if (detail === ProductDetailType.ORDERED_QUANTITY) {
      text = cartItem.quantity?.toString() || "";
    }
    else if (detail === ProductDetailType.PRICE || detail === ProductDetailType.PRICE_INCL_ZERO) {
      text = cartItem.product.price?.toString() || "0"
    }
    else if (detail === ProductDetailType.PRICE_TOTAL || detail === ProductDetailType.PRICE_TOTAL_INCL_ZERO) {
      text = (cartItem.product.price * cartItem.quantity)?.toString() || "0"
    }
    else {
      text = getProductDetailText(cartItem.product as unknown as MagentoProduct, detail).text as string;
    }

    if (text) {
      productDetails.push({
        type: detail,
        text,
      });
    }
  }

  return productDetails;
}



export function mapPrintOnDemandItemsToProductDetails(product: any, details: ProductDetailType[]) {
  const productDetails: ProductDetail[] = [];
  for (let detail of details) {
    let {text} = getProductDetailText(product, detail);

    if (detail === ProductDetailType.PRINT_PROFILE) {
      text = product?.profile_name;
    }

    if (text) {
      productDetails.push({
        type: detail,
        text,
      });
    }
  }

  return productDetails;
}

export function mapPdfCollectionItemsToProductDetails(product: any, details: ProductDetailType[]) {
  const productDetails: ProductDetail[] = [];
  for (let detail of details) {
    let {text} = getProductDetailText(product, detail);

    if (text) {
      productDetails.push({
        type: detail,
        text,
      });
    }
  }

  return productDetails;
}
