





























































































































import {computed, defineComponent, onMounted, reactive} from '@nuxtjs/composition-api'
import {SfButton, SfComponentSelect, SfInput, SfSelect, SfLoader} from '@storefront-ui/vue'
import DsbModal from '~/components/Modal/DsbModal.vue'
import {PageCategory} from '~/types/PageCategory'
import DsbInput from "~/components/Form/DsbInput.vue";
import {useLogin} from "~/modules/login/composables/useLogin";
import {LoginEnterId, LoginLink} from '~/modules/login/types';
import {ref} from "@nuxtjs/composition-api";
import {getFormattedDebitorId} from "~/modules/customer/helpers/debitorFormatter";

export default defineComponent({
  name: 'DsbLoginEnterDebitorId',
  components: {
    DsbInput,
    SfLoader,
    DsbModal,
    SfButton,
    SfComponentSelect,
    SfSelect,
    SfInput,
  },
  setup() {
    const {
      user,
      fetchDebitorIds,
      loginWithDebitorId,
      loading: loadingUser,
      loginWithBenslId,
      selectValue,
      selectValidate,
      selectValid,
      selectErrorMessages
    } = useLogin()

    const errorMessage = ref('')

    const inputs = reactive({
      first: '',
      second: '',
      third: ''
    })

    onMounted(async () => {
      if (!user.value?.debitorIds?.length) {
        await fetchDebitorIds()
      }
    })

    async function loginWithSelectedDebitorId(debitorId: string) {
      if (debitorId) {
        await loginWithDebitorId(debitorId.replace('/', ''))
      }
    }

    async function loginWithCustomDebitorId() {
      if (inputs.first == '' || inputs.second == '' || inputs.third == '') {
        errorMessage.value = 'Felder dürfen nicht leer sein'
        return
      } else {
        const customDebitorId = getDebitorId();
        const debitorIdValid = validateCustomDebitorId(customDebitorId);

        if (debitorIdValid === false) {
          errorMessage.value = 'Sie sind nicht berechtigt diese Vermittlernummer zu verwenden.'
          return;
        } else {
          errorMessage.value = '';
          await loginWithDebitorId(customDebitorId)
        }
      }
    }

    function getDebitorId() {
      return inputs.first + inputs.second + inputs.third
    }

    function validateCustomDebitorId(debitorId) {
      let matchedAllowedDebitorPrefixes = false;

      user.value.debitorIds.forEach(value => {
        if (debitorId === value) {
          matchedAllowedDebitorPrefixes = true;
        }
      });

      user.value.allowedWildcards.forEach(value => {
        let prefix = value.replace('*', '');
        const regExTest = new RegExp('^' + prefix);
        if (regExTest.test(debitorId) === true) {
          matchedAllowedDebitorPrefixes = true;
        }
      });

      return matchedAllowedDebitorPrefixes;
    }

    const isUserLoading = computed(() => {
      return loadingUser.value
    })

    const isAutoLogin = computed(() => {
      return (isUserLoading.value) ? false : user.value?.isAutoLogin
    })

    const isLoginWithBenslIdVisible = computed(() => {
      return user.value?.isLoginWithBenslIdVisible
    })

    const isLoginWithDebitorIdVisible = computed(() => {
      return user.value?.isLoginWithDebitorIdVisible
    })

    const isLoginWithDebitorSelectionVisible = computed(() => {
      return user.value?.isLoginWithDebitorSelectionVisible
    })

    const isLoginWithCustomDebitorIdVisible = computed(() => {
      return user.value?.isLoginWithCustomDebitorIdVisible
    })

    return {
      isUserLoading,
      isAutoLogin,
      user,
      PageCategory,
      LoginLink,
      LoginEnterId,
      inputs,
      getDebitorId,
      loginWithCustomDebitorId,
      loginWithSelectedDebitorId,
      errorMessage,
      getFormattedDebitorId,
      loginWithBenslId,
      isLoginWithBenslIdVisible,
      isLoginWithDebitorIdVisible,
      isLoginWithDebitorSelectionVisible,
      isLoginWithCustomDebitorIdVisible,
      selectValue,
      selectValidate,
      selectValid,
      selectErrorMessages
    }
  },
})
