














































import {
  toRef,
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';
import userAddressesGetters from "~/modules/customer/getters/userAddressesGetters";
import {MapAddressField} from "~/types/Addresses";

export default defineComponent({
  name: 'DsbUserAddressDetails',
  props: {
    address: {
      type: Object,
      required: true,
    },
    showAddressDefault: {
      type: Boolean,
      default: true
    },
  },

  setup(props) {
    const address = toRef(props, 'address');

    const userAddress = computed(() => ({
      id: userAddressesGetters.getId(address.value),
      company: userAddressesGetters.getCompanyName(address.value),
      [MapAddressField.NAME]: userAddressesGetters.getName(address.value),
      [MapAddressField.FREE_TEXT1]: userAddressesGetters.getFreeText1(address.value),
      [MapAddressField.FREE_TEXT2]: userAddressesGetters.getFreeText2(address.value),
      firstName: userAddressesGetters.getFirstName(address.value),
      lastName: userAddressesGetters.getLastName(address.value),
      street: userAddressesGetters.getStreetName(address.value),
      postalCode: userAddressesGetters.getPostCode(address.value),
      city: userAddressesGetters.getCity(address.value),
      province: userAddressesGetters.getProvince(address.value) || '',
      country: userAddressesGetters.getCountry(address.value),
      phone: userAddressesGetters.getPhone(address.value),
      isDefault: userAddressesGetters.isDefault(address.value),
      isDefaultShipping: userAddressesGetters.isDefaultShipping(address.value),
      isDefaultBilling: userAddressesGetters.isDefaultBilling(address.value),
    }));

    const addressDefaultText = computed(() => {
      if (userAddress.value.isDefaultShipping) return 'Stammadresse';
      return '';
    });

    return {
      userAddress,
      addressDefaultText,
      MapAddressField
    };
  },
});
