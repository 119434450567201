





































import {defineComponent, onMounted, useRouter} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import {SfButton, SfImage, SfHeading} from '@storefront-ui/vue';
import type { NuxtError } from '@nuxt/types';
import {getInstance} from "~/helpers/hooks/getInstance";
import {usePage} from "~/composables";
import {PageCategory} from "~/types/PageCategory";
import {ErrorMessage, General} from '~/types/Page'

export default defineComponent({
  components: {
    SfButton,
    SfImage,
    SfHeading
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  // @ts-ignore
  head() {
    return {
      // @ts-ignore
      title: this.error.statusCode === 404 ? this.$t(`${PageCategory.PAGE}.${General.TITLE}`) : this.$t(`${PageCategory.PAGE}.${General.ERROR_TITLE}`)
    }
  },
  setup(props) {
    const router = useRouter();

    const vm = getInstance();
    const { pageTitle } = usePage()

    // @ts-ignore
    pageTitle.value = props.error.statusCode === 404 ? vm.proxy.$i18n.t(`${PageCategory.PAGE}.${General.TITLE}`).toString() : vm.proxy.$i18n.t(`${PageCategory.PAGE}.${General.ERROR_TITLE}`).toString()

    onMounted(() => {
      const backToHomeButton = document.querySelector('#back-to-home-button') as HTMLElement
      backToHomeButton.focus()
    })

    return {
      router,
      PageCategory,
      General,
      ErrorMessage
    };
  }
});
