<template>
  <section
    class="sf-modal"
    :class="[staticClass, className]"
  >
    <SfOverlay
      v-if="overlay"
      class="sf-modal__overlay"
      :transition="transitionOverlay"
      :visible="visible"
    >
    </SfOverlay>
    <transition :name="transitionModal">
      <div
        v-if="visible"
        v-focus-trap
        v-click-outside="checkPersistence"
        class="sf-modal__container"
        role="dialog"
        aria-modal="true"
        :aria-label="title"
      >
        <div ref="close-button">
          <SfButton
            :class="{ 'display-none': !cross }"
            class="sf-button--pure sf-modal__close"
            type="button"
            data-testid="close-button"
            :aria-label="closeModalAriaLabel"
            @click="close"
          >
            <slot name="close">
              <SfIcon icon="cross" size="0.875rem" color="gray-secondary"/>
            </slot>
          </SfButton>
        </div>

        <div ref="content" class="sf-modal__content">
          <slot/>
        </div>
      </div>
    </transition>
  </section>
</template>
<script>
import { SfBar, SfOverlay, SfIcon, SfButton } from '@storefront-ui/vue';
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { focusTrap } from "@storefront-ui/vue/src/utilities/directives";
import { clickOutside } from "@storefront-ui/vue/src/utilities/directives";
import { isClient } from "@storefront-ui/vue/src/utilities/helpers";

export default {
  name: "SfModal",
  directives: { focusTrap, clickOutside },
  components: {
    SfBar,
    SfOverlay,
    SfIcon,
    SfButton,
  },
  model: {
    prop: "visible",
    event: "close",
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    cross: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    transitionOverlay: {
      type: String,
      default: "sf-fade",
    },
    transitionModal: {
      type: String,
      default: "sf-fade",
    },
    closeModalAriaLabel: {
      type: String,
      default: "Modal schließen",
    },
    querySelectorFirstFocus: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      staticClass: null,
      className: null,
      lastActiveElement: null
    };
  },
  watch: {
    visible: {
      handler: function (value) {
        if (!isClient) return;
        if (value) {
          this.$nextTick(() => {
            disableBodyScroll(this.$refs.content);
            this.lastActiveElement = document.activeElement

            if (this.querySelectorFirstFocus && this.$refs["content"]?.querySelector(this.querySelectorFirstFocus)) {
              this.$refs["content"]?.querySelector(this.querySelectorFirstFocus).focus()
            } else {
              this.$refs["close-button"]?.querySelector("button").focus()
            }
          });
          document.addEventListener("keydown", this.keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener("keydown", this.keydownHandler);

          if (this.lastActiveElement) {
            this.lastActiveElement?.focus()
          }
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
  },
  methods: {
    close() {
      this.$emit("close", false);
    },
    checkPersistence() {
      if (!this.persistent) {
        this.close();
      }
    },
    keydownHandler(e) {
      if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
        this.close();
      }
    },
    classHandler() {
      if (this.staticClass !== this.$vnode.data.staticClass) {
        this.staticClass = this.$vnode.data.staticClass;
      }
      if (this.className !== this.$vnode.data.class) {
        this.className = this.$vnode.data.class;
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/molecules/SfModal.scss";
</style>
