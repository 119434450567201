import {useContext, useRoute} from '@nuxtjs/composition-api'
import {getProductLink} from "~/modules/deeplink/composables/useDeeplink/getProductLink";

export function useDeeplink() {
  const app = useContext();

  const route = useRoute();
  const queryParams = route.value.query;

  const resolveDeeplinkSku = async () => {
    let deeplinkRequest: { key: string, value: string }
    let url: string

    if (queryParams) {
      if (queryParams.nrl) {
        const skuList = queryParams.nrl.toString()
        url = createSearchUrl(skuList)
        deeplinkRequest = {
          key: 'nrl',
          value: '?nrl=' + url
        }
      }

      if (queryParams.nr) {
        deeplinkRequest = {
          key: 'nr',
          value: queryParams.nr.toString()
        }
        url = '?nr=' + deeplinkRequest.value
      }

      if (app.$vsf.$magento.config.state.getCustomerToken()) {
        if (deeplinkRequest?.key === 'nr') {
          url = await getUrlBySku(deeplinkRequest.value)
        }
        redirectToUrl(url)
        return
      }
      saveDeeplinkAsCookie(url)
      app.redirect('/login')
    }
  }

  const redirectToUrl = (url) => {
    app.$cookies.remove('deeplinkUrl')
    app.redirect(url)
  }

  const redirectToDeeplinkCookie = async (cookie: string) => {
    if (cookie && cookie.includes('nr=')) {
      const sku = cookie.replace(/.*=/g,"")
      cookie = await getUrlBySku(sku)
    }
    redirectToUrl(cookie)
  }

  const saveDeeplinkAsCookie = (url) => {
    app.$cookies.set('deeplinkUrl', url)
  }

  const createSearchUrl = (skus: string): string => {
    return '/search/concreteResults?term=' + skus
  }

  const getUrlBySku = async (sku: string | string[]): Promise<string> => {
    const {load} = getProductLink()

    const result = await load({
      filter: {
        sku: {
          eq: sku
        }
      }
    })

    const resultItems = result.data?.['products']['items']
    if (resultItems.length == 1) {
      return resultItems[0].url_key + '.html'
    }

    return '/404'
  }

  const deeplinkParamsExist = (): boolean => {
    if (queryParams.nr && queryParams.nr != '') {
      return true
    }
    if (queryParams.nrl && queryParams.nrl != '') {
      return true
    }
    return false
  }

  const getDeeplinkCookie = ():string => {
    const deeplinkUrl = app.$cookies.get('deeplinkUrl');

    if (!deeplinkUrl) {
      return ''
    }
    return deeplinkUrl
  }

  return {
    resolveDeeplinkSku,
    redirectToUrl,
    getDeeplinkCookie,
    redirectToDeeplinkCookie,
    deeplinkParamsExist
  }
}
