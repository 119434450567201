import { FavoritesContingent, FavoritesGeneral } from '../../types/favorites/favoritesCategories'
import { General } from "../../types/Page";

export default {
  [General.TITLE]: 'Favoriten',
  [FavoritesGeneral.DESCRIPTION]: '',

  [FavoritesContingent.CONTINGENT_INFO]: 'Sie können nur noch maximal {contingent} Stück bestellen.',
  [FavoritesContingent.CONTINGENT_FIX]: 'Korrigieren',
}
