import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d935788 = () => interopDefault(import('../modules/checkout/pages/Checkout/Cart.vue' /* webpackChunkName: "" */))
const _a301d77a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _deb8aae2 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _2f52fc9a = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _78e8446c = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _a5ccad1e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1d6e0a76 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _740316af = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _2e51f63e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _6c8eb428 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _456c602e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyData.vue' /* webpackChunkName: "" */))
const _35382187 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _18c4e0c6 = () => interopDefault(import('../modules/customer/pages/MissingAliasEmail.vue' /* webpackChunkName: "" */))
const _41ad4caf = () => interopDefault(import('../modules/wishlist/pages/MyWishlist.vue' /* webpackChunkName: "" */))
const _763b5a69 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _632b1e0e = () => interopDefault(import('../modules/license/pages/InvalidLicenses.vue' /* webpackChunkName: "" */))
const _dbf58022 = () => interopDefault(import('../modules/pdfCollection/pages/Kollektion.vue' /* webpackChunkName: "" */))
const _d817e51c = () => interopDefault(import('../modules/license/pages/Licenses.vue' /* webpackChunkName: "" */))
const _6c49a110 = () => interopDefault(import('../modules/login/pages/LoginPage.vue' /* webpackChunkName: "" */))
const _731f1fce = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _7fb0db40 = () => interopDefault(import('../modules/web2print/pages/personalisierte-dokumente.vue' /* webpackChunkName: "" */))
const _251be1a4 = () => interopDefault(import('../pages/PortalResponsibility.vue' /* webpackChunkName: "" */))
const _46c9eb80 = () => interopDefault(import('../pages/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _08019616 = () => interopDefault(import('../modules/customer/pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _5a076b83 = () => interopDefault(import('../modules/savedCarts/pages/SavedCarts.vue' /* webpackChunkName: "" */))
const _fc10ee60 = () => interopDefault(import('../modules/catalog/pages/search.vue' /* webpackChunkName: "" */))
const _388e7664 = () => interopDefault(import('../modules/customer/pages/InternalUser/UpdateRequiredData.vue' /* webpackChunkName: "" */))
const _eab6f540 = () => interopDefault(import('../modules/payNext/pages/PaymentReturn.vue' /* webpackChunkName: "" */))
const _526a3522 = () => interopDefault(import('../modules/catalog/search/concrete-search-results.vue' /* webpackChunkName: "" */))
const _74c27c30 = () => interopDefault(import('../modules/catalog/search/recently-updated.vue' /* webpackChunkName: "" */))
const _02ff7b52 = () => interopDefault(import('../modules/login/pages/LoginError.vue' /* webpackChunkName: "" */))
const _0277570e = () => interopDefault(import('../modules/campaigns/pages/Campaign.vue' /* webpackChunkName: "" */))
const _749ed167 = () => interopDefault(import('../modules/web2print/pages/configurator.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _0d935788,
    name: "cart___default"
  }, {
    path: "/checkout",
    component: _a301d77a,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _deb8aae2,
      name: "billing___default"
    }, {
      path: "payment",
      component: _2f52fc9a,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _78e8446c,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _1d6e0a76,
      name: "user-account___default"
    }]
  }, {
    path: "/Cms",
    component: _740316af,
    name: "Cms___default"
  }, {
    path: "/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "/my-data",
      component: _456c602e,
      name: "my-data___default"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/customer-missing-email",
    component: _18c4e0c6,
    name: "customer-missing-email___default"
  }, {
    path: "/favorites",
    component: _41ad4caf,
    name: "favorites___default"
  }, {
    path: "/Home",
    component: _763b5a69,
    name: "Home___default"
  }, {
    path: "/invalid-licenses",
    component: _632b1e0e,
    name: "invalid-licenses___default"
  }, {
    path: "/kollektion",
    component: _dbf58022,
    name: "kollektion___default"
  }, {
    path: "/licenses",
    component: _d817e51c,
    name: "licenses___default"
  }, {
    path: "/login",
    component: _6c49a110,
    name: "login___default"
  }, {
    path: "/Page",
    component: _731f1fce,
    name: "Page___default"
  }, {
    path: "/personalisierte-dokumente",
    component: _7fb0db40,
    name: "personalisierte-dokumente___default"
  }, {
    path: "/portal-responsibility",
    component: _251be1a4,
    name: "portal-responsibility___default"
  }, {
    path: "/PortalResponsibility",
    component: _251be1a4,
    name: "PortalResponsibility___default"
  }, {
    path: "/privacy-policy",
    component: _46c9eb80,
    name: "privacy-policy___default"
  }, {
    path: "/PrivacyPolicy",
    component: _46c9eb80,
    name: "PrivacyPolicy___default"
  }, {
    path: "/reset-password",
    component: _08019616,
    alias: "/customer/account/createPassword",
    name: "reset-password___default"
  }, {
    path: "/saved-carts",
    component: _5a076b83,
    name: "saved-carts___default"
  }, {
    path: "/search",
    component: _fc10ee60,
    name: "search___default"
  }, {
    path: "/update-required-data",
    component: _388e7664,
    name: "update-required-data___default"
  }, {
    path: "/checkout/process-payment",
    component: _eab6f540,
    name: "payment-return___default"
  }, {
    path: "/search/concreteResults",
    component: _526a3522,
    name: "concreteResults___default"
  }, {
    path: "/search/recently-updated",
    component: _74c27c30,
    name: "recently-updated___default"
  }, {
    path: "/login/error/:error_code",
    component: _02ff7b52,
    name: "login-error___default"
  }, {
    path: "/kampagnen/:slug/:uid",
    component: _0277570e,
    props: true,
    name: "campaign___default"
  }, {
    path: "/web2print/:id/:slug",
    component: _749ed167,
    name: "web2print___default"
  }, {
    path: "/",
    component: _763b5a69,
    name: "home___default"
  }, {
    path: "/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _731f1fce,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
