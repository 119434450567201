import {useCustomerStore} from "~/modules/customer/stores/customer";
import {useContext, useRouter} from "@nuxtjs/composition-api";

const isServer = process.server

export function useInternalUser(){
  const app = useContext();
  const customerStore = useCustomerStore();
  const router = useRouter();
  const updateRequiredDataUrl = '/update-required-data';

  const validate = (): boolean => {
    if(customerStore.isInternal === false){
      return true
    }
    if(hasCustomerNoAddress(customerStore.user)){
      return false
    }
    if(updateRequiredDataUrl === router.currentRoute.path){
      return true
    }
    return true
  }

  const hasCustomerNoAddress = (user): boolean => {
    const addresses = user.addresses;

    if(user.default_shipping === null){
      return true
    }

    // @ts-ignore
    if(addresses.length === 0){
      return true
    }
  }

  const redirect = async (url) => {
    if (isServer) {
      app.redirect(url)
    }else {
      return await router.push({path: url})
    }
  }

  return {
    validate,
    hasCustomerNoAddress,
    redirect,
    updateRequiredDataUrl
  }
}
