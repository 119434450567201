import {useApi} from "~/composables";
import {defaultConfig} from "~/modules/core/defaultConfig";
import {useContext} from "@nuxtjs/composition-api";

export const placeOrderCommand = () => {
  const {
    mutate
  } = useApi();

  const { app } = useContext();

  const GRAPHQL_ENDPOINT = '/graphql';
  const customerToken = app.$cookies.get(defaultConfig.cookies.customerCookieName);

  const placeOrderMutation = `
  mutation placeOrder($input: PlaceOrderInput) {
    placeOrder(input: $input) {
      order {
        order_number
      }
      paynext_redirect_url
    }
  }
 `;

  // const mutation = async (variables) => {
  //   return await mutate<Mutation>(
  //     placeOrderMutation,
  //     variables
  //   )
  // }

  const mutation = async (variables: any) => {
    const response = await fetch(GRAPHQL_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${customerToken}`
      },
      body: JSON.stringify({ query: placeOrderMutation, variables: variables })
    });

    const {data, errors} = await response.json()

    return {
      data, errors
    }
  }

  return {
    mutation,
  };
};
