import {computed, ref} from "@nuxtjs/composition-api";
import {MagentoProduct} from "~/types/Product";
import productGetters from "~/modules/catalog/product/getters/productGetters";

export function useProductVersion(product) {

  const sku = computed(() => {
    return product?.value?.sku
  })

  const skuDigital = computed(() => {
    return getActiveDigitalVersionSku(product.value)
  })

  const skuPhysical = computed(() => {
    return getActivePhysicalVersionSku(product.value)
  })

  return {
    sku,
    skuDigital,
    skuPhysical
  }
}

export function getActiveDigitalVersionName(product: MagentoProduct): string
{
  if(!product?.active_versions?.digital?.version_name){
    return ''
  }
  return productGetters.getProductSkuText(product?.active_versions?.digital?.is_singular_version, product?.active_versions?.digital?.version_name)
}

export function getActivePhysicalVersionName(product: MagentoProduct): string
{
  if(!product?.active_versions?.physical?.version_name){
    return ''
  }
  return productGetters.getProductSkuText(product?.active_versions?.physical?.is_singular_version, product?.active_versions?.physical?.version_name)
}


export function getActiveDigitalVersionSku(product: MagentoProduct): string
{
  return product?.active_versions?.digital?.sku || ''
}

export function getActivePhysicalVersionSku(product: MagentoProduct): string
{
  return product?.active_versions?.physical?.sku || ''
}
