export const customAddProductsToCart = `
  mutation addProductsToCart($cartId: String!, $cartItems: [CartItemInput!]!) {
    addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
      user_errors {
        code
        message
      }
      cart {
       id
       items {
          quantity
          product {
            sku
            name
          }
       }
       total_quantity
      }
    }
  }
 `;
