export interface ProductDetailOptions {
  [key: string]: string | boolean | number;
}

export interface ProductDetail {
  type: ProductDetailType;
  text: string | number;
  options?: ProductDetailOptions;
}

export enum ProductDetailType {
  ARTICLE_NUMBER = "details_articleNr",
  VERSION = "details_version",
  VERSION_PRINT = "details_version_print",
  VERSION_DOWNLOAD = "details_version_download",
  LICENCE_VERSION = "details_licence_version",
  AVAILABILITY = "details_availability",
  LICENCE = "details_licence",
  LICENCE_EXPIRED = "details_licence_expired",
  ORDERED_QUANTITY = "details_order_quantity",
  CANCELABLE = "cancelable",
  ORDERED = "ordered_qty",
  OUTSTANDING = "outstanding_qty",
  DELIVERY_STATUS = "detail_delivery_status",
  PRINT_PROFILE = "detail_print_profile",
  PRICE = "details_price",
  PRICE_INCL_ZERO = "details_price_incl_zero",
  PRICE_TOTAL = "details_price_total",
  PRICE_TOTAL_INCL_ZERO = "details_price_total_incl_zero",
}
