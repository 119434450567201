import { computed, ref } from "@nuxtjs/composition-api";
import { licenceTermsMock } from "~/mocks/licence-terms.mock";
import { getFormattedDate } from "~/utils/dateFormatter";
import { useProductVersion } from "~/composables/useProductVersion";
import { getChildProductBySku } from "~/composables/useProductDetails/productType";
import { MagentoProduct } from "~/types/Product";

const licenceTermsModalVisible = ref(false);
const licenceAgreementModalVisible = ref(false);

export function useLicense(product) {
  const licenceTerms = ref(licenceTermsMock);

  const hasLicense = computed(() => {
    return !!product.value?.license_expiration_date;
  });

  const getLicenseDate = computed((): string => {
    if (!hasLicense.value) {
      return null;
    }

    return product.value?.license_expiration_date;
  });

  const isLicenseExpired = computed(() => {
    if (!hasLicense.value) {
      return false;
    }

    if (product.value) {
      if (isLicenseDateExpired(product.value.license_expiration_date)) {
        return true;
      }
    }

    return false;
  });

  return {
    licenceTerms,
    licenceTermsModalVisible,
    licenceAgreementModalVisible,
    hasLicense,
    isLicenseExpired,
    getLicenseDate
  };
}

function isLicenseDateExpired(licenseExpirationDate: string): boolean {
  const expirationDate = new Date(licenseExpirationDate);
  const today = new Date();

  if (expirationDate <= today) {
    return true;
  }

  return false;
}

export function getLicenseExpirationDate(licenseExpirationDate: string): string {
  if (!isLicenseDateExpired(licenseExpirationDate)) {
    return getFormattedDate(licenseExpirationDate);
  }
}

function hasProductLicence(licenseExpirationDate) {
  if (licenseExpirationDate == "" || licenseExpirationDate == null) {
    return false;
  }

  return true;
}

export function getLicenceVersion(product: MagentoProduct): string {
  return product?.active_versions?.physical?.version_name || "";
}
