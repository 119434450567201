import { customRef, Ref, ref } from '@nuxtjs/composition-api'

const quantityBySku = ref<Map<string, number>>(new Map())

export function useQuantity (sku?: string) {
  let quantity: Ref<number>

  if(sku) {
    function quantityRef(value: number) {
      return customRef<number>((track, trigger) => ({
        set(inValue) {
          quantityBySku.value.set(sku, inValue)
          trigger()
        },
        get() {
          track()
          return quantityBySku.value.get(sku) || 1
        }
      }))
    }

    quantity = quantityRef(1)
  } else {
    quantity = ref(1)
  }

  const tempQuantity = ref(quantity.value)

  function incrementQuantity () {
    tempQuantity.value++
  }

  function decrementQuantity () {
    if (tempQuantity.value > 1) {
      tempQuantity.value--
    }
  }

  return { tempQuantity, quantity, incrementQuantity, decrementQuantity }
}
