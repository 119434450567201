/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      aggregations (filter: {category: {includeDirectChildrenOnly: true}}) {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
      items {
        ... on ConfigurableProduct {
          __typename
          uid
          sku
          name
          short_description {
            html
          }
          url_key
          url_rewrites {
            url
          }
          active_versions {
            digital {
              version_name
              sku
              is_singular_version
            }
            physical {
              version_name
              sku
              is_singular_version
            }
          }
          permissions {
              job_level
              job_qualifications
              sales_channel
              sales_region
              user_groups
              user_type
          }
          ... on ConfigurableProduct {
            variants{
              product {
                sku
                thumbnail {
                  url
                }
              }
            }
          }

        }

        ... on SimpleProduct {
          __typename
          uid
          sku
          name
          short_description {
            html
          }
          url_key
          url_rewrites {
            url
          }
          active_versions {
            digital {
              version_name
              sku
              is_singular_version
            }
            physical {
              version_name
              sku
              is_singular_version
            }
          }
          thumbnail {
            url
          }
          permissions {
              job_level
              job_qualifications
              sales_channel
              sales_region
              user_groups
              user_type
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
