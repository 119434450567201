export enum SearchResults {
  SEARCH_SUGGESTIONS = 'results_searchSuggestions',
  AUTOCOMPLETE = 'results_autocomplete',
  HISTORY = 'results_history',
  PRODUCTS = 'results_products',
  COLLECTIONS = 'results_collections',
  SHOW_ALL_RESULTS = 'results_all'
}

export enum SearchListItem {
  MORE = 'searchListItem_more',
  SCREENREADER_MORE = 'screanreader-searchListItem_more',
}

export enum General {
  SEARCH = 'general_search',
  SEARCH_CONCRETE_TITLE = 'general_search_concrete_title',
  SEARCH_OPEN = 'general_search_open',
  SEARCH_CLOSE = 'general_search_close',
  SEARCH_LABEL = 'general_search_label',
  SEARCH_INPUT = 'general_searchInput',
  SEARCH_INPUT_LONG = 'general_searchInput_long',
  SEARCH_CLEAR='general_searchInput_clear',
  SEARCH_RECENTLY_UPDATED='general_search_recently_updated'
}

export enum Category {
  SEARCH = 'category_search',
  SEARCH_INPUT = 'category_searchInput'
}
