



























































































import {computed, defineComponent, ref} from "@nuxtjs/composition-api";
import { SfButton } from "@storefront-ui/vue";
import { useQuantity } from "~/components/AddToCart/composables/quantity";
import SpinnerSvg from "~/components/utils/SpinnerSvg.vue";
import { PageCategory } from "~/types/PageCategory";
import { ActionType, ActionTypeScreenreader } from "~/types/Action";
import { PropType } from "vue";
import { useErrorMessage } from "~/composables/useErrorMessage";
import ErrorMessage from "~/components/ProductActions/ErrorMessage.vue";
import { useLicense } from "~/composables";
import DsbConfirmationModal from "~/components/Modal/DsbConfirmationModal.vue";
import { useDownloadAction } from "~/composables";
import { useProductVersion } from "~/composables/useProductVersion";
import useCart from "~/modules/checkout/composables/useCart";
import { ProductGeneral } from "~/types/ProductCategories";
import {ProductInterface} from "~/modules/GraphQL/types";
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useI18n } from "~/helpers/hooks/usei18n";

export default defineComponent({
  name: "AddToCart",
  components: {
    ErrorMessage,
    SfButton,
    SpinnerSvg,
    DsbConfirmationModal,
  },
  props: {
    product: {
      required: true,
      type: Object as PropType<ProductInterface>,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  emits: ["validate"],
  setup(props) {
    const { tempQuantity, quantity, incrementQuantity, decrementQuantity } = useQuantity(
      props.product.sku
    );
    const {
      addToCart: addToCartBase,
      error: errorFromCart,
      lastUpdatedCartSku,
      isAddToCardInProcess,
      addToCartScreenReaderMessage,
    } = useCart();

    const currentProduct = ref(props.product);
    const {
      licenceTerms,
      licenceAgreementModalVisible,
      hasLicense,
      getLicenseDate,
    } = useLicense(currentProduct);
    const { skuPhysical } = useProductVersion(currentProduct);

    const { acknowledgeLicenseAgreement } = useDownloadAction(
      "addToCart_" + props.product.sku
    );
    const { setErrorMessage, resetAllErrors } = useErrorMessage(
      "addToCart_" + props.product.sku
    );

    const licenceTermsModalVisible = ref(false);

    const formattedLicenseDate = (): string => {
      const d = new Date(getLicenseDate.value);
      return d.toLocaleDateString("de-DE");
    };

    const i18n = useI18n();

    function addToCartValidation(product) {
      if(tempQuantity.value == 0) {
        setErrorMessage({
          name: "addItem",
          message: '0 ist ein ungültiger Wert. Die Menge muss mindestens 1 betragen.',
        });
        return
      }

      quantity.value = tempQuantity.value

      if (hasLicense.value) {
        licenceTermsModalVisible.value = true;
        return;
      }

      addToCart(product);
    }

    async function addToCart(product) {
      resetAllErrors();

      await addToCartBase(product);
      quantity.value = 1;

      if (errorFromCart?.value?.addItem && errorFromCart?.value?.addItem?.message) {
        setErrorMessage({
          name: "addItem",
          message: i18n.t(errorFromCart.value.addItem.message),
        });
      }
    }

    function agreeToLicense(product) {
      resetAllErrors();

      acknowledgeLicenseAgreement(skuPhysical.value).then(() => {
        licenceTermsModalVisible.value = false;
        addToCart(product);
      });
    }

    return {
      lastUpdatedCartSku,
      addToCartValidation,
      addToCart,
      quantity,
      incrementQuantity,
      decrementQuantity,
      PageCategory,
      ProductGeneral,
      ActionType,
      isAddToCardInProcess,
      ActionTypeScreenreader,
      addToCartScreenReaderMessage,
      licenceTerms,
      licenceTermsModalVisible,
      licenceAgreementModalVisible,
      hasLicense,
      agreeToLicense,
      currentProduct,
      formattedLicenseDate,
      productGetters,
      tempQuantity,
      disabledComputed: computed(() => props.disabled),
    };
  },
});
