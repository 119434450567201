import { useApi} from "~/composables";
import {CustomerQuery} from "~/modules/customer/composables/types";

export const getCustomer = () => {
  const {
    query
  } = useApi();

  /**
   * text_field is included in custom_attributes (attribute_code === 'text_field')
   */
  const getCustomerQuery = `
  query customer {
    customer {
      sales_region
      is_internal
      default_billing
      default_shipping
      email
      firstname
      is_subscribed
      lastname
      debitor_id
      is_favorites_notification_subscribed
      is_reorder_notification_subscribed
      has_dummy_email
      alias_email
      addresses {
        address_line_2
        address_line_3
        sap_name
        city
        country_code
        default_billing
        default_shipping
        custom_attributes {
          attribute_code
          value
        }
        extension_attributes {
          attribute_code
          value
        }
        firstname
        id
        lastname
        company
        postcode
        prefix
        street
        suffix
        telephone
        vat_id
      }

    }
  }`;

  const load = async () => {
    return await query<CustomerQuery>(
      getCustomerQuery
    )
  }

  return {
    load,
  };
};
