<template>
  <div
    class="sf-input"
    :class="{
      'has-text': !!value,
      invalid: !valid,
    }"
    :data-testid="name"
  >
    <div class="sf-input__wrapper">
      <label
        :class="{ 'display-none': !label }"
        class="sf-input__label"
        :for="name"
      >
        <slot name="label" v-bind="{ label }">{{ label }}</slot>
      </label>
      <input
        :id="idWithoutWhitespace"
        v-focus
        v-bind="$attrs"
        :value="value"
        :required="required"
        :disabled="disabled"
        :name="name"
        :class="{ 'sf-input--is-password': isPassword }"
        :type="inputType"
        v-on="listeners"
      />
      <span class="sf-input__bar"></span>
      <slot
        v-bind="{
          isPasswordVisible,
          switchVisibilityPassword,
        }"
        name="show-password"
      >
        <button
          :class="{ 'display-none': !isPassword }"
          class="sf-input__password-button"
          type="button"
          aria-label="Passwort anzeigen"
          :aria-pressed="isPasswordVisible.toString()"
          @click="switchVisibilityPassword"
        >
          <span class="dsb-icon" :class="isPasswordVisible? 'dsb-password-show-icon': 'dsb-password-hide-icon'"></span>
        </button>
      </slot>
    </div>
    <div class="sf-input__error-message">
      <transition name="sf-fade">
        <slot name="error-message" v-bind="{ errorMessage }">
          <div :class="{ 'display-none': valid }" role="status">{{ errorMessage }}</div></slot
        >
      </transition>
    </div>
  </div>
</template>
<script>
import { SfIcon, SfButton } from '@storefront-ui/vue'
import { focus } from "@storefront-ui/vue/src/utilities/directives/focus/focus-directive";

export default {
  name: "DsbInput",
  directives: {
    focus,
  },
  components: { SfIcon, SfButton },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    valid: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
      description: "Native input required attribute",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Native input disabled attribute",
    },
    hasShowPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPasswordVisible: false,
      inputType: "",
      isNumberTypeSafari: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event.target.value),
      };
    },
    isPassword() {
      return this.type === "password" && this.hasShowPassword;
    },
    idWithoutWhitespace() {
      return this.name.replace(/\s/g, "");
    },
  },
  watch: {
    type: {
      immediate: true,
      handler: function (type) {
        let inputType = type;
        // Safari has bug for number input
        if (typeof window !== "undefined" || typeof document !== "undefined") {
          const ua = navigator.userAgent.toLocaleLowerCase();
          if (
            ua.indexOf("safari") !== -1 &&
            ua.indexOf("chrome") === -1 &&
            type === "number"
          ) {
            this.isNumberTypeSafari = true;
            inputType = "text";
          }
        }
        this.inputType = inputType;
      },
    },
    value: {
      immediate: true,
      handler: function (value) {
        if (!this.isNumberTypeSafari) return;
        if (isNaN(value)) {
          this.$emit("input");
        }
      },
    },
  },
  methods: {
    switchVisibilityPassword() {
      this.isPasswordVisible = !this.isPasswordVisible;
      this.inputType = this.isPasswordVisible ? "text" : "password";
    },
  },
};
</script>
