






























import { defineComponent } from '@vue/composition-api'
import { General } from '~/types/homePage/HomepageCategories'
import { PageCategory } from '~/types/PageCategory'

export default defineComponent({
  name: 'LoginHeader',
  setup () {
    return {
      PageCategory,
      General,
    }
  },
})
