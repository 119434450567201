import { defineStore } from 'pinia';
import {ProductInterface} from "~/modules/GraphQL/types";
interface PdfCollectionState {
  products: ProductInterface[],
}

export const usePdfCollectionStore = defineStore('pdfCollection', {
  state: (): PdfCollectionState => ({
    products: [],
  }),
});
