import { ref } from "@nuxtjs/composition-api";

const errors = {}

export function useErrorMessage (sku) {
  if(!errors.hasOwnProperty(sku)) {
    errors[sku] = ref({})
  }

  function resetAllErrors() {
    for (const [key] of Object.entries(errors)) {
      errors[key].value = {}
    }
  }

  function setErrorMessage(Error, clearOtherErrors = true){
    if(clearOtherErrors){
      resetAllErrors()
    }

    errors[sku].value = Error
  }

  return {
    error: errors[sku],
    setErrorMessage,
    resetAllErrors,
  }
}
