export enum LicencesGeneral {
  READ_TERMS = 'licence_read_terms',
  INVALID_LICENSE_TITLE = 'invalid_license_title'
}

export enum InvalidLicenceSubtitle {
  INVALID_LICENSE_SUBTITLE_1 = 'invalid_license_subtitle_1',
  INVALID_LICENSE_SUBTITLE_2 = 'invalid_license_subtitle_2',
}

export enum LicencesFilter {
  SHOW = 'licence_show',
  SORT_BY = 'licence_sort_by',
}

export enum LicenceListItem {
  ACCEPTED_DATE='licence_accepted_date',
  EXPIRATION_DATE='licence_expiration_date',
  WEEKS_LEFT = 'licence_weeks_left',
  EXPIRED = 'licence_expired',
  DETAILS = 'licence_product_details',
  SCREENREADER_DETAILS = 'screenreader_licence_product_details'
}
