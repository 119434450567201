import {computed, ref, useContext, useRoute, useRouter} from '@nuxtjs/composition-api'
import {mapLicenceItemsToProductDetails} from '~/utils/mapProductToProductDetails'
import {reactive} from "@vue/composition-api";
import {AgnosticPagination} from "@vue-storefront/core";
import {getFormattedDate} from "~/utils/dateFormatter";
import {getLicenses} from "~/modules/license/composables/useLicenseList/getLicenses";
import {getInvalidLicensesCount} from "~/modules/license/composables/useInvalidLicenses/getInvalidLicensesCount";
import {licenseDetails, SortDirection} from "~/modules/license/composables/useLicenseList";
import {fetchLicenceProducts as fetch} from "~/modules/license/helper/fetchLicenceProducts";
import productGetters from "~/modules/catalog/product/getters/productGetters";
import loginHeader from "~/modules/login/components/LoginHeader.vue";

const loading = ref(false)
const error = reactive({fetchLicenceProducts: null})

const rawLicenceProducts = ref([])
const LICENSE_COOKIE = 'Accepted-Licenses'

const licencesSortBySelection = ref(SortDirection.ASC)

const pagination = ref<AgnosticPagination>({
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  itemsPerPage: 10,
  pageOptions: [10, 50]
});

const isServer = process.server

async function fetchInvalidLicenceProducts(query: Function) {
  loading.value = true

  const filterParams = {
    "filter": {
      "valid": {
        "eq": "false"
      }
    },
    "sort": {
      'expires_at': licencesSortBySelection.value
    },
    "currentPage": pagination.value.currentPage,
    "pageSize": pagination.value.itemsPerPage
  }


  const result = await fetch(query, filterParams, error)
  pagination.value = {
    ...pagination.value,
    totalItems: result.licenses.total_count,
    itemsPerPage: result.licenses.page_info.page_size,
    currentPage: result.licenses.page_info.current_page,
    totalPages: Math.ceil(result.licenses.total_count / result.licenses.page_info.page_size),
  }

  rawLicenceProducts.value = result.licenses.items
  loading.value = false
}

export function useInvalidLicense() {
  const {load: getLicenseQuery} = getLicenses();
  const app = useContext();
  const router = useRouter();
  const route = useRoute()

  const setAcceptLicenseCookie = (value: boolean) => {
    app.$cookies.set(LICENSE_COOKIE, value, {maxAge: 86400})
  }

  const userHasNotAcceptedLicenses = async (): Promise<boolean> => {
    const cookie = app.$cookies.get(LICENSE_COOKIE)
    if (cookie) {
      return cookie === 'false'
    }
    const {load} = getInvalidLicensesCount()

    let userHasNotAcceptedLicenses = false;
    try {
      const result = await load({filter: {valid: {eq: false}}})

      userHasNotAcceptedLicenses = (result.data as any).licenses.total_count > 0
    } catch (error) {
      throw new Error('Could not load invalid licenses');
    }

    return userHasNotAcceptedLicenses;
  }

  const redirectUserWithInvalidLicenses = async () => {
    if (await userHasNotAcceptedLicenses()) {
      if (app.$cookies.get(LICENSE_COOKIE) != false) {
        setAcceptLicenseCookie(false)
      }
      redirect('/invalid-licenses')
    }
  }

  const redirect = (url) => {
    if (isServer) {
      app.redirect(url)
    } else {
      router.push(url)
    }
  }

  if (route.value.query.itemsPerPage) {
    pagination.value.itemsPerPage = parseInt(route.value.query.itemsPerPage as string)
  }

  if (route.value.query.page) {
    pagination.value.currentPage = parseInt(route.value.query.page as string) || pagination.value.currentPage
  }

  const licenceProducts = computed(() => rawLicenceProducts.value.map(product => ({
      sku: product.sku,
      name: product.name,
      url: productGetters.getParentUrl(product),
      text: product.description.html,
      imgSrc: productGetters.getProductThumbnailImage(product),
      details: mapLicenceItemsToProductDetails(product, licenseDetails),
      active_versions: product.active_versions,
      licence: {
        expirationDate: getFormattedDate(product.license?.expirationDate),
        weeksLeft: product.license?.weeksLeft,
        expired: product.license?.expired,
        termsAgreed: product.license?.termsAgreed,
        acceptedAt: getFormattedDate(product.license?.acceptedAt),
      },
    })
  ))

  return {
    fetchInvalidLicenceProducts: () => fetchInvalidLicenceProducts(getLicenseQuery),
    licenceProducts,
    redirectUserWithInvalidLicenses,
    userHasNotAcceptedLicenses,
    setAcceptLicenseCookie,
    pagination,
    loading,
    error
  }
}
