












import LoginHeader from '~/modules/login/components/LoginHeader.vue'
import LoginFooter from '~/modules/login/components/LoginFooter.vue'
import {
  useRoute, defineComponent, useFetch,
} from '@nuxtjs/composition-api';
import {useDeeplink} from "~/modules/deeplink/composables/useDeeplink";

export default defineComponent({
  name: 'LoginLayout',
  components: { LoginFooter, LoginHeader },
  setup () {
    const route = useRoute();

    const { deeplinkParamsExist, resolveDeeplinkSku} = useDeeplink()

    useFetch(async () => {
      if (deeplinkParamsExist()) {
        await resolveDeeplinkSku()
      }
    })

    return {
      route
    }
  }
})
