import { defineStore } from 'pinia';
import {Maybe, Scalars} from "~/modules/GraphQL/types";
import {UseFacetSearchResult} from "~/modules/catalog/category/composables/useFacet";
import {SearchBarId} from "~/modules/catalog/search/enum";


export interface Search {
  suggestions?: UseFacetSearchResult;
  term?: Maybe<Scalars['String']>;
  autocompleteResults?: Array<any>
}

interface SearchState {
  search: SearchBar,
}

interface SearchBar {
  [SearchBarId.HOMEPAGE]: Search,
  [SearchBarId.SEARCH_PAGE]: Search,
  [SearchBarId.HEADER]: Search,
}


export const useSearchStore = defineStore('search', {
  state: (): SearchState => ({
    search: {
      [SearchBarId.HOMEPAGE]: {},
      [SearchBarId.SEARCH_PAGE]: {},
      [SearchBarId.HEADER]: {},
    },
  }),
});
