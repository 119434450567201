export enum ProductGeneral {
  LICENSE_AGREEMENT_REQUIRED = "general_license_agreement_required",
  LICENSE_AGREEMENT_DEFAULT = "general_license_agreement_default",
  LICENSE_AGREEMENT_ACTION_SIGN = "general_license_agreement_action_sign",
  LICENSE_AGREEMENT_ACTION_DISMISS = "general_license_agreement_action_dismiss",
  LICENCE = "general_licence",
  LICENCE_ENDS = "general_licenceEnds",
  LICENCE_END_DATE = "general_licenceEndDate",
  BACK_TO_OVERVIEW = "general_backToOverview",
}

export enum ProductPreview {
  ZOOM = "preview_zoom",
}

export enum ProductAvailability {
  AVAILABLE = "availability_available",
  NOT_AVAILABLE = "availability_notAvailable",
  STILL_PIECES_AVAILABLE = "availability_still_pieces_available",
  DELIVERY_FROM_WEEK = "availability_delivery_from_week",
}
