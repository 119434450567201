import { customRef, Ref, ref } from "@nuxtjs/composition-api";

type Validator = (value: any) => boolean | string

export function useValidate<T>(inValue: T, inValidators: Validator[] = []) {
  const valid: Ref<boolean | undefined> = ref(undefined)
  const errorMessages: Ref<string[]> = ref([])
  const validators: Ref<Validator[]> = ref(inValidators)

  function validate(inValue: T) {
    valid.value = true
    for(const validator of validators.value) {
      const res = validator(inValue)
      if(typeof res == "string") {
        errorMessages.value.push(res)
      }

      if(!res === true) {
        valid.value = false
      }
    }

    return valid.value
  }

  function validationRef(value: T) {
    return customRef((track, trigger) => ({
      get() {
        track()
        return value
      },
      set(inValue: T) {
        errorMessages.value = []
        validate(inValue)
        value = inValue
        trigger()
      }
    }))
  }

  const value = validationRef(inValue)

  function reset() {
    value.value = undefined
    errorMessages.value = []
    valid.value = undefined
  }

  return {
    valid,
    validators,
    errorMessages,
    value,
    validate: () => validate(value.value),
    reset
  }
}
