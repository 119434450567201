import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';
import { ComposableFunctionArgs } from '~/composables';
import { useCartStore } from "~/modules/checkout/stores/cart";

export const loadTotalQtyCommand = {
  execute: async (context: VsfContext, params?: ComposableFunctionArgs<{}>) => {
    Logger.debug('[Magento]: Load cart total qty');

    const apiState = context.$magento.config.state;
    const cartStore = useCartStore();

    const cartId = cartStore.cart.id ?? apiState.getCartId()

    if (cartId) {
      const cartQuery = `
        query cartTotalQty($cartId: String!) {
          cart(cart_id:$cartId) {
            total_quantity
            items {
              id
              }
          }
        }`;

      const { data } = await context.$magento.api.customQuery({ query: cartQuery, queryVariables: {cartId: cartId} });

      return {
        // @ts-ignore
        totalQuantity: data?.cart?.total_quantity || 0,
        // @ts-ignore
        totalPositions: data?.cart?.items?.length || 0
      }
    }

    return {
      // @ts-ignore
      totalQuantity: 0,
      // @ts-ignore
      totalPositions: 0,
    }
  },
};
