import {
  computed, readonly, ref, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { addItemCommand } from '~/modules/checkout/composables/useCart/commands/addItemCommand';
import { applyCouponCommand } from '~/modules/checkout/composables/useCart/commands/applyCouponCommand';
import { loadCartCommand } from '~/modules/checkout/composables/useCart/commands/loadCartCommand';
import { loadTotalQtyCommand } from '~/modules/checkout/composables/useCart/commands/loadTotalQtyCommand';
import { removeCouponCommand } from '~/modules/checkout/composables/useCart/commands/removeCouponCommand';
import { removeItemCommand } from '~/modules/checkout/composables/useCart/commands/removeItemCommand';
import { updateItemQtyCommand } from '~/modules/checkout/composables/useCart/commands/updateItemQtyCommand';
import { Logger } from '~/helpers/logger';
import { Cart, CartItemInterface, ProductInterface } from '~/modules/GraphQL/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { Product } from '~/modules/catalog/product/types';
import { ComposableFunctionArgs } from '~/composables';
import { UseCartErrors, UseCartInterface } from './useCart';
import {mapCartItem} from "~/utils/mapCartItem";
import cartGetters from "~/modules/checkout/getters/cartGetters";
import {useQuantity} from "~/components/AddToCart/composables/quantity";
import {PageCategory} from "~/types/PageCategory";
import {ActionTypeScreenreader} from "~/types/Action";
import { CartIsInActive } from '~/types/CustomError';

const lastUpdatedCartSku = ref<string>('')

/**
 * Allows loading and manipulating cart of the current user.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function useCart<CART extends Cart, CART_ITEM extends CartItemInterface, PRODUCT extends ProductInterface>(): UseCartInterface<
CART,
CART_ITEM,
PRODUCT
> {
  const loading = ref<boolean>(false);
  const error = ref<UseCartErrors>({
    addItem: null,
    removeItem: null,
    updateItemQty: null,
    load: null,
    clear: null,
    applyCoupon: null,
    removeCoupon: null,
    loadTotalQty: null,
  });
  const { app, i18n } = useContext();
  const context = app.$vsf;
  const route = useRoute();
  const cartStore = useCartStore();
  const cart = computed(() => cartStore.cart as CART);
  const apiState = context.$magento.config.state;
  const { loading: wishlistLoading, afterAddingWishlistItemToCart } = useWishlist();
  const isAddToCardInProcess = ref(false);
  const addToCartScreenReaderMessage = ref('');

  /**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
  const setCart = (newCart: CART): void => {
    Logger.debug('useCart.setCart', newCart);

    cartStore.$patch((state) => {
      state.cart = newCart;
      updatePositions(newCart);
    });
  };

  /**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
  const isInCart = (product: PRODUCT): boolean => !!cart.value?.items?.find((cartItem) => cartItem?.product?.uid === product.uid);

  const load = async ({ customQuery = {}, customHeaders = {}, realCart = false } = { customQuery: { cart: 'cart' } }): Promise<void> => {
    Logger.debug('useCart.load');

    try {
      loading.value = true;
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders, realCart });

      cartStore.$patch((state) => {
        state.cart = loadedCart;
        updatePositions(loadedCart);
      });
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useCart/load', err);
    } finally {
      loading.value = false;
    }
  };

  const clear = async ({ customQuery, customHeaders } = { customQuery: { cart: 'cart' }, customHeaders: {} }): Promise<void> => {
    Logger.debug('useCart.clear');

    try {
      loading.value = true;
      context.$magento.config.state.removeCartId();
      const loadedCart = await loadCartCommand.execute(context, { customQuery, customHeaders });

      cartStore.$patch((state) => {
        state.cart = loadedCart;
        updatePositions(loadedCart);
      });
    } catch (err) {
      error.value.clear = err;
      Logger.error('useCart/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const loadTotalQty = async (params?: ComposableFunctionArgs<{}>): Promise<void> => {
    Logger.debug('useCart.loadTotalQty');

    try {
      loading.value = true;
      const data: { totalQuantity: number, totalPositions: number } = await loadTotalQtyCommand.execute(context, params);

      cartStore.$patch((state) => {
        state.cart.total_quantity = data.totalQuantity;
        state.cart.total_positions = data.totalPositions;
      });
    } catch (err) {
      error.value.loadTotalQty = err;
      Logger.error('useCart/loadTotalQty', err);
    } finally {
      loading.value = false;
    }
  };

  const addItem = async ({
    product, quantity, productConfiguration, customQuery, customHeaders,
  }): Promise<void> => {
    Logger.debug('useCart.addItem', { product, quantity });

    try {
      loading.value = true;

      if (!apiState.getCartId()) {
        await load({ realCart: true });
      }
      let updatedCart: Cart;

      try {
        updatedCart = await addItemCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          productConfiguration,
          customQuery,
          customHeaders,
        });
      } catch (error) {
        if (error instanceof CartIsInActive) {
          setCart(null);
          await load({ realCart: true });
          updatedCart = await addItemCommand.execute(context, {
            currentCart: cart.value,
            product,
            quantity,
            productConfiguration,
            customQuery,
            customHeaders,
          });
        } else {
          throw error
        }
      }

      error.value.addItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
        updatePositions(updatedCart);
      });
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useCart/addItem', err);
    } finally {
      if (!wishlistLoading.value && route.value.query?.wishlist) {
        afterAddingWishlistItemToCart({
          product,
          cartError: error.value.addItem,
        });
      }
      loading.value = false;
    }
  };

  const removeItem = async ({ product, customQuery, customHeaders }) => {
    Logger.debug('useCart.removeItem', { product });

    try {
      loading.value = true;
      const updatedCart = await removeItemCommand.execute(context, {
        currentCart: cart.value,
        product,
        customQuery,
        customHeaders,
      });

      error.value.removeItem = null;
      cartStore.$patch((state) => {
        state.cart = updatedCart;
        updatePositions(updatedCart);
      });
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useCart/removeItem', err);
    } finally {
      loading.value = false;
    }
  };

  const updateItemQty = async ({ product, quantity, customQuery = { updateCartItems: 'updateCartItems' } }): Promise<void> => {
    Logger.debug('useCart.updateItemQty', {
      product,
      quantity,
    });

    if (quantity && quantity > 0) {
      try {
        loading.value = true;
        const updatedCart = await updateItemQtyCommand.execute(context, {
          currentCart: cart.value,
          product,
          quantity,
          customQuery,
        });

        error.value.updateItemQty = null;
        cartStore.$patch((state) => {
          state.cart = updatedCart;
          updatePositions(updatedCart);
        });
      } catch (err) {
        error.value.updateItemQty = err;
        Logger.error('useCart/updateItemQty', err);
      } finally {
        loading.value = false;
      }
    }
  };

  const updatePositions = (cart) => {
    cartStore.$patch((state) => {
      if(cart?.items != null || cart?.items != undefined){
        state.cart.total_positions = cart.items.length
      }
    })
  };

  const handleCoupon = async (couponCode = null, customQuery = null): Promise<void> => {
    const variables = {
      currentCart: cart.value,
      customQuery,
      couponCode,
    };

    const { updatedCart, errors } = couponCode
      ? await applyCouponCommand.execute(context, variables)
      : await removeCouponCommand.execute(context, variables);

    if (errors) {
      throw errors[0];
    }

    if (updatedCart) {
      cartStore.$patch((state) => {
        state.cart = updatedCart;
      });
    }
  };

  const applyCoupon = async ({ couponCode, customQuery }): Promise<void> => {
    Logger.debug('useCart.applyCoupon');

    try {
      loading.value = true;
      await handleCoupon(couponCode, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.applyCoupon = err;
      Logger.error('useCart/applyCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const removeCoupon = async ({ customQuery }): Promise<void> => {
    Logger.debug('useCart.removeCoupon');

    try {
      loading.value = true;
      await handleCoupon(null, customQuery);
      error.value.applyCoupon = null;
    } catch (err) {
      error.value.removeCoupon = err;
      Logger.error('useCart/removeCoupon', err);
    } finally {
      loading.value = false;
    }
  };

  const canAddToCart = (product: Product, qty = 1) => {
    lastUpdatedCartSku.value = product?.sku

    const inStock = product?.stock_status || '';
    const stockLeft = product?.only_x_left_in_stock === null
      ? true
      : qty <= product?.only_x_left_in_stock;
    return inStock && stockLeft;
  };

  const cartItems = computed(() => cartGetters.getItems(cart.value));
  const isCartWithPrices = computed(() => cartGetters.getTotals(cart.value)?.total > 0);

  const cartListItems = computed(() => {
    return cartItems.value.map(item => ({
      ...mapCartItem(item, isCartWithPrices.value)
    }))
  })

  async function addToCart(product: Product) {
    lastUpdatedCartSku.value = product.sku
    const {quantity} = useQuantity(product.sku)

    addToCartScreenReaderMessage.value = ''
    const tempQuantity = quantity.value

    if (!canAddToCart(product, quantity.value)) {
      return
    }

    isAddToCardInProcess.value = true;
    // @ts-ignore
    await addItem({
      product,
      quantity: quantity.value,
    });

    await loadTotalQty()

    if(!error.value.addItem) {
      console.log(`added product ${product.name} to cart`)
      addToCartScreenReaderMessage.value = i18n.t(`${PageCategory.PRODUCT}.${ActionTypeScreenreader.ADD_TO_CART_SUCCESS}`, {name: product.name, quantity: tempQuantity}).toString()
    }

    isAddToCardInProcess.value = false;
  }

  async function deleteCart() {
    try {
      for (const item of cart.value.items) {
        // @ts-ignore
        await removeItem({product: item})
      }
    } catch (err){

    } finally {

      return await loadTotalQty({});
    }
  }

  return {
    setCart,
    cart,
    loadTotalQty,
    isInCart,
    addItem,
    load,
    removeItem,
    clear,
    updateItemQty,
    applyCoupon,
    removeCoupon,
    canAddToCart,
    cartListItems,
    cartItems,
    lastUpdatedCartSku,
    addToCart,
    deleteCart,
    isAddToCardInProcess,
    addToCartScreenReaderMessage,
    loading: readonly(loading),
    error: readonly(error),
    isCartWithPrices: readonly(isCartWithPrices),
  };
}

export default useCart;
export * from './useCart';
