import {computed, ref, useRoute, useRouter} from '@nuxtjs/composition-api'
import {ProductDetailType} from '~/types/ProductData'
import {mapProductToProductDetails} from '~/utils/mapProductToProductDetails'
import {ActionType} from "~/types/Action";
import {useCampaignList, useUiHelpers} from "~/composables";
import useFacet from "~/modules/catalog/category/composables/useFacet";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import productGetters, {getProductThumbnailImage} from "~/modules/catalog/product/getters/productGetters";

export function useCampaign() {
  const {getCampaignsList} = useCampaignList();
  const uiHelpers = useUiHelpers();
  const {search, result} = useFacet()

  const actions = [
    ActionType.DOWNLOAD,
    ActionType.ADD_TO_CART,
  ]

  const details: ProductDetailType[] = [
    ProductDetailType.ARTICLE_NUMBER,
    ProductDetailType.VERSION_DOWNLOAD,
  ]

  const rawCampaign = ref({
    name: '',
    image: '',
    short_description: '',
    description: '',
    products: {
      items: []
    }
  })

  const router = useRouter();
  const route = useRoute();
  const {path} = route.value;

  const fetchCampaign = async () => {
    const uid = path.replace(/(.+[\/])/gi, '');

    const currentCampaign = await getCurrentCampaign(uid)

    // if campaign is not visible for customer redirect to homepage
    if (!currentCampaign) {
      router.push('/')
    }

    await search({...uiHelpers.getFacetsFromURL(), category_uid: uid})

    return {
      name: rawCampaign.value?.name,
      image: rawCampaign.value?.image,
      short_description: rawCampaign.value?.short_description || '',
      text: rawCampaign.value?.description,
      products: result.value?.data?.items?.map(product => ({
        ...product,
        sku: product.sku,
        name: product.name,
        text: product.short_description?.html || '',
        imgSrc: getProductThumbnailImage(product),
        details: mapProductToProductDetails(product, details),
        actions,
        url: productGetters.getUrl(product),
      }))
    }
  }

  const getCurrentCampaign = async (uid: string) => {
    const campaign = await getCampaignsList();
    if (campaign === undefined || campaign === null || campaign.length === 0) {
      return null
    }

    let currentCampaign = campaign.find(item => item.uid === uid)
    if(currentCampaign === undefined){
      return null
    }

    rawCampaign.value = {
      name: currentCampaign.name,
      image: currentCampaign.image,
      short_description: currentCampaign.short_description,
      description: currentCampaign.description,
      products: {
        items: []
      }
    }

    return rawCampaign.value;
  }

  const pagination = computed(() => facetGetters.getPagination(result.value))

  return {
    fetchCampaign,
    pagination
  }
}
