import {ref, computed, Ref, useRouter, useContext} from '@nuxtjs/composition-api'
import {useValidate} from "~/composables/useValidate";
import axios from "axios";
import {useUser} from '~/modules/customer/composables/useUser';
import {identityClass, LoginState, SSOUser, SSOUserResponse} from '~/modules/login/types';
import {useCustomerData} from "~/modules/login/composables/useCustomerData";
import {useInternalUser} from "~/modules/customer/composables/useInternalUser";

const user: Ref<SSOUser> = ref()
const errorMessage: Ref<string> = ref()

function required(inValue) {
  return !!inValue
}

export function useLogin() {
  const {isAuthenticated, load: loadUser} = useUser()
  const {validate: validateInternalUser, updateRequiredDataUrl, redirect } = useInternalUser();
  const router = useRouter()
  const loading = ref(false)

  const {getCustomerData} = useCustomerData()

  const {
    value: selectValue,
    valid: selectValid,
    errorMessages: selectErrorMessages,
    reset: selectReset,
    validate: selectValidate
  } = useValidate<string>('', [required])
  const {
    value: inputValue,
    valid: inputValid,
    errorMessages: inputErrorMessages,
    reset: inputReset,
    validate: inputValidate
  } = useValidate<string>('', [required])

  const state = computed(() => {
    if (errorMessage.value) {
      return LoginState.ERROR
    }

    if (isAuthenticated.value) {
      return LoginState.LOGGED_IN
    }
    const {app} = useContext();
    const {$cookies} = app;

    if ($cookies.get('sso-customer')) {
      return LoginState.AFTER_SSO
    }

    return LoginState.BEFORE_SSO
  })

  function resetLoginForm() {
    selectReset()
    inputReset()
  }

  const getDebitorIds = (data: SSOUserResponse): Array<string> => {
    let debitorIds = [];

    if (data?.authorized_agents !== undefined && Array.isArray(data?.authorized_agents) && data?.authorized_agents.length > 0) {
      debitorIds = data?.authorized_agents;
    } else if (data?.agency_number !== undefined) {
      debitorIds = [data?.agency_number];
    }

    return debitorIds
  }

  const isInternal = (data: SSOUserResponse): boolean => {
    return data?.identity_class === identityClass.INTERN
  }

  async function fetchDebitorIds() {
    try {
      loading.value = true
      const data = await getCustomerData();

      user.value = {
        isInternal: isInternal(data),
        debitorIds: getDebitorIds(data),
        bid: data?.bid,
        isLoginWithCustomDebitorIdVisible: data?.frontend.isLoginWithCustomDebitorIdVisible,
        isLoginWithBenslIdVisible: data?.frontend.isLoginWithBenslIdVisible,
        isLoginWithDebitorIdVisible: data?.frontend.isLoginWithDebitorIdVisible,
        isLoginWithDebitorSelectionVisible: data?.frontend.isLoginWithDebitorSelectionVisible,
        isAutoLogin: data?.frontend.isAutoLogin,
        allowedWildcards: data?.frontend.allowedWildcards
      }

      await autoLogin();
      loading.value = false

    } catch (e) {
      errorMessage.value = e.response?.data?.message || ''
    }
  }

  async function loginWithBenslId(bid: string) {
    try {
      loading.value = true
      await login({
        bid: bid
      })

      resetLoginForm()

      await afterLoginProcess()
      loading.value = false
    } catch (e) {
      errorMessage.value = e.response.data.message
    }
  }

  async function loginWithDebitorId(debitorId: string) {
    try {
      loading.value = true
      await login({
        authorized_agent_id: debitorId
      })
      resetLoginForm()

      await afterLoginProcess()
      loading.value = false
    } catch (e) {
      errorMessage.value = e.response.data.message
    }
  }

  async function login(data: any) {
    if (!user.value.isInternal && !selectValid && !inputValid) {
      errorMessage.value = 'fields invalid'
      throw new Error(errorMessage.value);
    }
    await axios.post('/single-sign-on-callback/customer/login', data)
  }

  async function autoLogin() {
    if (user.value?.debitorIds.length > 0 || user.value?.isAutoLogin === false) {
      return;
    }

    try {
      await login({
        bid: user.value.bid
      })

      resetLoginForm()
      await afterLoginProcess()
      loading.value = false
    } catch (e) {
      errorMessage.value = e.response.data.message
    }
  }

  async function afterLoginProcess(){
    await loadUser();
    const isInternalUserValid = validateInternalUser();

    if(!isInternalUserValid){
      return await redirect(updateRequiredDataUrl);
    }

    return await router.push('/')
  }

  return {
    selectValue,
    selectErrorMessages,
    selectValid,
    selectValidate,
    inputValue,
    inputErrorMessages,
    inputValid,
    inputValidate,
    state,
    fetchDebitorIds,
    loginWithBenslId,
    loginWithDebitorId,
    user,
    errorMessage,
    loading,
  }
}
