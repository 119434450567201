import { Logger } from '~/helpers/logger';
import {Cart, UpdateCartItemsInput, UpdateCartItemsMutation} from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import { updateCartItems } from "~/modules/checkout/composables/useCart/customQueries/updateCartItems";

export const updateItemQtyCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    product,
    quantity,
    customQuery = { updateCartItems: 'updateCartItems' },
    customHeaders = {},
  }) => {
    Logger.debug('[Magento]: Update product quantity on cart', {
      product,
      quantity,
      currentCart,
    });

    const updateCartItemsMutation = updateCartItems;

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: [
        {
          cart_item_uid: product.uid,
          quantity,
        },
      ],
    };

    const { data, errors } = await context.$magento.api.customMutation({ mutation: updateCartItemsMutation, mutationVariables: {input: updateCartParams}});

    if(!!errors?.length) {
      throw new Error(errors?.[0].message)
    }

    if ((data as UpdateCartItemsMutation).updateCartItems === null) {
      throw new Error('Der Warenkorb konnte nicht aktuallisiert werden');
    }

    Logger.debug('[Result]:', { data });

    return (data as UpdateCartItemsMutation)
      .updateCartItems
      .cart as unknown as Cart;
  },
};
