import axios, { AxiosError, AxiosResponse } from 'axios'
import { useLogger } from '~/composables/logger'
import {useShopApi} from "~/composables";

export function usePreview () {
  const { log } = useLogger()
  const { shopApi } = useShopApi()

  const getPreviewUrl = (sku: string) : Promise<string> => {

    return new Promise<string>((resolve, reject) => {
      const previewUrl = `/index.php/digitalcontent/product/preview?sku=${sku}`
      shopApi.get(previewUrl, { responseType: 'blob' }).then((fileResponse: AxiosResponse<Blob>) => {
        log(`Previewing digital content for ${sku}: ${previewUrl}`)
        const mimeType = 'application/pdf'
        const blob = new Blob([fileResponse.data], { type: mimeType })
        resolve(URL.createObjectURL(blob))
      }).catch((error: AxiosError<Blob>) => {
        log(`Previewing of ${sku} failed: ${error.message}`)
        return ''
        //reject(error)
      })
    })
  }

  return {
    getPreviewUrl,
  }
}
