import {useApi} from "~/composables";

export const dumpSavedCartToCart = () => {
  const {
    mutate,
  } = useApi();

  const shoppingListToCart = `
  mutation shoppingListToCart ($listId: String)
  {
    shoppingListToCart(
      input: {
         list_id: $listId
      }
    ){
        success_message
        error_message
    }
  }`;

  const mutation = async(variables) => {
    return await mutate(
      shoppingListToCart,
      variables
    );
  }

  return {
    mutation
  };
};
