import {Context, NuxtAppOptions} from '@nuxt/types';
import {LocaleObject} from 'nuxt-i18n';
import jwtDecode from "jwt-decode";

const findLocaleBasedOnMagentoStoreCode = (storeCode: string, locales: Array<string | LocaleObject>) => locales.find((locale) => ((typeof locale === 'object' ? locale.code : locale) === storeCode));

const findCurrencyBasedOnMagentoStoreCode = (storeCode: string, locales: Array<string | LocaleObject>): string => {
  const match = locales.find((locale) => typeof locale === 'object' && locale.code === storeCode) as LocaleObject | undefined;
  return match?.defaultCurrency;
};

/**
 * Prepare new cookie string based on app state.
 *
 * @param app {NuxtAppOptions}
 * @param newStoreCode {string}
 * @param currency {string}
 * @returns {string}
 */
const prepareNewCookieString = (app: NuxtAppOptions, newStoreCode: string, currency: string) => {
  const apiState = app.$vsf.$magento.config.state;
  const customerTokenCookie = apiState.getCustomerToken();
  const cartIdCookie = apiState.getCartId();

  const expirationDate = getTokenExpirationDate(customerTokenCookie);

  app.$cookies.set("vsf-store", newStoreCode, {secure: true, sameSite: 'strict', path: '/', expires: expirationDate});
  app.$cookies.set("vsf-locale", newStoreCode, {secure: true, sameSite: 'strict', path: '/', expires: expirationDate});
  app.$cookies.set("vsf-currency", currency, {secure: true, sameSite: 'strict', path: '/', expires: expirationDate});
  app.$cookies.set("vsf-country", apiState.getCountry(), {secure: true, sameSite: 'strict', path: '/', expires: expirationDate});

  if (customerTokenCookie) {
    app.$cookies.remove("vsf-customer");
    app.$cookies.set("vsf-customer", customerTokenCookie, {secure: true, sameSite: 'strict', path: '/', expires: expirationDate});
  }

  if (cartIdCookie) {
    app.$cookies.set("vsf-cart", cartIdCookie, {secure: true, sameSite: 'strict', path: '/', expires: expirationDate});
  }
};

export const getTokenExpirationDate = (token: string): Date => {
  let date = new Date();

  try {
    const decodedToken = jwtDecode<any>(token);
    date.setTime(decodedToken.exp * 1000);

  } catch (e) {
    date.setTime(date.getTime() + (((60 * 60) - 10) * 1000)); // 1 hour
  }
  return date;

}

export default ({app, route}: Context) => app.$vsf.$magento.client.interceptors.request.use(async (request) => {
  const {
    $vsf: {$magento: {config: {state}}},
    i18n,
  } = app;

  const currentStoreCode = app.$vsf.$magento.config.state.getStore() ?? route.path.split('/')[1]; // localhost:3000/default
  const shouldSetDefaultLocale = !currentStoreCode || !findLocaleBasedOnMagentoStoreCode(currentStoreCode, i18n.locales);

  if (shouldSetDefaultLocale) {
    await i18n.setLocale(i18n.defaultLocale);
  }

  const i18nCurrentLocaleCode = i18n.locale;
  const shouldLocaleBeRefreshed = i18nCurrentLocaleCode !== state.getLocale();

  if (shouldLocaleBeRefreshed) {
    const currency = findCurrencyBasedOnMagentoStoreCode(i18nCurrentLocaleCode, i18n.locales);

    state.setStore(i18nCurrentLocaleCode);
    state.setLocale(i18nCurrentLocaleCode);
    state.setCurrency(currency);

    // eslint-disable-next-line no-param-reassign
    // request.headers.cookie = prepareNewCookieString(app, i18nCurrentLocaleCode, currency);
    prepareNewCookieString(app, i18nCurrentLocaleCode, currency);
  }

  return request;
});
