














































import { defineComponent } from '@vue/composition-api'
import { SfButton, SfIcon } from '@storefront-ui/vue'
import { PropType } from '@nuxtjs/composition-api'
import { Image } from '~/types/Image'
import SfModal from "~/components/Modal/SfModal.vue";

export default defineComponent({
  name: 'DsbModal',
  components: { SfModal, SfButton, SfIcon },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    bgImageClass: {
      type: String,
      default: 'question-bg-image',
    },
    title: {
      type: String,
      default: '',
    },
    subline: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    cancelButtonText: {
      type: String,
      default: 'Schließen',
    },
    cancelButtonTextScreenreader: {
      type: String,
      default: 'Schließen',
    },
  },
  methods: {
    close () {
      this.$emit('close', false)
    },
  },
})
