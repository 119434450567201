import { customRef, ComputedRef } from "@nuxtjs/composition-api";

export function customComputedRef(value: ComputedRef) {
  return customRef<any>((track,trigger) => ({
    set(val: ComputedRef) {
      value = val
      trigger()
    },
    get() {
      track()
      return value.value
    }
  }))
}
