import { ListHeader, ListFilter, ListFooter } from '../../types/ListCategories'

export default {
  [ListHeader.PRODUCTS_FOUND]: '{products} Produkte gefunden',
  [ListHeader.ONE_PRODUCT_FOUND]: '1 Produkt gefunden',
  [ListHeader.ONE_PRODUCT_FOUND_SR]: 'ein Produkt gefunden',
  [ListHeader.PRODUCTS_QTY]: '{products} Produkte',
  [ListHeader.ONE_PRODUCT_QTY]: '1 Produkt',
  [ListHeader.ONE_PRODUCT_QTY_SR]: 'ein Produkt',
  [ListHeader.SORT_BY]: 'Sortieren nach',
  [ListHeader.CATEGORY]: 'Kategorie',
  [ListHeader.DISPLAY_AS]: 'Darstellen als',
  [ListHeader.GALLERY]: 'Galerie',
  [ListHeader.LIST]: 'Liste',

  [ListFilter.HEADER_ALL]: 'Filtern',
  [ListFilter.SHOW_ALL]: 'Alle Produkte anzeigen',
  [ListFilter.DOWNLOADS]: 'Downloads',
  [ListFilter.IN_STOCK]: 'Auf Lager',
  [ListFilter.PERSONALISABLE]: 'Personalisierbar',

  [ListFooter.PREVIOUS_PAGE]: 'VORHERIGE SEITE',
  [ListFooter.NEXT_PAGE]: 'NÄCHSTE SEITE',
  [ListFooter.SHOW]: 'Zeige',
  [ListFooter.RESULTS_PER_PAGE]: 'Ergebnisse pro Seite',
}
