export function getFormattedDebitorId(debitorId: string){
  if (debitorId) {
    if (debitorId.length < 8) {
      return debitorId
    }

    let debitorFormatTest = new RegExp(/[\w\d]+\_[\d]+/);
    if (debitorFormatTest.test(debitorId) === true) {
      let debitorSplit = debitorId.split('_');
      debitorId = debitorSplit[1];
    }

    let firstPart = debitorId.substring(0,2)
    let secondPart = debitorId.substring(2,5)
    let thirdPart = debitorId.substring(5,9)

    return firstPart + '/' + secondPart + '/' + thirdPart
  }
  return ''
}
