export enum LoginState {
  BEFORE_SSO,
  AFTER_SSO,
  LOGGED_IN,
  ERROR,
}

export enum LoginGeneral {
  TITLE = 'general_title',
  INTRO_TEXT = 'general_intro_text'
}

export enum LoginLink {
  SSO= 'link_sso',
  CONTINUE = 'link_continue',
  CONTINUE_WITH_NUMBER = 'link_continue_number',
  CONTINUE_WITH_NUMBER_SCREENREADER = 'link_continue_number_screenreader',
  CONTINUE_AS_INTERN = 'link_continue_as_intern',
  APPLY_FOR_MEMBERSHIP = 'link_apply_for_membership',
  WRITE_EMAIL = 'link_write_email',
  MAILTO = 'link_mailto',
}

export enum LoginEnterId {
  INTERN_INTRO='intern_info',
  KNOWN_ID = 'info_known_id',
  SELECT_INFO_ID = 'select_info_id',
  SELECT_LABEL_ID = 'select_label_id',
  SELECT_PLACEHOLDER_ID = 'select_placeholder_id',
  INPUT_INFO_ID = 'input_info_id',
  INPUT_LABEL_ID = 'input_label_id',
  INPUT_PLACEHOLDER_ID = 'input_placeholderl_id',
}

export enum LoginError {
  SOMETHING_HAPPENED='error_something_happened',
  MAYBE_THIS_HELPS='error_maybe_this_helps',
  CUSTOMER_NOT_FOUND='customer-not-found',
  GENERAL_ERROR='general',
  INVALID_TOKEN='invalid-token',
  REQUEST_ERROR='request-error',
}


export interface SSOUser {
  isInternal?: boolean,
  debitorIds?: string[],
  bid?: string,
  isLoginWithCustomDebitorIdVisible?: boolean;
  isLoginWithBenslIdVisible?: boolean;
  isLoginWithDebitorIdVisible?: boolean;
  isLoginWithDebitorSelectionVisible?: boolean;
  isAutoLogin?: boolean;
  allowedWildcards?: string[];
}


export enum identityClass {
  INTERN = 'INTERN',
  EXTERN = 'EXTERN'
}


export interface SSOUserResponse {
  accounting_area_number?: string;
  sap_structure_shortname?: string;
  sub?: string;
  identity_class?: string;
  email_verified?: boolean;
  sap_structure_unit?: string;
  sales_organisation_number?: string;
  structure_id?: string;
  structure_fullpath_name?: string;
  preferred_username?: string;
  given_name?: string;
  structure_fullpath_id?: string;
  accounting_area?: string;
  agency_number?: string;
  name?: string;
  clerk_number?: string;
  bid?: string;
  family_name?: string;
  email?: string;
  job_classification_id?: string;
  authorized_agents?: string[];
  frontend?: FrontendData;
}

export interface FrontendData {
  isLoginWithCustomDebitorIdVisible?: boolean;
  isLoginWithBenslIdVisible?: boolean;
  isLoginWithDebitorIdVisible?: boolean;
  isLoginWithDebitorSelectionVisible?: boolean;
  isAutoLogin?: boolean;
  allowedWildcards?: string[];
}
