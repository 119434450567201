import { AutocompleteResultType } from '~/types/search/AutocompleteResult'
import {useUiHelpers, useFacet, useShopApi} from "~/composables";
import {
  computed,
  useRouter,
  customRef, ref
} from '@nuxtjs/composition-api';
import debounce from 'lodash.debounce';
import {mapProductSuggestionItem} from "~/utils/mapProductSuggestionItem";
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import { Logger } from '~/helpers/logger';
import {AxiosError, AxiosResponse} from "axios";
import {useSearchStore} from "~/modules/catalog/search/store/searchStore";
import { SearchBarId } from "~/modules/catalog/search/enum";

const _searchString = {
  [SearchBarId.HOMEPAGE]: '',
  [SearchBarId.SEARCH_PAGE]: '',
  [SearchBarId.HEADER]: '',
}
const _isSearchOpen = {}
const isSearchExpanded = ref(false)


const searchHistory = customRef<string[]>((track,trigger) => ({
  set(val: string[]) {
    if(!process.browser) return

    window.localStorage.setItem('searchHistory', JSON.stringify(val))
    trigger()
  },
  get() {
    track()
    if(!process.browser) return []

    const searchHistoryRaw = window.localStorage.getItem('searchHistory');
    if(!searchHistoryRaw) {
      return []
    }
    return JSON.parse(searchHistoryRaw)
  }
}))

function last (array: any[], elements: number) {
  return array.slice(Math.max(array.length - elements, 0)).reverse()
}

export function useSearch (id = SearchBarId.HEADER) {
  const rawSuggestions = ref()
  const rawAutocompleteResults = ref([])
  const loading = ref(false)
  const minLength = ref(3)

  const router = useRouter();
  const { getFacetsFromURL } = useUiHelpers();
  const isSearchPage = true;
  const {
    result,
    search: fetchProducts,
  } = useFacet(isSearchPage);
  const { shopApi } = useShopApi()
  const searchStore = useSearchStore()

  const getSearchStoreById = (id) => {
    return searchStore.search[id]
  }

  const isSearchOpen = customRef<boolean>((track, trigger) => ({
    set(val) {
      _isSearchOpen[id] = val
      trigger()
    },
    get() {
      track()
      return _isSearchOpen[id]
    }
  }))

  const suggest = debounce((searchTerm, trigger) => {
    if(searchTerm === undefined){
      return;
    }
    if(!isSearchOpen.value){
      return;
    }
    if(searchTerm.length <= minLength.value){
      return
    }

    loading.value = true

    autocomplete(searchTerm)
      .then((result) => {
        rawAutocompleteResults.value = result
      })

    fetchProducts({
      term: searchTerm,
      itemsPerPage: 5,
      page: 1
    }).then(() => {
      rawSuggestions.value = filterSuggestionsForSkuExactMatch(result.value)

      searchStore.search[id] = {
        term: rawSuggestions.value.input.term,
        suggestions: rawSuggestions.value,
        autocompleteResults: rawAutocompleteResults.value
      }

      trigger()
      loading.value = false
    });
  }, 1000)



  const autocomplete = (searchTerm: string): Promise<any> => {
    return new Promise<any>((resolve, reject) => {
      Logger.debug(`autocomplete for '${searchTerm}'`)
      const autocompleteUrl = `/search/ajax/suggest?q=${searchTerm}`
      shopApi.get(autocompleteUrl).then((successResponse: AxiosResponse<any>) => {
        resolve(successResponse.data)
      }).catch((error: AxiosError<any>) => {
        Logger.error(`Failed to get autocomplete results for search term '${searchTerm}': ${error.message}`)
        reject(error)
      })
    })
  }

  const tempSearchInput = customRef<string>((track,trigger) => ({
    set(val) {
      if(val === ''){
        clear()
      }

      searchString.value = val
      trigger()
    },
    get() {
      track()
      return searchString.value
    }
  }))

  const searchString = customRef<string>((track,trigger) => ({
    set(val) {
      _searchString[id] = val

      if(!val) {
        trigger()
        return
      }

      suggest(val, trigger)
    },
    get() {
      track()
      return _searchString[id]
    }
  }))

  const facets = getFacetsFromURL()
  if(facets.term && !searchString.value && id === SearchBarId.SEARCH_PAGE) {
    searchString.value = facets.term
  }

  const suggestions = computed(() => {
    const search = getSearchStoreById(id)
    if(search?.suggestions === undefined){
      return []
    }
    const list = facetGetters
      .getProducts(search?.suggestions)
      .map(product => ({
        ...mapProductSuggestionItem(product),
      }))

    return list
  })

  const filterSuggestionsForSkuExactMatch = (result) => {
    const term = result?.input?.term.trim();

    if(term === '' || term === undefined || term === null){
      return result
    }

    if(term.split(' ').length > 1){
      return result
    }
    if(term.length < 4){
      return result
    }

    const items = result?.data.items.filter((item) => {
      return (item.sku.trim().toLowerCase() === term.toLowerCase())
    })

    if(items.length === 0){
      return result
    }

    result.data.items = items
    return result
  }

  const showAddToCart = computed(() =>{
    return suggestions.value.length === 1
  })

  const autocompleteResults = computed(() => {
    const search = getSearchStoreById(id)
    if(search?.autocompleteResults === undefined){
      return []
    }

    return search?.autocompleteResults
      .map(result => ({
          type: AutocompleteResultType.COLLECTION,
          name: result.title,
        })
      )
  })

  const commonSearchTerms = computed(() => [ //TODO: replace with results from backend
    'Vorschlag', 'Vorschlag', 'Vorschlag'
  ])

  function clear() {
    searchString.value = ''
    rawSuggestions.value = []
    rawAutocompleteResults.value = []

    searchStore.search[id] = {}
  }

  function search () {
    if(id === SearchBarId.HEADER) {
      isSearchExpanded.value = false
    }

    isSearchOpen.value = false
    searchHistory.value.push(searchString.value)

    const currentSearchTerm = searchString.value

    for(const key of Object.keys(_searchString)) {
      if (key === SearchBarId.SEARCH_PAGE) {
        _searchString[key] = currentSearchTerm
      } else {
        _searchString[key] = ''
      }

      searchStore.search[key] = {}
    }

    router.push({
      path: '/search',
      query: {
        term: currentSearchTerm
      }
    })
  }

  const closeSearchResults = () => {
    isSearchOpen.value = false
    isSearchExpanded.value = false
  }

  return {
    isSearchOpen,
    isSearchExpanded,
    searchString,
    minLength,
    searchHistory,
    autocompleteResults,
    suggestions,
    clear,
    search,
    last,
    commonSearchTerms,
    showAddToCart,
    tempSearchInput,
    loading,
    closeSearchResults,
    suggest
  }
}
