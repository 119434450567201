import {Product} from "~/modules/catalog/product/types";
import {MagentoProduct} from "~/types/Product";

export const getProductSku = (product: Product): string => product.sku;

export function getProductSkuText(isSingularVersion: string, sku: string): string {
  return isSingularVersion === '1' ? sku + 'V' : sku
}

export function getProductSkuFormatted(product: MagentoProduct, sku: string){
  if (product?.active_versions?.physical?.is_singular_version === '1' || product?.active_versions?.digital?.is_singular_version === '1') {
    if ((sku === product?.active_versions?.physical?.sku) || (sku === product?.active_versions?.digital?.sku)){
      return sku + 'V'
    }
  }
  return sku
}
