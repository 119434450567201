


































import { PropType, defineComponent, computed } from "@nuxtjs/composition-api";
import { Action, ActionTypeScreenreader } from "~/types/Action";
import { PageCategory } from "~/types/PageCategory";
import AddToCart from "~/components/AddToCart/component.vue";
import { ProductActions } from "~/types/ProductActions";

export default defineComponent({
  components: { AddToCart },
  props: {
    sku: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    action: {
      required: true,
      type: Object as PropType<Action>,
    },
    value: {
      required: true,
      type: Boolean,
    },
    active: {
      required: false,
      default: true,
      type: Boolean,
    },
  },

  setup(props) {
    const cleanedName = computed(() =>
      props.name.replace(/<\/?span[^>]*>/g, "")
    );
    return {
      cleanedName,
      PageCategory,
      ProductActions,
      ActionTypeScreenreader,
    };
  },
});
