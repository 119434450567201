































import { defineComponent } from '@vue/composition-api'
import { PageCategory } from '~/types/PageCategory'
import {useLogin} from "~/modules/login/composables/useLogin";
import { LoginError, LoginLink } from '~/modules/login/types';

export default defineComponent({
  name: 'DsbLoginError',
  props: {
    messageKey: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup () {

    return {
      ...useLogin(),
      PageCategory,
      LoginLink,
      LoginError,
    }
  },
})
