import {useApi} from "~/composables";
import {ref} from '@nuxtjs/composition-api';
import {Logger} from '@vue-storefront/core';

export const useHeaderMenu = () => {
  const {
    query
  } = useApi();

  const GET_CATEGORY_LIST_QUERY = `
  query {
  categoryList(
    filters: {
      parent_id: {in: ["2"]}
    }
  ) {
      uid
      level
      name
      path
      url_path
      url_key
      url_suffix
      include_in_menu
  }
}
`;

  const isMenuOpen = ref(false)
  const licences = ref(90)

  const getCategoryMenu = async (id: number) => {
    Logger.debug(`useCategoryMenu`, id);

    const result = await query(
      GET_CATEGORY_LIST_QUERY,
      {id},
      "cache-first"
    );

    // @ts-ignore
    return result.data?.categoryList.filter(category => category.include_in_menu === 1);
  };

  return {
    getCategoryMenu,
    isMenuOpen,
    licences
  };
};
