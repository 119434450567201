import { LicencesFilter, LicencesGeneral, LicenceListItem } from '../../types/licences/licenceCategories'
import { General } from "../../types/Page";
import {SearchListItem} from "~/types/search/SearchCategories";

export default {
  [General.TITLE]: 'Artikel mit Rechtshinweisen zur Beachtung der Urheber-/Bildrechte',

  [LicencesGeneral.READ_TERMS]: 'Rechtshinweise lesen',

  [LicencesFilter.SHOW]: 'Anzeigen',
  [LicencesFilter.SORT_BY]: 'Sortierung',

  [LicenceListItem.ACCEPTED_DATE]: 'Bestätigt am:',
  [LicenceListItem.EXPIRATION_DATE]: 'Nutzbar bis:',
  [LicenceListItem.WEEKS_LEFT]: '(Noch {weeksLeft} Wochen)',
  [LicenceListItem.EXPIRED]: 'Abgelaufen',

  [LicenceListItem.DETAILS]: 'Details',
  [LicenceListItem.SCREENREADER_DETAILS]: '{name} Produktseite',
}
