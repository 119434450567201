import {defaultPageSize} from "~/modules/catalog/category/composables/useFacet/perPageOptions";

export const wishlistQuery = `
  query wishlist($currentPage: Int = 1, $pageSize: Int = ${defaultPageSize}) {
    customer {
      wishlists {
        id
        items_count
        sharing_code
        items_v2(currentPage: $currentPage, pageSize: $pageSize) {
          items {
            id
            quantity
            description
            added_at
            product {
              ...on ConfigurableProduct {
                configurable_options {
                  attribute_code
                  attribute_uid
                  label
                  position
                  uid
                  use_default
                  values {
                    label
                    swatch_data {
                      value
                    }
                    uid
                  }
                }
                variants{
                  product {
                    sku
                    delivery_type
                    stock_status
                    only_x_left_in_stock
                    image {
                      url
                      disabled
                      label
                      __typename
                    }
                    thumbnail {
                      url
                    }
                  }
                }
              }
              uid
              __typename
              sku
              name
              stock_status
              delivery_type
              is_active
              active_versions {
                digital {
                  version_name
                  sku
                  is_singular_version
                }
                physical {
                  version_name
                  sku
                  is_singular_version
                }
              }
              permissions {
                job_level
                job_qualifications
                sales_channel
                sales_region
                user_groups
                user_type
              }
              only_x_left_in_stock
              categories {
                uid
                name
                url_suffix
                url_path
                breadcrumbs {
                  category_name,
                  category_url_path
                }
              }
              license_expiration_date
              license_beginning_date
              price_range {
                maximum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
                minimum_price {
                  final_price {
                    currency
                    value
                  }
                  regular_price {
                    currency
                    value
                  }
                }
              }
              thumbnail {
                url
                position
                disabled
                label
              }
              url_key
              url_rewrites {
                url
              }
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
        }
      }
    }
  }
`;
