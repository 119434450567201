import {customRef, computed, ref, ssrRef} from '@nuxtjs/composition-api'
import { AgnosticGroupedFacet } from "@vue-storefront/core";
import {customComputedRef} from "~/helpers/customComputedRef";
import {Category} from "@vue-storefront/magento-api";
import {useProductListView} from "~/composables/useProductListView";
import {ProductInterface} from "~/modules/GraphQL/types";
import type { Pagination } from '~/composables/types';
import type { CategoryTree } from '~/modules/GraphQL/types';
import {SortingModel} from "~/modules/catalog/category/composables/useFacet/sortingOptions";

const products = ssrRef<ProductInterface[]>([]);
const categoryList = ssrRef<Object>({})
const pagination = ref<Pagination>({});
const sortBy = ref<SortingModel>({ selected: '', options: [] });
const filters = customComputedRef(computed(() => undefined))
const facets = customComputedRef(computed<AgnosticGroupedFacet[]>(() => undefined))
const sidebar = ref<CategoryTree[]>([]);
const activeCategory = customComputedRef(computed<Category>(() => undefined))
const productsLoading = customComputedRef(computed<boolean>(() => true))
const sidebarLoading = customComputedRef(computed<boolean>(() => true))
const sidebarAction = ref<(Category) => void>((item) => {})
const showAllAction = ref<() => void>(() => {})

const {
  view,
  viewSelectionDisabled,
  initViewSelection
} = useProductListView();

const downloadsFilter = ref(false)
const inStockFilter = ref(false)
const personalisableFilter = ref(false)

const itemsOnPage = customRef(() => ({
  set(size: number) {
    pagination.value.itemsPerPage = size
    if (pagination.value.currentPage > pagination.value.totalPages) {
      pagination.value.currentPage = 1
    }
  },
  get() {
    return pagination.value.itemsPerPage
  },
}))


const paginatedProducts = computed(() => products.value.slice(
  (pagination.value.currentPage - 1) * itemsOnPage.value, pagination.value.currentPage * itemsOnPage.value
))

const paginationSelection = computed<string>(() => {
  if(pagination.value?.itemsPerPage === undefined){
    return ''
  }
  return pagination.value?.itemsPerPage.toString()
});

export function useProductList() {
  return {
    productsLoading,
    sidebarLoading,
    products,
    categoryList,
    pagination,
    sortBy,
    filters,
    facets,
    sidebar,
    sidebarAction,
    showAllAction,
    activeCategory,
    paginatedProducts,
    itemsOnPage,
    view,
    viewSelectionDisabled,
    downloadsFilter,
    inStockFilter,
    personalisableFilter,
    initViewSelection,
    paginationSelection
  }
}
