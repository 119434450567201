import {useApi} from "~/composables";
import {ApolloQueryResult} from "@apollo/client/core";

export const getPdfCollection = (): GetPdfCollection => {
  const {
    query
  } = useApi();

  const pdfCollection = `
  query pdfCollection($pageSize: Int = 20, $currentPage: Int = 1) {
    pdfCollection(
        pageSize: $pageSize
        currentPage: $currentPage
    ) {
        message
        total_count
        page_info {
          current_page
          page_size
          total_pages
        }
        items {
            shoppinglist_item_id
            product {
              sku
              name
              only_x_left_in_stock
              delivery_type
              is_active
              active_versions {
                digital {
                  version_name
                  sku
                  is_singular_version
                }
                physical {
                  version_name
                  sku
                  is_singular_version
                }
              }
              short_description {
                html
              }
              thumbnail {
                url
                position
                disabled
                label
              }
              url_key
              url_rewrites {
                url
              }
              ... on ConfigurableProduct {
                variants {
                  product {
                    sku
                    delivery_type
                    thumbnail {
                      url
                      position
                      disabled
                      label
                    }
                  }
                }
              }
            }
        }
     }
  }`;

  const loadPdfCollection = async (variables): Promise<PdfCollection | null> => {
    const {data} = await query<ApolloQueryResult<PdfCollection>>(pdfCollection, variables);
    // @ts-ignore
    return data || null;
  }

  return {
    loadPdfCollection
  };
}

interface GetPdfCollection {
  loadPdfCollection(variables): Promise<PdfCollection | null>
}

declare type PdfCollection = {
  pdfCollection: {
    message: string
    items: Array<{
      product: any
      product_thumbnail: string
    }>
    total_count?: number | null | undefined;
    page_info?: {
      current_page?: number | null | undefined;
      page_size?: number | null | undefined;
      total_pages?: number | null | undefined;
    } | null | undefined;
  }
}
