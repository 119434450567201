import { CustomerCartQuery } from "~/composables";
import { Logger } from "~/helpers/logger";
import { ApolloQueryResult } from "@apollo/client/core/types";
import { VsfContext } from "~/composables/context";

export const customerCartCommand = {
  execute: async (context: VsfContext): Promise<ApolloQueryResult<CustomerCartQuery>> => {
    Logger.debug("[Magento]: Load cart");

    const cartQuery = `
 query customerCart {
    customerCart {
      id
      email
      is_virtual
      applied_coupons {
        code
      }
      prices {
        subtotal_excluding_tax {
          value
        },
        subtotal_including_tax {
          value
        },
        applied_taxes {
          amount {
            value
          },
          label
        }
        discounts {
          amount {
            value
          },
          label
        }
        grand_total {
          value
        }
      }
      items {
        uid
        product {
          uid
          __typename
          sku
          name
          stock_status
          only_x_left_in_stock
          stock_availability_from
          salable_qty
          rating_summary
          parent_sku
          parent_url_key
          is_singular_version
          short_description {
            html
          }
          description {
            html
          }
          thumbnail {
            url
            position
            disabled
            label
          }
          url_key
          url_rewrites {
            url
          }
          price_range {
            maximum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
            minimum_price {
              final_price {
                currency
                value
              }
              regular_price {
                currency
                value
              }
            }
          }
          categories {
            uid
            name
            url_suffix
            url_path
            breadcrumbs {
              category_name,
              category_url_path
            }
          }
          review_count
          reviews {
            items {
              average_rating
              ratings_breakdown {
                name
                value
              }
            }
          }
        }
        prices {
          row_total {
            value
          }
          row_total_including_tax {
            value
          }
          total_item_discount {
            value
          }
        }
        quantity
        ... on SimpleCartItem {
            customizable_options {
                id
                customizable_option_uid
                label
                type
                is_required
                sort_order
                values {
                    customizable_option_value_uid
                    label
                    value
                }
            }
        }
        ... on ConfigurableCartItem {
          configurable_options {
            configurable_product_option_uid
            option_label
            configurable_product_option_value_uid
            value_label
          }
          configured_variant {
            sku
            thumbnail {
              url
            }
          }
        }
        ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
      }
      total_quantity
      shipping_addresses {
        firstname
        lastname
        street
        city
        company
        address_line_2
        address_line_3
        sap_name
        postcode
        telephone
        country {
          code
          label
        }
        selected_shipping_method {
          carrier_code
          carrier_title
          method_code
          method_title
          amount {
            value
            currency
          }
        }
      }
      billing_address {
        firstname
        lastname
        street
        city
        company
        address_line_2
        address_line_3
        sap_name
        postcode
        telephone
        country {
          code
          label
        }
      }
    }
  }`;

    const { data, errors } = await context.$magento.api.customQuery({ query: cartQuery });

    // @ts-ignore
    return { data, errors };
  },
};
