import { render, staticRenderFns } from "./DownloadAction.vue?vue&type=template&id=f1f78110&scoped=true&"
import script from "./DownloadAction.vue?vue&type=script&lang=ts&"
export * from "./DownloadAction.vue?vue&type=script&lang=ts&"
import style0 from "./DownloadAction.vue?vue&type=style&index=0&id=f1f78110&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1f78110",
  null
  
)

export default component.exports