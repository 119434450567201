import {useApi} from "~/composables";

export const deleteSavedCartById = () => {
  const {
    mutate
  } = useApi();

  const deleteShoppingList = `
  mutation deleteShoppingList ($listId: String)
  {
    deleteShoppingList(
      input: {
         list_id: $listId
      }
    ){
        success_message
    }
  }`;

  const deleteSavedCartMutation = async(variables) => {
    return await mutate(
      deleteShoppingList,
      variables
    )
  }

  return {
    deleteSavedCartMutation,
  };
};
