var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"searchResultProductToCartBtn dsb-product-actions__action",attrs:{"aria-label":_vm.value
      ? _vm.$t(
          ((_vm.PageCategory.PRODUCT) + "." + (_vm.ActionTypeScreenreader.ADD_TO_CART_CANCEL)),
          { name: _vm.cleanedName }
        )
      : _vm.$t(
          ((_vm.PageCategory.PRODUCT) + "." + (_vm.ActionTypeScreenreader.SET_QUANTITY_FOR_CART)),
          { name: _vm.cleanedName }
        ),"disabled":!_vm.active},on:{"click":function($event){return _vm.$emit('input', !_vm.value)}}},[_c('span',{staticClass:"dsb-product-actions__action-icon dsb-icon-left",class:( _obj = {}, _obj[_vm.action.icon] = !_vm.value, _obj['dsb-close-icon'] =  _vm.value, _obj['text-sf-c-text-disabled cursor-default dsb-icon-disabled'] =  !_vm.active, _obj )},[_vm._v("\n    "+_vm._s(_vm.value
        ? _vm.$t(((_vm.PageCategory.PRODUCT) + "." + (_vm.ProductActions.CANCEL)))
        : _vm.$t(((_vm.PageCategory.PRODUCT) + "." + (_vm.action.type))))+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }