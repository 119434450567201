export enum OrdersFilter {
  TIME_PERIOD = 'filter_time_period',
  DELIVERY_STATUS = 'filter_delivery_status'
}

export enum OrdersSearchBar {
  PLACEHOLDER = 'search_placeholder',
  BUTTON_TEXT = 'search_button_text'
}

export enum OrderDetails {
  DATE = 'order_date',
  ID = 'order_id',
  DELIVERY_STATUS = 'order_delivery_status',
  DETAILS = 'order_details',
  TOTAL_INCL_TAX = 'order_total_incl_tax',
  PAYMENT_METHOD = 'order_payment_method'
}

export enum OrderDeliveryStatus {
  CANCELED = 'Canceled',
  PARTIAL_CANCELED = 'PartialCanceled',
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  CLOSED = 'Closed',
  PARTIAL_SHIPPED = 'Partial',
  ORDERED = 'Ordered',
  SHIPPED = 'Shipped',
  MIXED = 'Mixed',
  COMPLETE = 'Complete',
  BACKORDERED = 'Backordered',
  INVOICED = 'Invoiced',
  PENDING_PAYMENT = 'Pending Payment'
}
