



































































import { defineComponent } from '@vue/composition-api'
import {SfButton, SfIcon} from '@storefront-ui/vue'
import { PropType } from '@nuxtjs/composition-api'
import { Image } from '~/types/Image'
import SfModal from "~/components/Modal/SfModal.vue";

export default defineComponent({
  name: 'DsbConfirmationModal',
  components: {SfModal, SfButton, SfIcon },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    bgImageClass: {
      type: String,
      default: 'question-bg-image',
    },
    title: {
      type: String,
      default: '',
    },
    subline: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    cancelButtonText: {
      type: String,
      default: 'Abbrechen',
    },
    agreeButtonText: {
      type: String,
      default: 'Bestätigen und schließen',
    },
    cancelButtonTextScreenreader: {
      type: String,
      default: 'Abbrechen',
    },
    agreeButtonTextScreenreader: {
      type: String,
      default: 'Bestätigen und schließen',
    },
  },
  methods: {
    close () {
      this.$emit('close', false)
    },
    agreeTerms () {
      this.$emit('agree')
      this.close()
    },
  },
})
