import {ref, watch} from "@nuxtjs/composition-api";
import {ListView} from "~/types/ListView";

const view = ref<string>(ListView.GALLERY)
const viewSelectionDisabled = ref(false)

export function initViewSelection() {
  if (typeof window !== 'undefined') {
    if (window?.localStorage.getItem('catalog_view') === null){
      view.value = ListView.GALLERY;
      return
    }

    if (window?.localStorage.getItem('catalog_view') === ListView.GALLERY) {
      view.value = ListView.GALLERY;
    } else {
      view.value = ListView.LIST;
    }
  }
}

function setViewSelection(view) {
  if (typeof window !== 'undefined') {
    if (view !== ListView.SEARCH) {
      return window?.localStorage.setItem('catalog_view', view)
    }
  }
}

watch(view, (currentView) => {
  setViewSelection(currentView)
});

export function useProductListView () {
  return {
    view,
    viewSelectionDisabled,
    initViewSelection
  }
}
