import { useCart } from "~/composables";
import {customRef, useRouter, computed} from "@nuxtjs/composition-api";
import {ref} from "@vue/composition-api";
import _debounce from 'lodash.debounce';
import {useErrorMessage} from "~/composables/useErrorMessage";

export const useCartItem = (sku) => {
  const router = useRouter();

  const {
    cartListItems,
    cartItems,
    lastUpdatedCartSku,
    loadTotalQty,
    error,
    removeItem: removeItemFromCartBase,
    updateItemQty: updateItemQtyBase,
  } = useCart()
  const cartItem = computed(() => cartItems.value.find(item => item.product.sku === sku))

  if(!cartItem) {
    return {
      cartItem: undefined,
      cartListItem: undefined,
      updateCartItem: undefined,
      removeItemFromCart: undefined,
      goToProductPage: undefined,
    }
  }

  const quantity = customRef<number>((track, trigger) => ({
    set(value) {
      updateCartItem(value).then(() => {
        trigger()
        tempQuantity.value = quantity.value
      })
    },
    get() {
      track()
      return cartItem.value?.quantity
    }
  }))

  const tempQuantity = ref(quantity.value)

  const delayedUpdateItemQty = _debounce(() => quantity.value = tempQuantity.value, 1000);

  const cartListItem = computed(() => cartListItems.value.find(item => item.sku === sku))

  async function updateCartItem(quantity: number) {
    lastUpdatedCartSku.value = cartListItem.value.sku

    const { setErrorMessage: setProductActionErrorMessage } = useErrorMessage('actions_' + cartListItem.value.sku)

    if(quantity == 0){
      setProductActionErrorMessage({ name: "updateItemQtyToZero", message:"0 ist ein ungültiger Wert. Der vorherige Wert wurde wiederhergestellt." }, true)
      return
    }

    await updateItemQtyBase({product: cartItem.value, quantity})
    await loadTotalQty();

    if(error.value){
      const { setErrorMessage: setProductActionErrorMessage } = useErrorMessage('actions_' + cartListItem.value.sku)
      setProductActionErrorMessage(error.value.updateItemQty, true)
    }
  }

  async function removeItemFromCart() {
    lastUpdatedCartSku.value = cartListItem.value.sku
    await removeItemFromCartBase({product: cartItem.value})
    await loadTotalQty();
  }

  const goToProductPage = async() => {
    await router.push(cartListItem.value.url)
  }

  return {
    cartItem,
    cartListItem,
    updateCartItem,
    removeItemFromCart,
    goToProductPage,
    incrementQuantity: () => {
      tempQuantity.value++
      delayedUpdateItemQty()
    },
    decrementQuantity: () => {
      tempQuantity.value > 0 && tempQuantity.value--
      delayedUpdateItemQty()
    },
    tempQuantity,
    quantity,
    delayedUpdateItemQty
  }
}
