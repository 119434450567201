



































import {PropType, defineComponent, computed, useFetch} from "@nuxtjs/composition-api";
import {Action, ActionType, ActionTypeScreenreader} from "~/types/Action";
import {PageCategory} from "~/types/PageCategory";
import SpinnerSvg from '~/components/utils/SpinnerSvg.vue'
import {ProductActions} from "~/types/ProductActions";
import {ref} from "@vue/composition-api";
import {
  SfLoader,
} from '@storefront-ui/vue';
import {useSaveToCollection} from "~/modules/pdfCollection/composables/useSaveToPdfCollection";
import {ProductInterface, useConfirmationModal, useErrorMessage} from "~/composables";
import {useCollectionList} from "~/modules/pdfCollection/composables/usePdfCollectionList";
import {usePdfCollectionStore} from "~/modules/pdfCollection/store/pdfCollectionStore";

export default defineComponent({
  components: {
    SpinnerSvg,
    SfLoader
  },
  props: {
    product: {
      required: true,
      type: Object as PropType<ProductInterface>,
    },
    action: {
      required: true,
      type: Object as PropType<Action>,
    },
    value: {
      required: false,
      type: Boolean
    }
  },
  setup(prop) {
    const { addItem: addItemToCollection } = useSaveToCollection();
    const isLoading = ref(false);
    const pdfCollectionStore = usePdfCollectionStore();
    const { loadCollectionItems, removeCollectionItemFromList } = useCollectionList();
    const { setErrorMessage } = useErrorMessage("actions_" + prop.product.sku);
    const { setConfirmationModal, showConfirmationModal } = useConfirmationModal();

    useFetch(async () => {
      await loadCollectionItems();
    });
    const addToCollection = async (product) => {
      isLoading.value = true;
      const addQueryResult = await addItemToCollection(product.sku);

      if (addQueryResult['success'] === false) {
        if(addQueryResult['message'] === ':)') {
          //TODO: Add specific error messages that are meant to be displayed to the user
          setErrorMessage( { name: "download", message: addQueryResult['message'] });
        } else {
          setErrorMessage( { name: "download", message: "Leider ist ein Fehler aufgetreten. Falls das Problem bestehen bleibt, kontaktieren Sie bitte den Support." });
        }
      }

      isLoading.value = false;
      await loadCollectionItems();
    };
    const confirmRemoveFromCollection = (product) => {
      setConfirmationModal({
        title: `Möchten Sie das Produkt \"${product.name.trim()}\" wirklich aus Ihrer Pdf-Kollektion entfernen?`,
        agreeButtonTextScreenReader: `${product.name.trim()} aus der Kollektion entfernen bestätigen`,
        cancelButtonTextScreenReader: `${product.name.trim()} aus der Kollektion entfernen abbrechen`,
        closeHandler: () => showConfirmationModal(false),
        confirmHandler: () => removeFromCollectionAndReload(product)
      })
    }

    const removeFromCollectionAndReload = async (product) => {
      isLoading.value = true;
      const removeMutationResult = await removeCollectionItemFromList(product.sku);

      if (
        removeMutationResult["data"]["removeProductFromPdfCollection"]["success"]
      ) {
        await loadCollectionItems();
      } else {
        setErrorMessage( { name: "removeProductFromPdfCollection", message: "Leider ist ein Fehler aufgetreten. Falls das Problem bestehen bleibt, kontaktieren Sie bitte den Support." })
      }

      isLoading.value = false;
    };

    const isInPdfCollection = computed (() => {
      const pdfCollectionProduct = pdfCollectionStore.products.find(item => item.sku === prop.product.sku);
      return !!pdfCollectionProduct;
    });

    return {
      // @ts-ignore
      addToCollection,
      PageCategory,
      ProductActions,
      ActionTypeScreenreader,
      ActionType,
      isLoading,
      isInPdfCollection,
      confirmRemoveFromCollection
    }
  }
})
