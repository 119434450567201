



































import {PropType, defineComponent, computed} from "@nuxtjs/composition-api";
import {Action, ActionType, ActionTypeScreenreader} from "~/types/Action";
import {PageCategory} from "~/types/PageCategory";
import SpinnerSvg from '~/components/utils/SpinnerSvg.vue'
import {ProductActions} from "~/types/ProductActions";
import {ref} from "@vue/composition-api";
import {
  SfLoader,
} from '@storefront-ui/vue';
import useWishlist from "~/modules/wishlist/composables/useWishlist";

export default defineComponent({
  components: {
    SpinnerSvg,
    SfLoader
  },
  props: {
    product: {
      required: true,
      type: Object,
    },
    action: {
      required: true,
      type: Object as PropType<Action>,
    },
    value: {
      required: false,
      type: Boolean
    }
  },
  setup(prop) {
    const {
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist,
    } = useWishlist();

    const isLoading = ref(false);

    const addToWishlist = async (product) => {
      isLoading.value = true;
      await (
        isInWishlist({product})
          ? removeItemFromWishlist({product})
          : addItemToWishlistBase({product})
      );
      isLoading.value = false;
    };

    return {
      // @ts-ignore
      isInWishlist: computed(() => isInWishlist({product: prop.product})),
      addToWishlist,
      PageCategory,
      ProductActions,
      ActionTypeScreenreader,
      ActionType,
      isLoading
    }
  }
})
