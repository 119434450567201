import {useApi} from "~/composables";

export const addProductToCollection = () => {
  const {
    mutate,
  } = useApi();

  const addProductToCollectionMutationQuery = `
  mutation addProductToPdfCollection ($productSku: String) {
    addProductToPdfCollection(
      input: {
          product_sku: $productSku
        }
      ) {
          message
          success
        }
  }`;

  const addProductToCollection = async (variables) => {
    return await mutate(
      addProductToCollectionMutationQuery,
      variables
    )
  }

  return {
    mutation: addProductToCollection,
  };
};
