import {customRef, computed, useRouter} from '@nuxtjs/composition-api'
import {Action, ActionIcon, ActionType} from '~/types/Action'
import DownloadAction from "~/components/ProductActions/DownloadAction.vue";
import ProductActionQuantity from "~/components/Quantity/productActionQuantity.vue";
import AddToCartAction from "~/components/ProductActions/AddToCartAction.vue";
import {MagentoProduct} from "~/types/Product";
import favoriteAction from "~/components/ProductActions/FavoriteAction.vue";
import addToCollectionAction from "~/components/ProductActions/AddToCollectionAction.vue";
import {useConfirmationModal} from "~/composables/useConfirmationModal";
import useWishlist from "~/modules/wishlist/composables/useWishlist";
import {useCartItem, useDownloadAction, useBreakpoints} from "~/composables";
import AcceptLicenseAction from "~/components/ProductActions/AcceptLicenseAction.vue";


export function useProductActions(product: MagentoProduct | any) {
  const router = useRouter()
  const {
    addItem: addItemToWishlistBase,
    isInWishlist,
    removeItem: removeItemFromWishlist,
  } = useWishlist();

  // @ts-ignore
  const {mdOrGreater} = useBreakpoints()

  const {
    downloadDigitalContent
  } = useDownloadAction('actions_' + product.sku)

  const {removeItemFromCart, goToProductPage} = useCartItem(product.sku)

  const {setConfirmationModal, showConfirmationModal} = useConfirmationModal()

  const addToWishlist = async (product) => {
    await (
      isInWishlist({product})
        ? removeItemFromWishlist({product})
        : addItemToWishlistBase({product})
    );

  };

  const goToWeb2PrintPage = async() => {
    await router.push(`/web2print/${product.sku}/${product.url_key}.html`)
  }
  function preview() {
    console.log('preview')
  }

  function actionsRef(value: Map<ActionType, Action>) {
    return customRef<Map<ActionType, Action>>((track, trigger) => ({
      set(inValue) {
        value = inValue
        trigger()
      },
      get() {
        track()
        const actions = new Map()
        for (const [actionKey, action] of value.entries()) {
          if (!action.params) {
            actions.set(actionKey, action)
            continue
          }

          const params = {}
          for (const [paramKey, param] of Object.entries(action.params)) {
            params[paramKey] = param.value
          }

          actions.set(actionKey, {...action, params})
        }

        return actions
      }
    }))
  }

  const actions = actionsRef(
    new Map(
      [
        [
          ActionType.PERSONALIZE,
          {
            type: ActionType.PERSONALIZE,
            icon: ActionIcon.EDIT,
            onClick: goToWeb2PrintPage
          },
        ],
        [
          ActionType.DOWNLOAD,
          {
            type: ActionType.DOWNLOAD,
            icon: ActionIcon.DOWNLOAD,
            onClick: downloadDigitalContent,
            component: DownloadAction
          },
        ],
        [
          ActionType.ACCEPT_LICENSE,
          {
            type: ActionType.ACCEPT_LICENSE,
            onClick: downloadDigitalContent,
            component: AcceptLicenseAction
          },
        ],
        [
          ActionType.ADD_TO_COLLECTION,
          {
            type: ActionType.ADD_TO_COLLECTION,
            icon: ActionIcon.PAPERCLIP,
            component: addToCollectionAction
          },
        ],
        [
          ActionType.FAVORITE,
          {
            type: ActionType.FAVORITE,
            icon: ActionIcon.FAVORITE_ADD,
            onClick: addToWishlist,
            component: favoriteAction
          },
        ],
        [
          ActionType.FAVORITE_REMOVE,
          {
            type: ActionType.FAVORITE_REMOVE,
            icon: ActionIcon.REMOVE,
            onClick: () => setConfirmationModal({
              title: `Möchten Sie das Produkt \"${product.name.trim()}\" wirklich aus Ihren Favoriten entfernen?`,
              agreeButtonTextScreenReader: `${product.name.trim()} aus den Favoriten entfernen bestätigen`,
              cancelButtonTextScreenReader: `${product.name.trim()} aus den Favoriten entfernen abbrechen`,
              closeHandler: () => showConfirmationModal(false),
              confirmHandler: () => removeItemFromWishlist({product})
            })
          },
        ],
        [
          ActionType.ADD_TO_CART,
          {
            type: ActionType.ADD_TO_CART,
            icon: ActionIcon.EMPTY_CART,
            component: AddToCartAction
          },
        ],
        [
          ActionType.REMOVE_FROM_CART,
          {
            type: ActionType.REMOVE_FROM_CART,
            icon: ActionIcon.REMOVE,
            onClick: () => setConfirmationModal({
              title: `Möchten Sie das Produkt \"${product.name.trim()}\" wirklich aus dem Warenkorb löschen?`,
              agreeButtonTextScreenReader: `${product.name} aus dem Warenkorb löschen bestätigen`,
              cancelButtonTextScreenReader: `${product.name} aus dem Warenkorb löschen abbrechen`,
              closeHandler: () => showConfirmationModal(false),
              confirmHandler: removeItemFromCart
            })
          },
        ],
        [
          ActionType.PREVIEW,
          {
            type: ActionType.PREVIEW,
            icon: ActionIcon.PREVIEW,
            onClick: preview,
          },
        ],
        [
          ActionType.QUANTITY,
          {
            type: ActionType.QUANTITY,
            params: {
              buttonSize: computed(() => mdOrGreater() ? 'var(--spacer-base)' : undefined),
              iconSize: computed(() => mdOrGreater() ? 'var(--spacer-sm)' : undefined),
              product: product
            },
            component: ProductActionQuantity,
          },
        ],
        [
          ActionType.DETAILS,
          {
            type: ActionType.DETAILS,
            icon: ActionIcon.INFO,
            onClick: goToProductPage,
          },
        ],
      ],
    ),
  )

  return {
    removeItemFromWishlist,
    addToWishlist,
    actions,
  }
}
