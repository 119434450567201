export enum ListHeader {
  PRODUCTS_FOUND='header_products_found',
  ONE_PRODUCT_FOUND='header_one_product_found',
  ONE_PRODUCT_FOUND_SR='header_one_product_found_sr',
  PRODUCTS_QTY='header_products_qty',
  ONE_PRODUCT_QTY='header_one_product_qty',
  ONE_PRODUCT_QTY_SR='header_one_product_qty_sr',
  SORT_BY='header_sort_by',
  CATEGORY='header_category',
  DISPLAY_AS='header_display_as',
  GALLERY='header_gallery',
  LIST='header_list'
}

export enum ListFilter {
  HEADER_ALL = 'filter_header_all',
  SHOW_ALL = 'filter_show_all',
  DOWNLOADS = 'delivery_type',
  IN_STOCK = 'stock_status',
  PERSONALISABLE = 'print_on_demand'
}

export enum ListFooter {
  PREVIOUS_PAGE = 'pagination_previous_page',
  NEXT_PAGE='paginiation_next_page',
  SHOW='select_show',
  RESULTS_PER_PAGE='select_results_per_page'
}
