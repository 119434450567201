import axios from 'axios'
import {useContext} from "@nuxtjs/composition-api";
import {defaultConfig} from "~/modules/core/defaultConfig";

const shopApi = axios.create({
  timeout: 30000,
  headers: {
    'X-Source': 'shop-api-client',
  },
})

export function useShopApi () {
  const { app } = useContext();
  const customerToken = app.$cookies.get(defaultConfig.cookies.customerCookieName);

  shopApi.defaults.headers.common = {'Authorization': `Bearer ${customerToken}`}
  return { shopApi }
}
