const middleware = {}

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['has-accepted-licenses'] = require('../middleware/has-accepted-licenses.ts')
middleware['has-accepted-licenses'] = middleware['has-accepted-licenses'].default || middleware['has-accepted-licenses']

middleware['has-dummy-email'] = require('../middleware/has-dummy-email.ts')
middleware['has-dummy-email'] = middleware['has-dummy-email'].default || middleware['has-dummy-email']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['is-internal-valid'] = require('../middleware/is-internal-valid.ts')
middleware['is-internal-valid'] = middleware['is-internal-valid'].default || middleware['is-internal-valid']

middleware['url-resolver'] = require('../middleware/url-resolver.ts')
middleware['url-resolver'] = middleware['url-resolver'].default || middleware['url-resolver']

export default middleware
