

















































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useRouter,
} from "@nuxtjs/composition-api";
import DsbLoginError from "~/modules/login/components/LoginError.vue";
import DsbModal from "~/components/Modal/DsbModal.vue";
import DsbLoginEnterDebitorId from "~/modules/login/components/LoginEnterDebitorId.vue";
import { PageCategory } from "~/types/PageCategory";
import { useLogin } from "~/modules/login/composables/useLogin";
import { SfButton } from "@storefront-ui/vue";
import { LoginState, LoginGeneral, LoginLink } from "~/modules/login/types";
import LoginUsernamePassword from "~/modules/login/components/LoginUsernamePassword.vue";

export default defineComponent({
  name: "Login",
  layout: "login",
  components: {
    LoginUsernamePassword,
    DsbLoginEnterDebitorId,
    DsbModal,
    DsbLoginError,
    SfButton,
  },
  setup() {
    const loading = ref(true);

    onMounted(() => {
      loading.value = false;
    });

    const router = useRouter();

    const { state, ...loginData } = useLogin();

    function redirect() {
      window.location.href = "/mooauth/actions/sendAuthorizationRequest/";
    }

    if (state.value === LoginState.LOGGED_IN) {
      router.push("/");
    }

    const { app } = useContext();
    const hasSSOCookie = computed(() => !!app.$cookies.get("sso-customer"));

    return {
      PageCategory,
      LoginGeneral,
      LoginLink,
      LoginState,
      ...loginData,
      state,
      loading,
      redirect,
      hasSSOCookie,
    };
  },
  head() {
    return {
      title: "Login",
    };
  },
});
