import { Logger } from '~/helpers/logger';
import { SetBillingAddressOnCartInput } from '~/modules/GraphQL/types';
import { Error } from "~/composables";

export const saveBillingAddressCommand = {
  execute: async (context, cartId, billingDetails, customQuery, customHeaders): Promise<{ data, errors: Error[] }> => {
    const {
      apartment,
      neighborhood,
      extra,
      sameAsShipping,
      customerAddressId,
      ...address
    } = billingDetails;

    const billingData = customerAddressId
      ? ({
        // workaround to save address with field direction
        //customer_address_id: customerAddressId,
        address: {
          ...address,
          street: [address.street, apartment, neighborhood, extra].filter(Boolean),
        },
        same_as_shipping: sameAsShipping,
      })
      : ({
        address: {
          ...address,
          street: [address.street, apartment, neighborhood, extra].filter(Boolean),
        },
        same_as_shipping: sameAsShipping,
      });

    const setBillingAddressOnCartInput: SetBillingAddressOnCartInput = {
      cart_id: cartId,
      billing_address: billingData,
    };

    const setBillingAddressOnCartCustomQuery = `
    mutation setBillingAddressOnCart($input: SetBillingAddressOnCartInput) {
        setBillingAddressOnCart(input: $input) {
          cart {
            billing_address {
              firstname
              lastname
              company
              address_line_2
              address_line_3
              sap_name
              street
              city
              postcode
              telephone
              country {
                code
                label
              }
            }
          }
        }
      }
      `;

    const { data, errors } = await context.$vsf.$magento.api.setBillingAddressOnCart(
      setBillingAddressOnCartInput,
      setBillingAddressOnCartCustomQuery,
      customHeaders,
    );

    Logger.debug('[Result setBillingAddressOnCart]:', { data, errors });

    return { data, errors }
  },
};
