import {SavedCartHeader, SavedCartHeaderScreenreader} from '../../types/savedCarts/savedCartsCategories'
import { General } from "../../types/Page";

export default {
  [General.TITLE]: 'Gespeicherte Warenkörbe',

  [SavedCartHeader.SAVED_ON]: 'Gespeichert am',
  [SavedCartHeader.POSITIONS]: 'Anzahl Positionen',
  [SavedCartHeader.DELETE]: 'Löschen',
  [SavedCartHeader.APPLY]: 'Übernehmen',

  [SavedCartHeaderScreenreader.DELETE]: 'Gespeicherten Warenkorb {name} löschen',
  [SavedCartHeaderScreenreader.APPLY]: 'Gespeicherten Warenkorb {name} in den Warenkorb übernehmen',
  [SavedCartHeaderScreenreader.OPEN]: 'Gespeicherten Warenkorb {name} öffnen',
  [SavedCartHeaderScreenreader.CLOSE]: 'Gespeicherten Warenkorb {name} schließen',
}
