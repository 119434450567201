import { useApi } from "~/composables";
import { ref } from "@nuxtjs/composition-api";
import { Logger } from "~/helpers/logger";

export const getProductLink = () => {
  const { query } = useApi();
  const loading = ref(false);

  const GET_PRODUCT_LINK_QUERY = `
    query products($filter: ProductAttributeFilterInput) {
      products(
        filter: $filter
      ) {
        items {
          url_key
          url_path
          sku
          job_level
          job_qualifications
          sales_channel
          sales_region
          user_type
        }
      }
    }
  `;

  const load = async (params) => {
    try {
      Logger.debug("getProductLink/load");
      loading.value = true;

      return await query(GET_PRODUCT_LINK_QUERY, params);
    } catch (error) {
      Logger.error("getProductLink/error", error);
    } finally {
      loading.value = false;
    }
  };

  return {
    load,
    loading,
  };
};
