import {computed, ref, useRoute} from '@nuxtjs/composition-api'
import {licenceTermsMock} from '~/mocks/licence-terms.mock'
import {mapLicenceItemsToProductDetails} from '~/utils/mapProductToProductDetails'
import {ProductDetailType} from '~/types/ProductData'
import {reactive} from "@vue/composition-api";
import {AgnosticPagination} from "@vue-storefront/core";
import {getFormattedDate} from "~/utils/dateFormatter";
import {getLicenses} from "~/modules/license/composables/useLicenseList/getLicenses";
import {fetchLicenceProducts as fetch} from "~/modules/license/helper/fetchLicenceProducts";
import productGetters from "~/modules/catalog/product/getters/productGetters";
import {perPageOptions} from "~/modules/catalog/category/composables/useFacet/perPageOptions";

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum Status {
  ALL = -1,
  EXPIRED = 0,
  NOT_EXPIRED = 1,
}

const loading = ref(false)
const error = reactive({fetchLicenceProducts: null})

export const licenseDetails: ProductDetailType[] = [
  ProductDetailType.ARTICLE_NUMBER,
  ProductDetailType.LICENCE_VERSION,
]

const licencesFilterByOptions = [
  {
    value: Status.ALL,
    label: 'Alle',
  },
  {
    value: Status.NOT_EXPIRED,
    label: 'Aktive Lizenzen',
  },
  {
    value: Status.EXPIRED,
    label: 'Abgelaufene Lizenzen',
  },
]

const licencesSortByOptions = [
  {
    value: {key: 'expires_at', direction: SortDirection.ASC},
    label: 'Ablaufdatum aufsteigend',
  },
  {
    value: {key: 'expires_at', direction: SortDirection.DESC},
    label: 'Ablaufdatum absteigend',
  },
]

const rawLicenceProducts = ref([])

const pagination = ref<AgnosticPagination>({
  currentPage: 1,
  totalPages: 0,
  totalItems: 0,
  itemsPerPage: perPageOptions[0],
  pageOptions: perPageOptions
});

// ToDo: Get licenceTerms
const licenceTerms = ref(licenceTermsMock)
const licenceModalVisible = ref(false)

const licencesFilterBy = ref<Status>(Status.ALL)
const licencesSortBy = ref({key: 'expires_at', direction: SortDirection.ASC})
const licencesSortBySelection = ref(SortDirection.ASC)

async function fetchLicenceProducts(query: Function) {
  loading.value = true

  const filterParams = {
    "filter": {
      "status": {
        "eq": licencesFilterBy.value
      }
    },
    "sort": {
      'expires_at': licencesSortBySelection.value
    },
    "currentPage": pagination.value.currentPage,
    "pageSize": pagination.value.itemsPerPage
  }


  const result = await fetch(query, filterParams, error)
  pagination.value = {
    ...pagination.value,
    totalItems: result.licenses.total_count,
    itemsPerPage: result.licenses.page_info.page_size,
    currentPage: result.licenses.page_info.current_page,
    totalPages: Math.ceil(result.licenses.total_count / result.licenses.page_info.page_size),
  }

  rawLicenceProducts.value = result.licenses.items
  loading.value = false
}

export function useLicenseList() {
  const {load} = getLicenses();
  const route = useRoute()

  if (route.value.query.itemsPerPage) {
    pagination.value.itemsPerPage = parseInt(route.value.query.itemsPerPage as string)
  }

  if (route.value.query.page) {
    pagination.value.currentPage = parseInt(route.value.query.page as string) || pagination.value.currentPage
  }

  const licenceProducts = computed(() => rawLicenceProducts.value.map(product => ({
      sku: product.parent_sku,
      name: product.name,
      url: productGetters.getParentUrl(product),
      text: product.description?.html || '',
      imgSrc: productGetters.getProductThumbnailImage(product),
      details: mapLicenceItemsToProductDetails(product, licenseDetails),
      licence: {
        expirationDate: getFormattedDate(product.license?.expirationDate),
        weeksLeft: product.license?.weeksLeft,
        expired: product.license?.expired,
        termsAgreed: product.license?.termsAgreed,
        acceptedAt: getFormattedDate(product.license?.acceptedAt),
      },
    })),
  )

  return {
    licencesSortByOptions,
    licencesSortBy,
    licencesSortBySelection,
    licencesFilterByOptions,
    licencesFilterBy,
    licenceModalVisible,
    licenceTerms,
    licenceProducts,
    pagination,
    fetchLicenceProducts: () => fetchLicenceProducts(load),
    fetch,
    loading,
    error
  }
}
