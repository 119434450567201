<template>
  <div class="sf-footer-column">
    <div
      class="sf-footer-column__title"
      :role="lgOrLess()? 'button' : 'heading'"
      :tabindex="lgOrLess()?'0':'-1'"
      aria-level="5"
      @click="toggle(title)"
      @keydown.enter="toggle(title)"
      :id="columnIndex"
    >
      {{ title }}
      <span
        v-show="lgOrLess()"
        class="sf-footer-column__chevron"
      >
        <SfChevron :class="{ 'sf-chevron--top': isColumnOpen }" />
      </span>
    </div>

    <transition name="sf-fade">
      <div
        :class="{ 'display-none': !isColumnOpen }"
        class="sf-footer-column__content"
      >
        <SfList class="px-4 py-1 lg:p-0" :aria-labelledby="columnIndex">
          <SfListItem v-for="(child, childIndex) in listItems" :key="childIndex">
            <a v-if="child.link === 'imprint'" href="https://www.allianz.de/impressum" target="_blank"
               class="sf-menu-item__label">{{ $t(`${PageCategory.HOMEPAGE}.${child.label}`) }}</a>
            <a v-else-if="child.link === 'learning_portal'" href="https://amisonline.allianz.de/leitfaeden/medien-und-drucksachenportal.html" target="_blank"
               class="sf-menu-item__label">{{ $t(`${PageCategory.HOMEPAGE}.${child.label}`) }}
            </a>
            <SfMenuItem v-else :label="$t(`${PageCategory.HOMEPAGE}.${child.label}`) " :link="child.link"/>
          </SfListItem>
        </SfList>
      </div>
    </transition>
  </div>
</template>
<script>
import { SfChevron, SfButton, SfList, SfMenuItem } from '@storefront-ui/vue'
import { focus } from "@storefront-ui/vue/src/utilities/directives/focus/focus-directive";
import { useBreakpoints } from '~/composables/useBreakpoints'
import { PageCategory } from '~/types/PageCategory'
import { Footer, General } from '~/types/homePage/HomepageCategories'

export default {
  name: "DsbFooterColumn",
  directives: { focus },
  components: {
    SfChevron,
    SfButton,
    SfList,
    SfMenuItem
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    listItems: {
      type: Array,
      default: []
    },
    columnIndex: {
      type: String,
      required: true
    }
  },
  inject: ["items"],
  data() {
    return {
      isColumnOpen: true,
    };
  },
  watch: {
    "$parent.isOpen": {
      handler(newVal) {
        this.isColumnOpen = newVal.includes(this.title);
      },
    },
  },
  created() {
    this.items.push(this.title);
  },
  methods: {
    toggle(payload) {
      this.$parent.toggle(payload);
    },
  },
  setup(){
    return {
      ...useBreakpoints(),
      PageCategory,
      Footer,
      General,
    }
  }
};
</script>
