export enum SavedCartHeader {
  SAVED_ON = 'saved_cart_date',
  POSITIONS = 'saved_cart_positions',
  DELETE = 'saved_cart_delete',
  APPLY = 'saved_cart_apply'
}

export enum SavedCartHeaderScreenreader {
  DELETE = 'saved_cart_screenreader_delete',
  APPLY = 'saved_cart_screenreader_apply',
  OPEN = 'saved_cart_screenreader_open',
  CLOSE = 'saved_cart_screenreader_close'
}
