import {useShopApi} from "~/composables";
import {AxiosError, AxiosResponse} from "axios";
// @ts-ignore
import {DownloadResponse} from '~/types/Download'

export function downloadPdfCollection() {
  const {shopApi} = useShopApi();
  const downloadCollection = (): Promise<any> => {
    return new Promise<any>((resolve) => {
      const downloadUrl = `/index.php/pdf-collection/collection/download`

      shopApi.get(downloadUrl, {responseType: 'blob'}).then((fileResponse: AxiosResponse<Blob>) => {
        const mimeType = 'application/zip'

        let filename = `PdfKollektion.zip`
        if (typeof fileResponse.headers['content-disposition'] === 'string') {
          const contentDisposition = fileResponse.headers['content-disposition']
          //gets previously generated filename from request
          filename = trimFilenameFromHeader(contentDisposition);
        }

        const blob = new Blob([fileResponse.data], {type: mimeType})
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        URL.revokeObjectURL(link.href)

        resolve('success')
      }).catch((error: AxiosError<Blob>) => {
        resolve('error');
      })
    })
  }

  const trimFilenameFromHeader = (contentDisposition: string) => {
    let filename = ''
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    return filename
  }

  return {
    downloadCollection
  };
}


