import { MagentoProduct } from "~/types/Product";
import { ProductStockStatus } from "@vue-storefront/magento-api";
import { ProductAvailability } from "~/types/ProductCategories";
import { getChildProductBySku, isConfigurable } from "~/composables/useProductDetails/productType";
import { ref } from "@nuxtjs/composition-api";
import { useProductVersion } from "~/composables/useProductVersion";

export function getProductAvailability(product: MagentoProduct) {
  if (isConfigurable(product)) {
    const parentProduct = ref(product);

    const { skuPhysical } = useProductVersion(parentProduct);
    const { product: childProduct } = getChildProductBySku(parentProduct.value, skuPhysical.value);

    return getAvailabilityTextByStatus(childProduct?.stock_status, childProduct?.stock_availability_from, childProduct?.salable_qty);
  }

  return getAvailabilityTextByStatus(product?.stock_status, (product as any)?.stock_availability_from, (product as any)?.salable_qty);
}

function getAvailabilityTextByStatus(stockStatus: string, stockAvailabilityFrom?: string, salableQty?: number) {
  if (stockStatus === ProductStockStatus.OutOfStock) {
    return { text: ProductAvailability.NOT_AVAILABLE, options: { week: 0 } };
  }

  // Change due CAS-514
  if (salableQty > 0) {
    return { text: ProductAvailability.AVAILABLE, options: { week: 0 } };
  }

  const dateStock = new Date(stockAvailabilityFrom);
  const dateNow = new Date();
  if (salableQty <= 0 && dateNow <= dateStock) {
    const onejan = new Date(dateStock.getFullYear(), 0, 1);
    const millisecsInDay = 86400000;
    const weekNumber = Math.ceil(((dateStock.getTime() - onejan.getTime()) / millisecsInDay + onejan.getDay() + 1) / 7);

    return { text: ProductAvailability.DELIVERY_FROM_WEEK, options: { week: weekNumber } };
  }

  return { text: ProductAvailability.NOT_AVAILABLE, options: { week: 0 } };
}
