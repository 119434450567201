import { render, staticRenderFns } from "./DsbUserAddressDetails.vue?vue&type=template&id=443c960a&scoped=true&"
import script from "./DsbUserAddressDetails.vue?vue&type=script&lang=ts&"
export * from "./DsbUserAddressDetails.vue?vue&type=script&lang=ts&"
import style0 from "./DsbUserAddressDetails.vue?vue&type=style&index=0&id=443c960a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "443c960a",
  null
  
)

export default component.exports