import {ProductDetailType} from "~/types/ProductData";
import {mapProductToProductDetails} from "~/utils/mapProductToProductDetails";
import {mapBaseProduct} from "~/utils/mapBaseProduct";
import {MagentoProduct} from "~/types/Product";

export function mapProductSuggestionItem(product: MagentoProduct) {

  const details: ProductDetailType[] = [
    ProductDetailType.ARTICLE_NUMBER,
    ProductDetailType.VERSION_PRINT,
    ProductDetailType.VERSION_DOWNLOAD,
    ProductDetailType.PRICE
  ]

  return {
    ...(mapBaseProduct(product)),
    text: product.short_description?.html || '',
    details: mapProductToProductDetails(product, details),
  }
}
