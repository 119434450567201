import { useProductList, useCategory, useFacet } from '~/composables'
import { onSSR, useVSFContext } from "@vue-storefront/core";
import findDeep from 'deepdash/findDeep';
import { useUiHelpers } from "~/composables";
import { usePage } from "~/composables";
import {ref, Ref, useRouter, onMounted, computed, useFetch, useContext} from "@nuxtjs/composition-api";
import { Category } from "@vue-storefront/magento-api";
import { getInstance } from "~/helpers/hooks/getInstance";
import { PageCategory } from "~/types/PageCategory";
import { General } from "~/types/Page";
import { useUrlResolver } from '~/composables/useUrlResolver';
import facetGetters from "~/modules/catalog/category/getters/facetGetters";
import type { ProductInterface, CategoryTree } from '~/modules/GraphQL/types';
import useCategoryContent from "~/modules/catalog/category/components/cms/useCategoryContent";
import {useTraverseCategory} from "~/modules/catalog/category/helpers/useTraverseCategory";
import { isCategoryTreeRoute } from '~/modules/GraphQL/CategoryTreeRouteTypeguard';
import {CacheTagPrefix, useCache} from "@vue-storefront/cache";


const {
  breadcrumbs,
} = usePage()
const {
  products,
  sidebar,
  sortBy,
  filters,
  facets,
  pagination,
  view,
  productsLoading,
  sidebarLoading,
  sidebarAction,
  showAllAction,
  viewSelectionDisabled,
  initViewSelection
} = useProductList()

const isInitialized = ref(false)
const isProductsLoading = ref(false);
const isCategoriesLoading = ref(false);

const attributes = ['delivery_type', 'stock_status', 'print_on_demand']



export function useCategoryList () {
  sidebarLoading.value = computed(() => isCategoriesLoading.value)
  productsLoading.value = computed(() => isProductsLoading.value)

  const activeCategoryName = computed(() => activeCategory.value?.name ?? '');
  const routeData = ref<CategoryTree | null>(null);

  const { activeCategory, loadCategoryTree, categoryTree } = useTraverseCategory();

  return {
    loadCategoryTree,
    activeCategory,
    activeCategoryName,
    routeData,
    isInitialized,
    products,
    productsLoading,
    sidebar,
    sidebarLoading,
    sortBy,
    filters,
    facets,
    pagination,
    view,
    breadcrumbs,
    viewSelectionDisabled,
    initViewSelection
  }
}
