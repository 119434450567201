

































































































































































import {
  ref,
  reactive,
  defineComponent,
} from '@vue/composition-api';
import {
  SfInput,
  SfButton,
  SfCheckbox,
  SfLoader,
  SfBar,
} from '@storefront-ui/vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import useForgotPassword from '~/modules/customer/composables/useForgotPassword';
import useUser from '~/modules/customer/composables/useUser';
import DsbInput from "~/components/Form/DsbInput.vue";
import {customerPasswordRegExp, invalidPasswordMsg} from "~/modules/customer/helpers/passwordValidation";

extend('email', {
  ...email,
  message: 'Ungültige E-Mail-Adresse',
});

extend('required', {
  ...required,
  message: 'Dieses Feld ist erforderlich',
});

export default defineComponent({
  name: "LoginUsernamePassword",
  components: {
    DsbInput,
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    ValidationProvider,
    ValidationObserver,
    SfBar,
  },
  setup() {
    const isSubscribed = ref(false);
    const allowRemoteShoppingAssistance = ref(false);
    const form = ref({});
    const isLogin = ref(true);
    const isForgotten = ref(false);
    const isThankYouAfterForgotten = ref(false);
    const userEmail = ref('');
    const {
      login,
      loading,
      error: userError,
    } = useUser();
    const { request, error: forgotPasswordError, loading: forgotPasswordLoading } = useForgotPassword();

    const error = reactive({
      login: null,
      register: null,
    });

    const resetErrorValues = () => {
      error.login = null;
      error.register = null;
    };

    const setIsForgottenValue = (value) => {
      resetErrorValues();
      isForgotten.value = value;
      isLogin.value = !value;
    };

    const handleForm = (fn) => async () => {
      resetErrorValues();
      await fn({
        user: {
          ...form.value,
          is_subscribed: isSubscribed.value,
          allow_remote_shopping_assistance: allowRemoteShoppingAssistance.value,
        },
      });

      const hasUserErrors = userError.value.register || userError.value.login;
      if (hasUserErrors) {
        error.login = userError.value.login?.message;
        error.register = userError.value.register?.message;
        return;
      }
    };

    const handleLogin = async () => handleForm(login)();

    const handleForgotten = async () => {
      // @ts-ignore
      userEmail.value = form.value.email;
      await request({ email: userEmail.value });

      if (!forgotPasswordError.value.request) {
        isThankYouAfterForgotten.value = true;
        isForgotten.value = false;
      }
    };

    return {
      allowRemoteShoppingAssistance,
      error,
      forgotPasswordError,
      forgotPasswordLoading,
      form,
      handleForgotten,
      handleLogin,
      isForgotten,
      isLogin,
      isSubscribed,
      isThankYouAfterForgotten,
      loading,
      setIsForgottenValue,
      userEmail,
      userError,
    };
  },
})
