import {Route} from "vue-router";

const extendQueryParams = (path: string, route: Route): string => {

  const queryParams = route.query

  if (queryParams?.nr) {
    path += '?nr=' + queryParams.nr.toString()
  }
  if (queryParams?.nrl) {
    path += '?nrl=' + queryParams.nrl.toString()
  }

  return path
}

export default extendQueryParams;
