






































import { defineComponent } from '@nuxtjs/composition-api'
import { SfButton } from '@storefront-ui/vue'
import { useCartItem } from "~/composables";
import { ProductActions } from "~/types/ProductActions";
import { PageCategory } from "~/types/PageCategory";
import {ActionTypeScreenreader} from "~/types/Action";

export default defineComponent({
  name: 'DsbProductActionQuantity',
  components: {
    SfButton,
  },
  props: {
    buttonSize: {
      type: String,
      default: 'var(--spacer-lg)',
    },
    iconSize: {
      type: String,
      default: 'var(--spacer-base)',
    },
    inputFontSize: {
      type: String,
      default: '1.25rem',
    },
    sku: {
      required: true,
      type: String,
    },
    product: {
      required: true,
      type: Object,
    },
  },
  setup (props) {
    return {
      PageCategory,
      ProductActions,
      ActionTypeScreenreader,
      ...useCartItem(props.sku)
    }
  },
})
