import {defineStore} from 'pinia';

export interface EnteredOptions {
  uid: string;
  value: string;
}

interface CustomOptionsState {
  sku?: string;
  entered_options: Array<EnteredOptions>;
}

export const useCustomOptionsStore = defineStore('custom_options', {
  state: (): CustomOptionsState => ({
    sku: null,
    entered_options: [],
  })
});
