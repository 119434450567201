import { AxiosError, AxiosResponse } from "axios";
// @ts-ignore
import { useShopApi } from "~/composables";
import { DownloadResponse, LicenseResponse } from "~/types/Download";
import { useLogger } from "~/composables/logger";
import { useErrorMessage } from "~/composables/useErrorMessage";
import { useI18n } from "~/helpers/hooks/usei18n";

export function useDownloadAction(id = "") {
  const { log } = useLogger();
  const { shopApi } = useShopApi();
  const i18n = useI18n();

  const { setErrorMessage, resetAllErrors } = useErrorMessage(id);

  const acknowledgeLicenseAgreement = (sku: string): Promise<LicenseResponse> => {
    return new Promise<LicenseResponse>((resolve, reject) => {
      log(`Acknowledging the license agreement for product '${sku}'`);
      const licenseAgreementUrl = `/index.php/digitalcontent/product/license?sku=${sku}`;
      shopApi
        .put(licenseAgreementUrl)
        .then((successResponse: AxiosResponse<LicenseResponse>) => {
          log(`The license agreement for product '${sku}' has been acknowledged.`);
          resolve(successResponse.data);
        })
        .catch((error: AxiosError<DownloadResponse>) => {
          setErrorMessage({ name: "acknowledgeLicenseAgreement", message: error.message });
          log(`Failed to acknowledge the license agreement for product '${sku}': ${error.message}`);
          reject(error);
        });
    });
  };

  const downloadDigitalContent = (
    sku: string,
    successCallback: () => void,
    agreementRequiredCallback: (message: string) => void,
    errorCallback: (message: string) => void
  ) => {
    const downloadUrl = `/index.php/digitalcontent/product/download?sku=${sku}`;

    log(`Downloading product '${sku}'`);

    shopApi
      .get(downloadUrl, { responseType: "blob" })
      .then((fileResponse: AxiosResponse<Blob>) => {
        log(`Downloading digital content for ${sku}: ${downloadUrl}`);
        const mimeType = "application/pdf";

        let filename = `${sku}.pdf`;
        if (typeof fileResponse.headers["x-digital-content-filename"] === "string") {
          filename = fileResponse.headers["x-digital-content-filename"];
        }

        const blob = new Blob([fileResponse.data], { type: mimeType });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        URL.revokeObjectURL(link.href);

        successCallback();
      })
      .catch((error: AxiosError<Blob>) => {
        if (error.response == null || error.response.data == null) {
          setErrorMessage({ name: "download", message: error.message });
          errorCallback("");
          return;
        }

        if (error.response.status === 404) {
          setErrorMessage({ name: "download", message: error.message });
          errorCallback(i18n.t("PDF not found").toString());
          return;
        }

        const data = error.response.data;
        data.text().then((json: string) => {
          try {
            const downloadResponse = JSON.parse(json) as DownloadResponse;
            const httpStatusLicenseAgreementRequired = 428;
            if (error.response?.status === httpStatusLicenseAgreementRequired) {
              agreementRequiredCallback(downloadResponse.message);
              return;
            }
            errorCallback(downloadResponse.message);
          } catch (e) {
            setErrorMessage({ name: "download", message: error.message });
            errorCallback(i18n.t("download action response returned invalid JSON").toString());
          }
        });
      });
  };

  return {
    downloadDigitalContent,
    acknowledgeLicenseAgreement,
  };
}
