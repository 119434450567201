import {Middleware} from '@nuxt/types';
import {useCustomerStore} from "~/modules/customer/stores/customer";

const middleware: Middleware = (context) => {
  const customerStore = useCustomerStore();
  const redirectUrl = '/customer-missing-email';
  if (context.app.$vsf.$magento.config.state.getCustomerToken()) {

    if (customerStore.user) {
      // @ts-ignore
      if (customerStore.user.has_dummy_email && (customerStore.user.alias_email === '' || customerStore.user.alias_email === null)) {
        context.redirect(redirectUrl);
      }
    }
  }
};
export default middleware;
