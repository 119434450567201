import {ref} from "@vue/composition-api";

interface ConfirmationModal {
  title: string,
  subline?: string,
  text?: string,
  agreeButtonText?: string,
  cancelButtonText?: string,
  agreeButtonTextScreenReader?: string,
  cancelButtonTextScreenReader?: string,
  closeHandler?: () => void,
  confirmHandler: () => void
}

const confirmationModal = ref({
  title: "",
  subline: "",
  text: "",
  agreeButtonText: "Bestätigen und schließen",
  cancelButtonText: "Abbrechen",
  agreeButtonTextScreenReader: "",
  cancelButtonTextScreenReader: "",
  closeHandler: () => showConfirmationModal(false),
  confirmHandler: () => {}
})

const isConfirmationModalVisible = ref(false)

function setConfirmationModal(confirmationModalObject: ConfirmationModal){
  confirmationModal.value = {...confirmationModal.value, ...confirmationModalObject}
  showConfirmationModal(true)
}

function showConfirmationModal(show: boolean) {
  isConfirmationModalVisible.value = show
}

export function useConfirmationModal () {

  return {
    confirmationModal,
    setConfirmationModal,
    showConfirmationModal,
    isConfirmationModalVisible
  };
}

