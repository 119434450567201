import {useApi} from "~/composables";

export const getProfiles = () => {
  const {
    query,
  } = useApi();

  const getProfilesQuery = `
    query getProfiles {
      profiles {
        id
        profile_name
        is_default
        user_id
        company
        text_field
        street
        postcode
        city
        phone
        email
        additional_address_data
        website
      }
    }
  `;

  const loadProfiles = async () => {
    return await query(
      getProfilesQuery
    )
  }

  return {
    loadProfiles,
  };
}
