import { ref } from '@nuxtjs/composition-api'
import { useProductList } from '../useProductList'
import { ListView } from '~/types/ListView'

export const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1240,
  '2xl': 1240,
}

const windowWidth = ref<number>(0)
const list = useProductList()
const breakpointFunctions = generateBreakpointFunctions()
const smKey = 'sm'

if (typeof window !== 'undefined' && window.isResizeHandlerRegistered === undefined) {
  window.isResizeHandlerRegistered = false
}

if (typeof window !== 'undefined' && window.isResizeHandlerRegistered === false) {
  window.onresize = () => {
    windowWidth.value = window.innerWidth
    list.view.value = breakpointFunctions[smKey]() || list.view.value === ListView.LIST ? ListView.LIST : ListView.GALLERY
  }
}

function generateBreakpointFunctions () {
  const breakpointFunctions = {}
  const screenEntries = Object.entries(screens)
  for (const [index, [breakpoint, size]] of screenEntries.entries()) {
    breakpointFunctions[breakpoint] = () => windowWidth.value <= size && windowWidth.value > (screenEntries[index - 1]?.[1] || -1)

    if (index > 0) {
      breakpointFunctions[`${breakpoint}OrLess`] = () => windowWidth.value <= size
    }

    if (index < screenEntries.length - 1) {
      breakpointFunctions[`${breakpoint}OrGreater`] = () => windowWidth.value > size
    }
  }
  return breakpointFunctions
}

export function useBreakpoints () {
  if (typeof window !== 'undefined') {
    windowWidth.value = window.innerWidth
  }

  return { screens, ...breakpointFunctions }
}

declare global {
  interface Window {
    isResizeHandlerRegistered?: boolean;
  }
}
