var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"downloadPDFButton searchResultProductDownloadBtn dsb-product-actions__action",class:_vm.buttonClasses,attrs:{"aria-label":_vm.$t(((_vm.PageCategory.PRODUCT) + ".screenreader_" + (_vm.action.type)), {
        name: _vm.name,
      }),"disabled":!_vm.isEnabled || _vm.downloadInProgress || !_vm.active},on:{"click":_vm.handleDownload}},[_c('span',{staticClass:"dsb-product-actions__action-icon dsb-icon-left",class:( _obj = {}, _obj[_vm.action.icon] = true, _obj['text-sf-c-text-disabled cursor-default'] =  !_vm.active, _obj )},[_vm._v("\n      "+_vm._s(_vm.$t(((_vm.PageCategory.PRODUCT) + "." + (_vm.action.type))))+"\n    ")])]),_vm._v(" "),_c('DsbConfirmationModal',{attrs:{"visible":_vm.licenceTermsModalVisible,"bg-image-class":"timer-bg-image","title":_vm.licenceTerms.title,"subline":_vm.$t(((_vm.PageCategory.PRODUCT) + "." + (_vm.ProductGeneral.LICENCE_END_DATE)), {
        name: _vm.productGetters.getName(_vm.product),
        licenceEndDate: _vm.formattedLicenseDate(),
      }),"text":_vm.licenceTerms.text,"agree-button-text-screenreader":((_vm.productGetters.getName(_vm.product)) + " Bildrechte bestätigen und schließen")},on:{"close":function($event){_vm.licenceTermsModalVisible = false},"agree":function($event){return _vm.agreeToLicense()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }