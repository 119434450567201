import { ProductAttributeFilterInput } from '~/modules/GraphQL/types';
import type { ComposableFunctionArgs } from '~/composables';
import type { FacetSearchParams } from '../useFacet';

export const rangeFilters = ['price'];
export const versionUpdatedAtFilter = ['version_updated_at'];
export const recentlyUpdatedFilter = ['recently-updated'];

export function createProductAttributeFilterInput(params: ComposableFunctionArgs<FacetSearchParams>, isSearchPage: Boolean): ProductAttributeFilterInput {
  const attributeFilter : Record<string, { from: unknown, to: unknown } | { eq: unknown } | { in: unknown }> = {};
  const inputFilters = params?.filters ?? {};

  const categoryFilter = {
    category_uid: { in: [params.category_uid, ...inputFilters.category_uid ?? []] },
  };

  Object.keys(inputFilters).forEach((key: string) => {
    if (rangeFilters.includes(key)) {
      const range = {from: 0, to: 0};
      const flatValue = inputFilters[key]
        .flatMap((inputFilter) => inputFilter.split('_'))
        .map((str: string) => Number.parseFloat(str))
        .sort((a, b) => a - b);

      [range.from] = flatValue;
      range.to = flatValue[flatValue.length - 1];

      attributeFilter[key] = range;
    } else if (versionUpdatedAtFilter.includes(key)) {
      const range = {from: '', to: ''};

      range.from = inputFilters[key]['from'] ?? ''
      attributeFilter[key] = range;
    } else if ( recentlyUpdatedFilter.includes(key)) {
      const range = {from: '', to: ''};

      let today = new Date();
      const priorDate = new Date(today.setDate(today.getDate() - 3))
      const date = priorDate.toISOString().replace(/T\d+:\d+:.*Z/g, '')

      range.from = date ?? ''
      attributeFilter['version_updated_at'] = range;
    } else if (typeof inputFilters[key] === 'string') {
      attributeFilter[key] = { eq: inputFilters[key] };
    } else if (inputFilters[key].length === 1) {
      attributeFilter[key] = { eq: inputFilters[key][0] };
    } else {
      attributeFilter[key] = { in: inputFilters[key] };
    }
  });

  if(isSearchPage){
    return { ...attributeFilter };
  }
  return { ...attributeFilter, ...categoryFilter };
}
