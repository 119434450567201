import {MagentoProduct} from "~/types/Product";
import productGetters from "~/modules/catalog/product/getters/productGetters";

export function mapBaseProduct(product: MagentoProduct) {
  return {
    ...product,
    url: productGetters.getUrl(product),
    text: productGetters.getDescription(product),
    imgSrc: productGetters.getProductThumbnailImage(product),
  }
}
