import {Middleware} from '@nuxt/types';
import extendQueryParams from "~/modules/deeplink/helper/deeplinkQueryParams";
import {useCustomerStore} from "~/modules/customer/stores/customer";
import jwtDecode from "jwt-decode";
import {Logger} from "~/helpers/logger";

const middleware: Middleware = (context) => {
  if (!context.app.$vsf.$magento.config.state.getCustomerToken()) {
    const loginPage = context.localeRoute({name: 'login'});
    let path = extendQueryParams(loginPage.path, context.route)

    const customerStore = useCustomerStore()
    customerStore.setIsLoggedIn(false)

    context.redirect(path);
  }else{
    try {
      const nowDate = new Date();
      nowDate.setSeconds(nowDate.getSeconds() + 10);
      const now = nowDate.getTime();

      const decodedToken = jwtDecode<any>(context.app.$vsf.$magento.config.state.getCustomerToken());
      const tokenExpirationInMs = decodedToken.exp * 1000;

      if (
        tokenExpirationInMs === null ||
        tokenExpirationInMs < now
      ) {
        const customerStore = useCustomerStore()
        customerStore.setIsLoggedIn(false)
        customerStore.user = null;
        context.app.$vsf.$magento.config.state.removeCustomerToken();
        context.app.$vsf.$magento.config.state.removeCartId();
      }
    } catch (exception) {
      Logger.error('middleware/is-authenticated', exception);
    }
  }
};
export default middleware;
