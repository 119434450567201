/* eslint-disable  */
import de from '../locales/de'

export default {
  "About us": "Über uns",
  "Account": "Konto",
  "Add new address": "Neue Adresse hinzufügen",
  "Add to compare": "Hinzufügen zum vergleichen",
  "Add to Wishlist": "Zur Wunschliste hinzufügen",
  "Additional Information": "Zusätzliche Information",
  "All Orders": "Alle Bestellungen",
  "Allow order notifications": "Bestellbenachrichtigungen zulassen",
  "Apply": "Übernehmen",
  "Applied Coupon": "Angewendeter Gutschein",
  "Attention!": "Attention!",
  "Back to home": "Zurück Zur Startseite",
  "Back to homepage": "Zurück zur Startseite",
  "Billing": "Abrechnung",
  "Billing address": "Rechnungsanschrift",
  "Brand": "Marke",
  "Cancel": "Abbrechen",
  "Cart": "Warenkorb",
  "Categories": "Kategorien",
  "Change": "Ändern",
  "Change password your account": "Wenn Sie das Passwort ändern möchten, um auf Ihr Konto zuzugreifen, geben Sie die folgenden Informationen ein",
  "Change to grid view": "Zur Rasteransicht wechseln",
  "Change to list view": "Zur Listenansicht wechseln",
  "Clear all": "Alles löschen",
  "Color": "Farbe",
  "Commercial information": "und erklären sich damit einverstanden, personalisierte Handelsinformationen vom Markennamen per E-Mail zu erhalten",
  "Contact details updated": "Halten Sie Ihre Adressen und Kontaktdaten auf dem neuesten Stand.",
  "Contact us": "Kontaktiere uns",
  "Continue to billing": "Weiter zur Abrechnung",
  "Continue to payment": "Weiter zur Zahlung",
  "Continue to shipping": "Weiter zum Versand",
  "Cookies Policy": "Cookie-Richtlinie",
  "Create an account": "Konto erstellen",
  "Customer Reviews": "Kundenbewertungen",
  "Customer service": "Kundendienst",
  "Date": "Datum",
  "Default Billing Address": "Standard-Rechnungsadresse",
  "Default Shipping Address": "Standard-Lieferadresse",
  "Delete": "Löschen",
  "Departments": "Abteilungen",
  "Description": "Beschreibung",
  "Details and status orders": "Überprüfen Sie die Details und den Status Ihrer Bestellungen im Online-Shop. Sie können Ihre Bestellung auch stornieren oder eine Rücksendung anfordern. ",
  "Discount": "Rabatt",
  "Done": "Fertig",
  "Download": "Herunterladen",
  "Download all": "Alle herunterladen",
  "Edit": "Bearbeiten",
  "Email address": "E-Mail Adresse",
  "Empty": "Sieht so aus, als hätten Sie der Tasche noch keine Artikel hinzugefügt. Beginnen Sie mit dem Einkaufen, um es auszufüllen.",
  "Empty bag": "Leere Tasche",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Geben Sie den Promo-Code ein",
  "Feedback": "Ihr Feedback ist uns wichtig. Lassen Sie uns wissen, was wir verbessern können.",
  "Feel free to edit": "Fühlen Sie sich frei, Ihre unten stehenden Daten zu bearbeiten, damit Ihr Konto immer auf dem neuesten Stand ist",
  "Filters": "Filter",
  "Find out more": "Finde mehr heraus",
  "First Name": "Vorname",
  "Forgot Password": "Wenn Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen.",
  "Forgot Password Modal Email": "E-Mail, mit der Sie sich anmelden:",
  "forgotPasswordConfirmation": "Vielen Dank! Wenn ein Konto mit der E-Mail-Adresse {0} registriert ist, finden Sie in Ihrem Posteingang eine Nachricht mit einem Link zum Zurücksetzen des Passworts.",
  "Forgotten password?": "Passwort vergessen?",
  "Go back": "Geh zurück",
  "Go back shopping": "Zurück einkaufen",
  "Go back to shop": "Zurück zum Einkaufen",
  "Go to checkout": "Zur Kasse gehen",
  "Guarantee": "Garantie",
  "Help": "Hilfe",
  "Help & FAQs": "Hilfe & FAQs",
  "hide": "ausblenden",
  "Home": "Startseite",
  "I agree to": "Ich stimme zu",
  "I confirm subscription": "Ich bestätige das Abonnement",
  "I want to create an account": "Ich möchte ein Konto erstellen",
  "Info after order": "Sie können sich mit E-Mail und definiertem Passwort in Ihrem Konto anmelden vorhin. Überprüfen Sie in Ihrem Konto Ihre Profildaten Transaktionsverlauf, Abonnement für Newsletter bearbeiten.",
  "Instruction1": "Um mich kümmern",
  "Instruction2": "Nur hier für die Pflegehinweise?",
  "Instruction3": "Ja, das haben wir uns gedacht",
  "It was not possible to request a new password, please check the entered email address.": "Es war nicht möglich, ein neues Passwort anzufordern, bitte überprüfen Sie die eingegebene E-Mail-Adresse.",
  "Item": "Artikel",
  "Items": "Gegenstände",
  "Kidswear": "Kinderkleidung",
  "Last Name": "Nachname",
  "Let’s start now – we’ll help you": "Fangen wir jetzt an - wir helfen Ihnen.",
  "Log into your account": "In dein Konto einloggen",
  "Login": "Anmeldung",
  "login in to your account": "Anmelden bei Ihrem Konto",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Sieht so aus, als hätten Sie der Tasche noch keine Artikel hinzugefügt. Beginnen Sie mit dem Einkaufen, um es auszufüllen.",
  "Looks like you haven’t added any items to the Wishlist.": "Sieht so aus, als hätten Sie keine Artikel zur Wunschliste hinzugefügt.",
  "Make an order": "Bestellung aufgeben",
  "Make an order with costs": "Kostenpflichtig bestellen",
  "Manage addresses": "Verwalten Sie alle gewünschten Adressen (Arbeitsplatz, Privatadresse...) Auf diese Weise müssen Sie die Adresse nicht bei jeder Bestellung manuell eingeben.",
  "Manage billing addresses": "Alle gewünschten Rechnungsadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Rechnungsadresse nicht bei jeder Bestellung manuell eingeben.",
  "Manage shipping addresses": "Alle gewünschten Versandadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Versandadresse nicht bei jeder Bestellung manuell eingeben.",
  "Match it with": "Kombiniere es mit",
  "Men fashion": "Herrenmode",
  "Menu": "Menü",
  "My billing and shipping address are the same": "Meine Rechnungs- und Lieferadresse sind identisch",
  "My Cart": "Mein Warenkorb",
  "New Products": "Neue Produkte",
  "No account": "Sie haben noch keinen Account?",
  "or": "oder",
  "or fill the details below": "oder füllen Sie die Details unten",
  "Order ID": "Bestellung ID",
  "Order information": "Bestellinformationen",
  "Order No.": "Bestellnummer",
  "Order summary": "Bestellübersicht",
  "Other products you might like": "Andere Produkte, die Ihnen gefallen könnten",
  "Out of stock": "Ausverkauft",
  "Password": "Passwort",
  "Password Changed": "Passwort erfolgreich geändert. Sie können nun zur Startseite zurückkehren und sich anmelden.",
  "Pay for order": "Für Bestellung bezahlen",
  "Payment": "Bestellabschluss",
  "Payment & Delivery": "Zahlung & Lieferung",
  "Payment Method": "Zahlungsmethode",
  "Payment Methods": "Zahlungsmethoden",
  "Personal details": "Persönliche Daten",
  "Please type your current password to change your email address.": "Bitte geben Sie Ihr aktuelles Passwort ein, um Ihre E-Mail-Adresse zu ändern.",
  "Price": "Preis",
  "Privacy": "Datenschutz",
  "Privacy Policy": "Datenschutzrichtlinie",
  "Product": "Produkt",
  "Product description": "Das Karissa V-Neck Tee hat eine halb taillierte Form schmeichelhaft für jede Figur. Sie können mit ins Fitnessstudio gehen Vertrauen, während es Kurven umarmt und häufiges \"Problem\" verbirgt Bereiche. Finden Sie atemberaubende Cocktailkleider für Frauen und feiern Sie Kleider.",
  "Product suggestions": "Produktvorschläge",
  "Products": "Produkte",
  "Products found": "Produkte gefunden",
  "Purchase terms": "Kaufbedingungen",
  "Quality in the details": "Qualität im Detail",
  "Quantity": "Menge",
  "Read all reviews": "Alle Bewertungen lesen",
  "Read and understand": "Ich habe das gelesen und verstanden",
  "Read reviews": "Bewertungen lesen",
  "Register": "Registrieren",
  "Register today": "Melde dich noch heute an",
  "Remove": "Löschen",
  "Remove Address": "Adresse entfernen",
  "Remove from Wishlist": "Von Wunschliste entfernen",
  "Repeat Password": "Wiederhole das Passwort",
  "Reset Password": "Passwort zurücksetzen",
  "Review my order": "Meine Bestellung überprüfen",
  "Same as shipping address": "Wie Versandadresse",
  "Save changes": "Änderungen speichern",
  "Save for later": "Für später speichern",
  "Save Password": "Passwort Speichern",
  "Save Email": "E-Mail-Adresse speichern",
  "Search": "Suche",
  "Search for items": "Nach Artikeln suchen",
  "Search results": "Suchergebnisse",
  "Sections that interest you": "Abschnitte, die Sie interessieren",
  "See all": "Alle anzeigen",
  "See all results": "Alle Ergebnisse anzeigen",
  "See more": "Mehr sehen",
  "Select payment method": "Zahlungsmethode auswählen",
  "Select shipping method": "Versandart auswählen",
  "Send my feedback": "Senden Sie mein Feedback",
  "Set up newsletter": "Richten Sie Ihren Newsletter ein und wir senden Ihnen wöchentlich Informationen zu neuen Produkten und Trends aus den von Ihnen ausgewählten Bereichen",
  "Share your look": "Teile deinen Look",
  "Shipping": "Versand",
  "Shipping address": "Lieferanschrift",
  "Shipping details": "Versanddetails",
  "Shipping method": "Versandart",
  "Show":"Anzeigen",
  "show more": "mehr anzeigen",
  "Show on page": "Auf Seite anzeigen",
  "Sign in": "Einloggen",
  "Size guide": "Größentabelle",
  "Sign Up for Newsletter": "Anmeldung für Newsletter",
  "Sort by": "Sortieren nach",
  'Sort: Default': 'Popularität',
  'Sort: Name A-Z': 'Name aufsteigend',
  'Sort: Name Z-A': 'Name absteigend',
  'Sort: Price from high to low': 'Höchster Preis',
  'Sort: Price from low to high': 'Niedrigster Preis',
  "Start shopping": "Einkaufen starten",
  "Status": "Status",
  "Subscribe": "Abonnieren",
  "Subscribe to newsletter": "Anmeldung zum Newsletter",
  "subscribeToNewsletterModalContent": "Wenn Sie sich für den Newsletter angemeldet haben, erhalten Sie spezielle Angebote und Nachrichten von VSF per E-Mail. Wir werden Ihre E-Mail zu keinem Zeitpunkt an Dritte verkaufen oder weitergeben. Bitte beachten Sie unsere {0}.",
  "Subtotal": "Zwischensumme",
  "Subtotal price": "Zwischensumme Preis",
  "Successful placed order": "Sie haben die Bestellung erfolgreich aufgegeben. Sie können den Status Ihres Bestellen Sie über unsere Lieferstatusfunktion. Sie erhalten eine Bestellung Bestätigungs-E-Mail mit Details Ihrer Bestellung und einem Link zum Verfolgen der Bestellung Fortschritt.",
  "Terms and conditions": "Allgemeine Geschäftsbedingungen",
  "Thank you": "Danke",
  "Thank You Inbox": "Wenn die Nachricht nicht in Ihrem Posteingang ankommt, versuchen Sie es mit einer anderen E-Mail-Adresse, mit der Sie sich möglicherweise registriert haben.",
  "Total": "Gesamt",
  "Order Total": "Auftragssumme",
  "Total items": "Gesamtanzahl",
  "Total price": "Gesamtpreis",
  "Update password": "Passwort aktualisieren",
  "Update personal data": "Persönliche Daten aktualisieren",
  "Use your personal data": "Bei Markennamen legen wir großen Wert auf Datenschutzfragen und verpflichten uns, die persönlichen Daten unserer Benutzer zu schützen. Erfahren Sie mehr darüber, wie wir Ihre persönlichen Daten pflegen und verwenden",
  "User Account": "Benutzerkonto",
  "View": "Ansicht",
  "View details": "Details anzeigen",
  "Vue Storefront Next": "Vue Storefront Next",
  "We haven’t found any results for given phrase": "Wir haben keine Ergebnisse für die angegebene Phrase gefunden",
  "Who we are": "Wer wir sind",
  "Wishlist": "Wunschliste",
  "Women fashion": "Damenmode",
  "You added {product} to your shopping cart.": "Sie haben {product} zu Ihrem Warenkorb hinzugefügt.",
  "You are not authorized, please log in": "Sie sind nicht autorisiert, bitte melden Sie sich an",
  "You can unsubscribe at any time": "Sie können sich jederzeit abmelden",
  "You currently have no orders": "Sie haben derzeit keine Bestellungen",
  "The email field must be a valid email":"Das E-Mail-Feld muss eine gültige E-Mail-Adresse sein",
  "You haven’t searched for items yet": "Sie haben noch nicht nach Artikeln gesucht.",
  "Your bag is empty": "Ihre Tasche ist leer",
  "Your current email address is": "Ihre aktuelle E-Mail-Adresse lautet",
  "Your email": "Deine E-Mail",
  "You have submitted no reviews": "Sie haben keine Bewertungen abgegeben",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"Die Kontoanmeldung war falsch oder Ihr Konto wurde vorübergehend deaktiviert. Bitte warten Sie und versuchen Sie es später erneut.",
  "A customer with the same email address already exists in an associated website.": "Ein Kunde mit derselben E-Mail-Adresse existiert bereits auf einer verknüpften Website.",
  "Invalid email": "Ungültige E-Mail",
  "SUMMER COLLECTION {year}": "SOMMERKOLLEKTION {year}",
  "Colorful summer dresses are already in store": "Bunte Sommerkleider sind bereits im Store",
  "Learn more": "Mehr erfahren",
  "Dresses": "Kleider",
  "Cocktail & Party": "Cocktailparty",
  "Linen Dresses": "Leinenkleider",
  "T-Shirts": "T-Shirts",
  "The Office Life": "Das Büroleben",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Finden Sie atemberaubende Cocktailkleider für Damen und Cocktailkleider für Party-D…c von all Ihren Lieblingsmarken.",
  "Shop now": "Jetzt einkaufen",
  "Summer Sandals": "Sommersandalen",
  "Eco Sandals": "Öko-Sandalen",
  "Subscribe to Newsletters": "Newsletter abonnieren",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Informieren Sie sich über bevorstehende Verkäufe und Veranstaltungen. Erhalten Sie Geschenke und Sonderangebote",
  "Fashion to take away": "Mode zum Mitnehmen",
  "Download our application to your mobile": "Laden Sie unsere Anwendung auf Ihr Handy herunter",
  "Share Your Look": "Teilen Sie Ihren Look",
  "My Account": "Mein Konto",
  "My profile": "Mein Profil",
  "Personal Details": "Persönliche Daten",
  "Addresses details": "Adressendetails",
  "My newsletter": "Mein Newsletter",
  "Log out": "Ausloggen",
  "My reviews": "Meine Bewertungen",
  "Order history": "Bestellverlauf",
  "Order details": "Bestelldetails",
  "My wishlist": "Meine Wunschliste",
  "Password change": "Passwortänderung",
  "Personal data": "Persönliche Daten",
  "Your e-mail": "Deine E-Mail",
  "Current Password": "Jetziges Passwortd",
  "You are not authorized, please log in.": "Sie sind nicht autorisiert, bitte melden Sie sich an.",
  "Go To Product": "Zum Produkt",
  "Returns":"Rücklauf",
  "My orders": "Meine Bestellungen",
  "Add the address": "Fügen Sie die Adresse hinzu",
  "Set as default shipping": "Als Standardversand festlegen",
  "Set as default billing": "Als Standardabrechnung festlegen",
  "House/Apartment number": "Haus-/Wohnungsnummer",
  "Street Name": "Straßenname",
  "City": "Stadt",
  "State/Province": "Staat/Provinz",
  "Zip-code": "Postleitzahl",
  "Country": "Land",
  "Phone number": "Telefonnummer",
  "Please select a country first": "Bitte wählen Sie zuerst ein Land aus",
  "This field is required": "Dieses Feld ist erforderlich",
  "The field should have at least 2 characters": "Das Feld sollte mindestens 2 Zeichen lang sein",
  "The field should have at least 4 characters": "Das Feld sollte mindestens 4 Zeichen lang sein",
  "The field should have at least 8 characters": "Das Feld sollte mindestens 8 Zeichen lang sein",
  "New Password": "Neues Passwort",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Für dieses Land sind keine Versandarten verfügbar. Es tut uns leid, bitte versuchen Sie es mit einem anderen Land oder später.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "Beim Versuch, Versandarten abzurufen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit anderen Versanddetails oder später.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Beim Versuch, diese Versandart auszuwählen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit einer anderen Versandart.",
  "We can't find products matching the selection.":"Wir können keine Produkte finden, die der Auswahl entsprechen.",
  'Page not found': 'Seite nicht gefunden',
  'Back to Home page': 'Zurück zur Startseite',
  'An error occurred': 'Ein Fehler ist aufgetreten',
  "AllProductsFromCategory": "Alle {categoryName}",
  "Show more": "Zeig mehr",
  "Show less": "Zeige weniger",
  "Yes": "Ja",
  "No": "Nein",
  "Apply filters": "Filter anwenden",
  "The coupon code isn't valid. Verify the code and try again.": "Der Gutscheincode ist ungültig. Überprüfen Sie den Code und versuchen Sie es erneut.",
  "From": "Aus",
  "To": "Zu",
  "Your customization": "Ihre Anpassung",
  "Passwords don't match":"Passwörter stimmen nicht überein",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens enthalten: 1 Groß- oder Kleinbuchstabe, 1 Ziffer oder ein Sonderzeichen (z. B. , . _ & ? usw.)",
  "Show all products":"Alle Produkte anzeigen",
  "Select previously saved address":"Zuvor gespeicherte Adresse auswählen",
  "Enter different address":"Geben Sie eine andere Adresse ein",
  "You must confirm your account. Please check your email for the confirmation link.": "Sie müssen Ihr Konto bestätigen. Bitte überprüfen Sie Ihre E-Mail auf den Bestätigungslink.",
  "Change Store":"Laden Wechseln",
  "Choose Currency":"Währung wählen",
  "Add a review":"Bewertung hinzufügen",
  "Add to cart":"Zum Warenkorb",
  "Title":"Titel",
  "Name":"Name",
  "Review":"Rezension",
  "Add review":"Bewertung hinzufügen",
  "Are you sure you would like to remove this item from the shopping cart?":"Sind Sie sicher, dass Sie diesen Artikel aus dem Warenkorb entfernen möchten?",
  "Your cart is empty":"Ihr Warenkorb ist leer",
  "Are you sure?":"Bist du dir sicher?",
  "{0} has been successfully removed from your cart":"{0} wurde erfolgreich aus Ihrem Warenkorb entfernt",
  "Amount":"Menge",
  "Thank you for your order!":"Vielen Dank für Ihren Auftrag!",
  "Your Purchase":"Ihr Einkauf",
  "Primary contacts for any questions":"Primäre Ansprechpartner für alle Fragen",
  "Your Account":"Ihr Konto",
  "What can we improve":"Was können wir verbessern",
  "Payment date":"Zahlungsdatum",
  "The user password was changed successfully updated!":"Das Benutzerpasswort wurde erfolgreich geändert aktualisiert!",
  "The user account data was successfully updated!":"Die Benutzerkontodaten wurden erfolgreich aktualisiert!",
  "download action response returned invalid JSON":"Beim Download ist ein Fehler aufgetreten.",
  "PDF not found":"Das PDF ist noch nicht verfügbar.",
  "The product's required option(s) weren't entered. Make sure the options are entered and try again.":"Die erforderliche(n) Option(en) des Produkts wurden nicht eingegeben. Stellen Sie sicher, dass die Optionen eingegeben sind, und versuchen Sie es erneut.",
  "There are no shipping methods available for this country. We are sorry, please try with a different country.":"Es konnten keine Versandarten abgerufen werden. Überprüfen Sie Ihre eingegebene Adresse.",
  "Cannot set properties of undefined (setting 'entered_options')":"Bitte in der Detailsicht des Artikels die Felder ausfüllen.",

  // Payment errors
  "Unable to place order:":"Bestellung kann nicht aufgegeben werden:",
  "Unsuccessful Authentication":"Authentifizierung fehlgeschlagen",
  "Bank system temporarily unavailable. Please review you payment details and try again later.": "Das Banksystem ist vorübergehend nicht verfügbar. Bitte überprüfen Sie Ihre Zahlungsdaten und versuchen Sie es später erneut.",
  "Expired Card.": "Abgelaufene Karte.",
  "Unauthorized sale. Your card limit was exceeded.": "Nicht autorisierter Verkauf. Ihr Kartenlimit wurde überschritten.",
  "Unauthorized sale. Please contact card issuer.": "Nicht autorisierter Verkauf. Bitte wenden Sie sich an den Kartenaussteller.",
  "Unknown error or communication problem with PSP.": "Unbekannter Fehler oder Kommunikationsproblem mit PSP.",
  "Possible card misuse or fraud attempt detected.": "Möglicher Kartenmissbrauch oder Betrugsversuch erkannt.",
  "Capturing failed.": "Die Erfassung ist fehlgeschlagen.",
  "Operation cancelled by user": "Vorgang vom Benutzer abgebrochen",
  "Currency not supported by payment provider": "Währung wird vom Zahlungsanbieter nicht unterstützt",

  "Validation Error: CardNumber": "Überprüfungsfehler: Kartennummer",
  "Validation Error: ExpiryDate": "Überprüfungsfehler: Verfallsdatum",
  "Validation Error: CardHolder": "Überprüfungsfehler: Karteninhaber",
  "Validation Error: CVC": "Überprüfungsfehler: CVC",
  "Validation Error: Please enter a valid shipping city": "Überprüfungsfehler: Bitte geben Sie eine gültige Versandstadt ein",
  "Validation Error: Please enter a valid shipping country": "Überprüfungsfehler: Bitte geben Sie ein gültiges Lieferland ein",
  "Validation Error: Please enter a valid shipping email": "Überprüfungsfehler: Bitte geben Sie eine gültige Versand-E-Mail ein",
  "Validation Error: Please enter a valid shipping first name": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie einen gültigen Vornamen für den Versand ein",
  "Validation Error: Please enter a valid shipping last name": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie einen gültigen Nachnamen für den Versand ein",
  "Validation Error: Please enter a valid shipping phone number": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie eine gültige Telefonnummer für den Versand ein",
  "Validation Error: Please enter a valid shipping state": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie ein gültiges Lieferland ein",
  "Validation Error: Please enter a valid shipping street 1": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie eine gültige Versandstraße 1 ein",
  "Validation Error: Please enter a valid shipping street 2": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie eine gültige Versandstraße 2 ein",
  "Validation Error: Please enter a valid shipping postal code": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie eine gültige Postleitzahl für den Versand ein",
  "Validation Error: Please enter a valid shipping address": "Überprüfungsfehler: Bitte geben Sie eine gültige Lieferadresse ein",
  "Validation Error: Please enter a valid billing address": "Überprüfungsfehler: Bitte geben Sie eine gültige Rechnungsadresse ein",
  "Validation Error: Please enter a valid billing city": "Überprüfungsfehler: Bitte geben Sie eine gültige Rechnungsadresse ein",
  "Validation Error: Please enter a valid billing country": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie ein gültiges Land für die Rechnungsstellung ein",
  "Validation Error: Please enter a valid billing email": "Überprüfungsfehler: Bitte geben Sie eine gültige E-Mail-Adresse für die Rechnungsstellung ein",
  "Validation Error: Please enter a valid billing first name": "Überprüfungsfehler: Bitte geben Sie einen gültigen Vornamen für die Rechnungsstellung ein",
  "Validation Error: Please enter a valid billing last name": "Überprüfungsfehler: Bitte geben Sie einen gültigen Nachnamen für die Rechnungsstellung ein",
  "Validation Error: Please enter a valid billing phone number": "Überprüfungsfehler: Bitte geben Sie eine gültige Telefonnummer für die Rechnungsstellung ein",
  "Validation Error: Please enter a valid billing state": "Überprüfungsfehler: Bitte geben Sie einen gültigen Abrechnungsstatus ein",
  "Validation Error: Please enter a valid billing street 1": "Überprüfungsfehler: Bitte geben Sie eine gültige Abrechnungsstraße 1 ein",
  "Validation Error: Please enter a valid billing street 2": "Überprüfungsfehler: Bitte geben Sie eine gültige Abrechnungsstraße 2 ein",
  "Validation Error: Please enter a valid billing postal code": "Fehler bei der Validierung: Bitte geben Sie eine gültige Rechnungspostleitzahl ein",
  "Validation Error: Please enter a valid datetime value": "Überprüfungsfehler: Bitte geben Sie einen gültigen Datumswert ein",
  "Validation Error: Please enter a valid numeric value": "Überprüfungsfehler: Bitte geben Sie einen gültigen numerischen Wert ein",
  "Validation Error: Please enter a valid country code value": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie einen gültigen Ländercode ein",
  "Validation Error: Please enter a valid currency code value": "Fehler bei der Gültigkeitsprüfung: Bitte geben Sie einen gültigen Wert für den Währungscode ein",
  "Validation Error: Please enter a valid language code value": "Überprüfungsfehler: Bitte geben Sie einen gültigen Wert für den Sprachcode ein",
  "Validation Error: Please enter a valid CPF_CNPJ code value": "Überprüfungsfehler: Bitte geben Sie einen gültigen CPF_CNPJ-Code ein",


  "Riskcheck address suggestion available": "Riskcheck Adressvorschlag vorhanden",
  "Rislcheck address could not be validated": "Rislcheck-Adresse konnte nicht validiert werden",
  "Riskcheck payment not available": "Riskcheck-Zahlung nicht verfügbar",
  "Riskcheck payment rejected": "Riskcheck-Zahlung abgelehnt",
  "Accountcheck payment account data invalid": "Accountcheck-Zahlung Kontodaten ungültig",
  "Empty request received": "Leere Anfrage erhalten",
  "Missing or invalid operation": "Fehlender oder ungültiger Vorgang",
  "Missing or invalid order": "Fehlender oder ungültiger Auftrag",
  "Missing or invalid MerchantId": "Fehlende oder ungültige MerchantId",
  "Missing or invalid Amount": "Fehlender oder ungültiger Betrag",
  "Missing or invalid Card number": "Fehlende oder ungültige Kartennummer",
  "Missing or invalid Customer IP address": "Fehlende oder ungültige IP-Adresse des Kunden",
  "Missing or invalid card expiry date": "Fehlendes oder ungültiges Gültigkeitsdatum der Karte",
  "Missing or invalid merchant reference": "Fehlende oder ungültige Händlerreferenz",
  "Missing or invalid payment": "Fehlende oder ungültige Zahlung",
  "Paypal error": "Paypal-Fehler",
  "Paypal Authentication error": "Paypal-Authentifizierungsfehler",
  "Paypal transaction refused": "Paypal-Transaktion abgelehnt",
  "Authentication error : invalid card": "Authentifizierungsfehler: ungültige Karte",
  "Authentication error : invalid data": "Authentifizierungsfehler: ungültige Daten",
  "Authentication error : missing field": "Authentifizierungsfehler: fehlendes Feld",
  "Authentication error : card not enrolled": "Authentifizierungsfehler: Karte nicht registriert",
  "Authentication error : card not validated": "Authentifizierungsfehler: Karte nicht validiert",
  "Authentication error : wrong PARES": "Authentifizierungsfehler : falsche PARES",
  "Transaction has been already cancelled": "Die Transaktion wurde bereits storniert",
  "Transaction has been cancelled by the bank": "Die Transaktion wurde von der Bank storniert",
  "Invalid payment method selected for this merchant": "Ungültige Zahlungsmethode für diesen Händler ausgewählt",
  "No payment methods found for this country code": "Keine Zahlungsmethoden für diesen Ländercode gefunden",
  "No payment methods found for this merchant": "Es wurden keine Zahlungsmethoden für diesen Händler gefunden",
  "Order already exists": "Auftrag existiert bereits",
  "Transaction has been refused by the bank": "Die Transaktion wurde von der Bank abgelehnt",
  "Transaction scenario not succesfull : contact GlobalCollect PSP": "Transaktionsszenario nicht erfolgreich: Kontaktieren Sie GlobalCollect PSP",


  "Paypal error invalid billing address": "Paypal-Fehler ungültige Rechnungsadresse",
  "Paypal error invalid billing city": "Paypal-Fehler ungültige Rechnungsstellung Stadt",
  "Paypal error invalid billing state": "Paypal-Fehler ungültige Rechnungsstellung Staat",
  "Paypal error invalid billing zip code": "Paypal-Fehler ungültige Rechnungsstellung Postleitzahl",
  "Paypal error invalid billing country code": "Paypal-Fehler: Ungültige Landesvorwahl für die Rechnungsstellung",
  "Paypal error invalid shipping address": "Paypal-Fehler ungültige Lieferadresse",

  "Paypal error invalid shipping city": "Paypal-Fehler ungültig Versand Stadt",
  "Paypal error invalid shipping state": "Paypal-Fehler ungültig Versand Staat",
  "Paypal error invalid shipping zip code": "Paypal-Fehler: Ungültige Postleitzahl für den Versand",
  "Paypal error invalid shipping country code": "Paypal-Fehler: Ungültige Landesvorwahl für den Versand",
  "Paypal error shipping address field is empty": "Paypal-Fehler: Das Feld für die Lieferadresse ist leer",
  "Paypal error shipping city field is empty": "Paypal Fehler Versand Stadt Feld ist leer",
  "Paypal error shipping state field is empty": "Paypal error shipping state Feld ist leer",
  "Paypal error shipping zip code field is empty": "Paypal Fehler Versand Postleitzahl Feld ist leer",
  "Paypal error shipping country code field is empty": "Paypal Fehler Versand Ländercode Feld ist leer",

  ...de
};
