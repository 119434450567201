export enum LoginGeneral {
  TITLE = 'general_title',
  INTRO_TEXT = 'general_intro_text'
}

export enum LoginLink {
  SSO= 'link_sso',
  CONTINUE = 'link_continue',
  CONTINUE_WITH_NUMBER = 'link_continue_number',
  CONTINUE_WITH_NUMBER_SCREENREADER = 'link_continue_number_screenreader',
  CONTINUE_AS_INTERN = 'link_continue_as_intern',
  APPLY_FOR_MEMBERSHIP = 'link_apply_for_membership',
  WRITE_EMAIL = 'link_write_email',
  MAILTO = 'link_mailto',
}

export enum LoginEnterId {
  INTERN_INTRO='intern_info',
  KNOWN_ID = 'info_known_id',
  SELECT_INFO_ID = 'select_info_id',
  SELECT_LABEL_ID = 'select_label_id',
  SELECT_PLACEHOLDER_ID = 'select_placeholder_id',
  INPUT_INFO_ID = 'input_info_id',
  INPUT_LABEL_ID = 'input_label_id',
  INPUT_PLACEHOLDER_ID = 'input_placeholderl_id',
}

export enum LoginError {
  SOMETHING_HAPPENED='error_something_happened',
  MAYBE_THIS_HELPS='error_maybe_this_helps',
  CUSTOMER_NOT_FOUND='customer-not-found',
  GENERAL_ERROR='general',
  INVALID_TOKEN='invalid-token',
  REQUEST_ERROR='request-error',
}
