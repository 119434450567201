import {useApi} from "~/composables";
import {defaultPageSize} from "~/modules/catalog/category/composables/useFacet/perPageOptions";

export const getSavedCarts = () => {
  const {
    query,
  } = useApi();

  const shoppingLists = `
  query shoppingLists($pageSize: Int = ${defaultPageSize}, $currentPage: Int = 1) {
    shoppingLists(pageSize: $pageSize, currentPage: $currentPage) {
      total_count
      page_info {
        current_page
        page_size
        total_pages
      }
      entities {
        total_quantity
        created_at
        shoppinglist_entity_id
        shoppinglist_name
        shopping_list_items {
          shoppinglist_item_id
          quantity
          product {
            sku
            name
            short_description {
              html
            }
            is_singular_version
            price_range {
              maximum_price {
                final_price {
                  currency
                  value
                }
                regular_price {
                  currency
                  value
                }
              }
              minimum_price {
                final_price {
                  currency
                  value
                }
                regular_price {
                  currency
                  value
                }
              }
            }
            thumbnail {
              url
              label
              position
              disabled
              __typename
            }
          }
          error
        }
      }
    }
  }`;



  const getSavedCartsQuery = async (variables) => {
    return await query(
      shoppingLists,
      variables
    )
  }

  return {
    getSavedCartsQuery,
  };
};
