import {
  readonly,
  Ref,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import { ComposableFunctionArgs } from '~/composables/types';
import { Logger } from '~/helpers/logger';
import {
  UseForgotPasswordResults,
  UseForgotPasswordErrors,
  UseForgotPasswordResetParams,
  UseForgotPasswordSetNewParams,
  UseForgotPasswordInterface,
} from '~/modules/customer/composables/useForgotPassword/useForgotPassword';

/**
 * Allows to request a password reset email and to set a new password to a user.
 *
 * Se the {@link UseForgotPasswordInterface} for a list of methods and values available in this composable.
 */
export function useForgotPassword(): UseForgotPasswordInterface {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const result: Ref<UseForgotPasswordResults> = ref({
    resetPasswordResult: null,
    setNewPasswordResult: null,
  });
  const error: Ref<UseForgotPasswordErrors> = ref({
    request: null,
    setNew: null,
  });

  // eslint-disable-next-line @typescript-eslint/require-await,consistent-return
  const resetPassword = async (resetPasswordParams: ComposableFunctionArgs<UseForgotPasswordResetParams>) => {
    Logger.debug('useForgotPassword/request', resetPasswordParams.email);

    try {
      loading.value = true;
      Logger.debug('[Magento]: Reset user password', resetPasswordParams);
      // eslint-disable-next-line max-len
      /*const {data} = await app.context.$vsf.$magento.api.requestPasswordResetEmail({
        email: resetPasswordParams.email,
        recaptchaToken: resetPasswordParams.recaptchaToken
      }, resetPasswordParams?.customQuery ?? null);*/

      // workaround to get not empty response
      const REQUEST_PASSWORD_CUSTOM_MUTATION = `
      mutation requestPasswordResetEmail($email: String!){
        requestPasswordResetEmail(email: $email)
      }`

      const requestPasswordVariables = {
        email: resetPasswordParams.email,
        recaptchaToken: resetPasswordParams.recaptchaToken,
      }

      const {data, errors} = await app.context.$vsf.$magento.api.customMutation({
        mutation: REQUEST_PASSWORD_CUSTOM_MUTATION,
        mutationVariables: requestPasswordVariables
      });

      Logger.debug('[Result]:', {data, errors});
      result.value.resetPasswordResult = data?.requestPasswordResetEmail ?? false;

      if (!data?.resetPassword) {
        console.log(errors)
        if (errors) {
          error.value.request = errors[0]
        } else {
          error.value.setNew = {name: 'setNew', message: 'Es war nicht möglich, ein neues Passwort anzufordern, bitte überprüfen Sie die eingegebene E-Mail-Adresse.'};
        }
      }
    } catch (err) {
      error.value.request = err;
      Logger.error('useForgotPassword/request', err);
    } finally {
      loading.value = false;
    }
  };

  const setNewPassword = async (setNewPasswordParams: ComposableFunctionArgs<UseForgotPasswordSetNewParams>) => {
    Logger.debug('useForgotPassword/setNew', setNewPasswordParams);

    try {
      loading.value = true;

      /*
        const { data, errors } = await app.context.$vsf.$magento.api.resetPassword({
        email: setNewPasswordParams.email,
        newPassword: setNewPasswordParams.newPassword,
        resetPasswordToken: setNewPasswordParams.tokenValue,
        recaptchaToken: setNewPasswordParams.recaptchaToken,
      }, resetPasswordMutation);
      */

      // workaround to get not empty response
      const RESET_PASSWORD_CUSTOM_MUTATION = `
      mutation resetPassword($email: String!, $newPassword: String!, $resetPasswordToken: String!){
        resetPassword(email: $email, newPassword: $newPassword, resetPasswordToken: $resetPasswordToken)
      }`

      const resetPasswordVariables = {
        email: setNewPasswordParams.email,
        newPassword: setNewPasswordParams.newPassword,
        resetPasswordToken: setNewPasswordParams.tokenValue,
        recaptchaToken: setNewPasswordParams.recaptchaToken,
      }

      const {data, errors} = await app.context.$vsf.$magento.api.customMutation({
        mutation: RESET_PASSWORD_CUSTOM_MUTATION,
        mutationVariables: resetPasswordVariables
      });

      Logger.debug('[Result]:', {data, errors});
      result.value.setNewPasswordResult = data?.resetPassword ?? false;
      error.value.setNew = null;

      if (!data?.resetPassword) {
        if (errors) {
          error.value.setNew = errors[0]
        } else {
          error.value.setNew = {name: 'setNew', message: 'Leider ist ein Fehler aufgetreten. Versuchen sie es später erneut.'};
        }
      }
    } catch (err) {
      error.value.setNew = err;
      Logger.error('useForgotPassword/setNew', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    request: resetPassword,
    setNew: setNewPassword,
    loading: readonly(loading),
    result: readonly(result),
    error: readonly(error),
  };
}

export * from './useForgotPassword';
export default useForgotPassword;
