import {ref} from "@nuxtjs/composition-api";
import {addProductToCollection} from "~/modules/pdfCollection/composables/pdfCollection/addProductToCollection";

export function useSaveToCollection() {
  const error = ref('');
  const loading = ref(false)

  const {mutation: addProductToCollectionMutation} = addProductToCollection();
  const addItem = async (sku) => {
    const result = await addProductToCollectionMutation({"productSku": sku});

    if (result['errors'] !== undefined) {
      result['errors'].forEach(error => console.error(`graphql error: ${error.message}`));
      return
    }

    return result.data?.['addProductToPdfCollection']
  }

  return {
    loading,
    error,
    addItem,
  }
}
