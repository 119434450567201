import { useApi } from "~/composables";
import { ref } from "@nuxtjs/composition-api";
import { Logger } from "~/helpers/logger";

export const getInvalidLicensesCount = () => {
  const { query } = useApi();
  const loading = ref(false);

  const GET_LICENSES_QUERY = `
    query licenses($filter: LicenseAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1) {
      licenses(
        filter: $filter,
        pageSize: $pageSize,
        currentPage: $currentPage
      ) {
        total_count
      }
    }
  `;

  const load = async (params) => {
    try {
      Logger.debug("getInvalidLicensesCount/load");
      loading.value = true;

      return await query(GET_LICENSES_QUERY, params);
    } catch (error) {
      Logger.error("getInvalidLicensesCount/error", error);
    } finally {
      loading.value = false;
    }
  };

  return {
    load,
    loading,
  };
};
