import {nextTick} from "@nuxtjs/composition-api";

const focusableElements = 'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
const focusableElementsExclBreadcrumb = '' +
  'a[href]:not(.sf-breadcrumbs__breadcrumb), ' +
  'button:not(.sf-breadcrumbs__breadcrumb), ' +
  'input:not(.sf-breadcrumbs__breadcrumb), ' +
  'textarea:not(.sf-breadcrumbs__breadcrumb), ' +
  'select:not(.sf-breadcrumbs__breadcrumb), ' +
  'details:not(.sf-breadcrumbs__breadcrumb), ' +
  '[tabindex]:not([tabindex="-1"]):not(.sf-breadcrumbs__breadcrumb) '

function focusOnFirstInteractiveElementInMain() {
  nextTick(() => {
    const firstInteractiveElement = document.querySelector('#main-content').querySelector(focusableElements) as HTMLElement

    if (firstInteractiveElement) {
      firstInteractiveElement.focus();
    }
  })
}

function focusOnFirstInteractiveElementAfterBreadcrumb() {
  nextTick(() => {
    const firstInteractiveElement = document.querySelector('#main-content').querySelector(focusableElementsExclBreadcrumb) as HTMLElement

    if (firstInteractiveElement) {
      firstInteractiveElement.focus();
    }
  })
}

export function useFocusHelper() {

  return {
    focusOnFirstInteractiveElementInMain,
    focusOnFirstInteractiveElementAfterBreadcrumb
  }
}
