import { MyAddresses, MyDataGeneral, MyNotifications, MyProfiles } from "../../types/myData/myDataCategories";
import { General } from "../../types/Page";

export default {
  [General.TITLE]: "Meine Daten und Profile",

  [MyDataGeneral.DESCRIPTION]:
    "Hier finden Sie Ihre Stammadresse, weitere von Ihnen hinterlegte Lieferadressen sowie die von Ihnen erstellten Profile für die Personalisierung von PDF-Dokumenten.",

  [MyAddresses.HEADING]: "Meine Adressen",
  [MyAddresses.DESCRIPTION]:
    "Die Stammadresse kann nur über die vertragsführende Stelle (i.d.R. die Geschäftsstelle) geändert werden. Dies sind Ihre aktuell verfügbaren Adressen.",

  [MyProfiles.HEADING]: "Meine Profile",
  [MyProfiles.DESCRIPTION]:
    "Ihre Profile sind die Adressdaten mit denen Sie Dokumente personalisieren können. Personalisierte Dokumente werden mit den während der Personalisierung ausgewählten Daten bedruckt geliefert bzw. zum Download zur Verfügung gestellt.",

  [MyNotifications.HEADING]: "Benachrichtigungen",
  [MyNotifications.DESCRIPTION]: "Bei uns gibt es zwei Arten der Benachrichtigung.",
  [MyNotifications.DESCRIPTION_FAVORITES]:
    "Wenn Sie dieses Häkchen aktivieren, erhalten Sie bei jeder Versionsänderung zu Ihren ausgewählten Favoriten eine automatische E-Mail.",
  [MyNotifications.DESCRIPTION_REDELIVERY]:
    "Wenn Sie dieses Häkchen aktivieren, bekommen Sie eine automatische E-Mail, sobald offene Bestellpositionen nicht mehr nachgeliefert werden.",
};
