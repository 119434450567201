import {ref, useRoute} from "@nuxtjs/composition-api";
import {ProductDetailType} from "~/types/ProductData";
import {getPdfCollection} from "~/modules/pdfCollection/composables/pdfCollection/getPdfCollection";
import {Logger} from "~/helpers/logger";
import {mapPdfCollectionItemsToProductDetails} from "~/utils/mapProductToProductDetails";
import {removeProductFromPdfCollection} from "~/modules/pdfCollection/composables/pdfCollection/removeProductFromPdfCollection";
import {flushPdfCollection} from "~/modules/pdfCollection/composables/pdfCollection/flushPdfCollection";
import {AgnosticPagination} from "@vue-storefront/core";
import productGetters, {getProductThumbnailImage} from '~/modules/catalog/product/getters/productGetters';
import {collectionPageDescriptionMock} from '~/mocks/pdfCollection/collectionPageDescription.mock'
import {downloadPdfCollection} from "~/modules/pdfCollection/composables/pdfCollection/DownloadPdfCollection";
import {generatePdfCollectionLink} from "~/modules/pdfCollection/composables/pdfCollection/generateLinkToProductCollection";
import {perPageOptions} from "~/modules/catalog/category/composables/useFacet/perPageOptions";
import {usePdfCollectionStore} from "~/modules/pdfCollection/store/pdfCollectionStore";

export function useCollectionList() {
  const route = useRoute()

  const {loadPdfCollection} = getPdfCollection();
  const {mutation: flushPdfCollectionMutation} = flushPdfCollection();
  const {downloadCollection} = downloadPdfCollection();
  const {generateLink} = generatePdfCollectionLink();
  const {mutation: removeProductFromPdfCollectionMutation} = removeProductFromPdfCollection()

  const collectionItems = ref([]);
  const products = ref([]);
  const error = ref('');
  const loading = ref(false)
  const collectionPageDescription = collectionPageDescriptionMock

  const pdfCollectionStore = usePdfCollectionStore();

  const closeNotification = (): void => {
    notificationData.value.visible = false;
    notificationData.value.title = '';
    notificationData.value.message = '';
  }
  const notificationData = ref({title: '', message: '', visible: false, type: '', closeNotification: closeNotification});


  const details: ProductDetailType[] = [
    ProductDetailType.ARTICLE_NUMBER,
    ProductDetailType.VERSION_DOWNLOAD,
  ]

  const pagination = ref<AgnosticPagination>({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    itemsPerPage: perPageOptions[0],
    pageOptions: perPageOptions
  });


  if (route.value.query.itemsPerPage) {
    pagination.value.itemsPerPage = parseInt(route.value.query.itemsPerPage as string)
  }

  if (route.value.query.page) {
    pagination.value.currentPage = parseInt(route.value.query.page as string) || pagination.value.currentPage
  }

  const loadCollectionItems = async () => {
    Logger.debug(`usePdfCollections/loadCollectionItems`);
    loading.value = true

    collectionItems.value = []
    try {
      const variables = {pageSize: pagination.value.itemsPerPage, currentPage: pagination.value.currentPage};
      const data = await loadPdfCollection(variables);
      if (data.pdfCollection !== null) {
        collectionItems.value = data.pdfCollection?.items;

        products.value = collectionItems.value.map(item => ({
          ...item.product,
          sku: item.product.sku,
          name: item.product.name,
          url: productGetters.getUrl(item.product),
          text: item.product.short_description.html,
          imgSrc: getProductThumbnailImage(item.product),
          details: mapPdfCollectionItemsToProductDetails({...item.product}, details),
        }));

        pdfCollectionStore.products = products.value;

        pagination.value = {
          ...pagination.value,
          totalItems: data.pdfCollection?.total_count,
          itemsPerPage: data.pdfCollection?.page_info?.page_size,
          currentPage: data.pdfCollection?.page_info?.current_page,
          totalPages: Math.ceil(data.pdfCollection?.total_count / data.pdfCollection?.page_info?.page_size),
        }
      }
    } catch (e) {
      Logger.error(`usePdfCollections/loadCollectionItems`);
      error.value = 'Produkte konnten nicht geladen werden.'
    } finally {
      loading.value = false
    }

    return products.value;
  }

  const removeCollectionItemFromList = async (sku) => {
    const result = await removeProductFromPdfCollectionMutation({"productSku": sku});

    if (result['errors'] !== undefined) {
      result['errors'].forEach(error => console.error(`graphql error: ${error.message}`));
      return
    }

    return result
  }

  const deleteAllCollectionItems = async () => {
    const result = await flushPdfCollectionMutation();

    if (result['errors'] !== undefined) {
      result['errors'].forEach(error => console.error(`graphql error: ${error.message}`));
      return
    }

    return result
  }

  const downloadCollectionItems = async () => {
    let resultObject = {};
    await downloadCollection().then((result) => {
      if (result === 'error') {
        resultObject = {'type': 'error', 'message': 'Beim Herunterladen der Kollektion ist leider ein Fehler aufgetreten.'}
      }
    })
    return resultObject
  }

  const getLinkToCollection = async () => {
    let resultObject = {};
    await generateLink().then((result) => {
      if (result['message'] !== undefined) {
        resultObject = {'type': 'error', 'message': 'Beim Generieren des Kollektions-Links ist leider ein Fehler aufgetreten.'}
      } else {
        const downloadUrl = result["download_url"];
        if(downloadUrl !== undefined) {
          resultObject = {'type': 'success', 'message': downloadUrl}
        } else {
          resultObject = {'type': 'error', 'message': 'Beim Generieren des Kollektions-Links ist leider ein Fehler aufgetreten.'}
        }
      }
    })
    return resultObject;
  }

  return {
    collectionPageDescription,
    pagination,
    loadCollectionItems,
    removeCollectionItemFromList,
    deleteAllCollectionItems,
    downloadCollectionItems,
    getLinkToCollection,
    generateLink,
    products,
    loading,
    error,
    notificationData
  }
}
