import {ref} from "@nuxtjs/composition-api";
import {reactive} from "@vue/composition-api";
import {getProfiles} from "~/modules/web2print/composables/profiles/getProfiles";
import {createProfile} from "~/modules/web2print/composables/profiles/createProfile";
import {updateProfile} from "~/modules/web2print/composables/profiles/updateProfile";
import {deleteProfile} from "~/modules/web2print/composables/profiles/deleteProfile";


export function useProfiles() {
  const loading = ref(false)
  const load = ref(false)
  const error = reactive({fetchProfiles: null})
  const {loadProfiles} = getProfiles();

  async function fetchProfiles() {
    let result = await loadProfiles();

    if (result?.['errors'] !== undefined) {
      result['errors'].forEach(error => console.log(`graphql error: ${error.message}`));
    }

    return result.data?.['profiles']
  }

  const {mutation: createProfileMutation} = createProfile()

  async function save(profile) {
    const result = await createProfileMutation(profile);

    if (result['errors'] !== undefined) {
      result['errors'].forEach(error => console.error(`graphql error: ${error.message}`));
    }

    return result
  }

  const {mutation: deleteProfileMutation} = deleteProfile()

  async function remove(profile) {
    const result = await deleteProfileMutation(
      {
        id: profile.id,
      }
    );

    if (result['errors'] !== undefined) {
      result['errors'].forEach(error => console.error(`graphql error: ${error.message}`));
    }

    return result
  }

  const {mutation: updateProfileMutation} = updateProfile()

  const tempId = ref(null)

  async function update(profile) {
    const id = profile.input.id || tempId.value
    tempId.value = id
    delete profile.input.id

    const result = await updateProfileMutation(
      {
          id,
          input: profile.input
      }
    );

    if (result['errors'] !== undefined) {
      result['errors'].forEach(error => console.error(`graphql error: ${error.message}`));
    }

    return result
  }

  return {
    loading,
    load,
    error,
    fetchProfiles,
    save,
    remove,
    update
  }
}
