export enum MyDataGeneral {
  DESCRIPTION = 'favorites_description',
}

export enum MyAddresses {
  HEADING = 'my_addresses_heading',
  DESCRIPTION = 'my_addresses_description'
}

export enum MyProfiles {
  HEADING = 'my_profiles_heading',
  DESCRIPTION = 'my_profiles_description'
}

export enum MyNotifications {
  HEADING = 'my_notification_heading',
  DESCRIPTION = 'my_notification_description',
  DESCRIPTION_FAVORITES = 'my_notification_description_favorites',
  DESCRIPTION_REDELIVERY = 'my_notification_description_redelivery'
}
