



















import { PropType, ref, defineComponent } from "@nuxtjs/composition-api";
import { SfModal, SfButton } from "@storefront-ui/vue";
import { Action } from "~/types/Action";
import { PageCategory } from "~/types/PageCategory";
import { useDownloadAction } from "~/composables";
import { ProductGeneral } from "~/types/ProductCategories";
import { useErrorMessage } from "~/composables/useErrorMessage";
import {useInvalidLicense} from "~/modules/license/composables/useInvalidLicenses";
import DsbConfirmationModal from "~/components/Modal/DsbConfirmationModal.vue";

export default defineComponent({
  name: "AcceptLicenseAction",
  components: { SfModal, SfButton, DsbConfirmationModal },
  props: {
    product: {
      required: true,
      type: Object,
    },
    name: {
      required: true,
      type: String,
    },
    action: {
      required: true,
      type: Object as PropType<Action>,
    },
  },
  emits: ['accept-license'],
  setup(props) {
    const { acknowledgeLicenseAgreement } = useDownloadAction(
      "actions_" + props.product.sku
    );
    const isEnabled = ref(true);
    const errorMessage = ref("");

    const licenseDialogIsVisible = ref(false);
    const licenseMessage = ref("");

    const { setErrorMessage } = useErrorMessage("actions_" + props.product.sku);

    const {fetchInvalidLicenceProducts} = useInvalidLicense();

    const acceptLicense = async () => {
      licenseMessage.value = "";
      errorMessage.value = "";
      setErrorMessage({ name: "download", message: "" });

      licenseDialogIsVisible.value = false;
      isEnabled.value = true;

      await acknowledgeLicenseAgreement(props.product.sku)
      await fetchInvalidLicenceProducts()
    };

    return {
      isEnabled,
      errorMessage,
      licenseMessage,
      licenseDialogIsVisible,
      acceptLicense: acceptLicense,
      PageCategory,
      ProductGeneral,
    };
  },
});
