import {ProductDetailType} from "~/types/ProductData";
import {mapProductToProductDetails} from "~/utils/mapProductToProductDetails";
import {ActionType} from "~/types/Action";
import {CartItemInterface} from "~/modules/checkout/types";
import productGetters, {getProductThumbnailImage} from "~/modules/catalog/product/getters/productGetters";
import {computed} from "@nuxtjs/composition-api";

export function mapCartItem(cartItem: CartItemInterface, hasPriceVisible = false) {

  const actions = [
    ActionType.QUANTITY,
    ActionType.REMOVE_FROM_CART,
    ActionType.DETAILS,
  ]

  const productDetailTypes = (computed (() => {
    let details = [
      ProductDetailType.ARTICLE_NUMBER,
      ProductDetailType.VERSION_PRINT,
      ProductDetailType.AVAILABILITY
    ]

    if (hasPriceVisible) {
      details.push(ProductDetailType.PRICE_INCL_ZERO)
    }

    return details
  }))

  return {
    sku: cartItem.product.sku,
    uid: cartItem.uid,
    name: cartItem.product.name,
    url: productGetters.getParentUrl(cartItem.product),
    text: cartItem.product.short_description?.html || cartItem.product.description?.html || '',
    imgSrc: getProductThumbnailImage(cartItem.product),
    details: mapProductToProductDetails(cartItem.product, productDetailTypes.value),
    actions,
    quantity: cartItem.quantity,
    totalPrice: cartItem.prices?.row_total.value,
    customizable_options: cartItem.customizable_options?.filter((option) => option.type !== 'checkbox') || []
  }
}
