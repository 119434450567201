import {useShopApi} from "~/composables";
import {AxiosError, AxiosResponse} from "axios";
// @ts-ignore
import {DownloadResponse} from '~/types/Download'
import {Logger} from "~/helpers/logger";


export function generatePdfCollectionLink() {
  const {shopApi} = useShopApi();
  const generateLink = (): Promise<any> => {
    return new Promise<any>((resolve) => {
      Logger.debug('generatePdfCollectionLink/generateLink');
      const generateLinkUrl = `/index.php/pdf-collection/collection/link`;

      shopApi.get(generateLinkUrl).then((response: AxiosResponse<any>) => {
        resolve(response.data)
      }).catch((error: AxiosError<any>) => {
        resolve(error.response.data)
      })
    })
  }

  return {
    generateLink
  };
}
