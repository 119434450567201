import { General, SearchListItem, SearchResults, Category } from '../../types/search/SearchCategories'
import { General as PageGeneral } from "../../types/Page";
import { PageCategory } from '../../types/PageCategory'
import { ActionType } from '../../types/Action'

export default {
  [PageGeneral.TITLE]: 'Suchergebnisse',

  [General.SEARCH]: 'Suche | Suchen',
  [General.SEARCH_CONCRETE_TITLE]: 'Konkrete Suchergebnisse',
  [General.SEARCH_OPEN]: 'Suche öffnen',
  [General.SEARCH_CLOSE]: 'Suche schließen',
  [General.SEARCH_LABEL]: 'Suchbegriff',
  [General.SEARCH_INPUT]: 'Suchbegriff eingeben ...',
  [General.SEARCH_INPUT_LONG]: 'Suchbegriff eingeben ...',
  [General.SEARCH_CLEAR]: 'Suchbegriff löschen',
  [General.SEARCH_RECENTLY_UPDATED]: 'Neue / geänderte Artikel',

  [Category.SEARCH]: 'Suchen',
  [Category.SEARCH_INPUT]: 'In den Ergebnissen suchen',

  [SearchListItem.MORE]: 'Details',
  [SearchListItem.SCREENREADER_MORE]: '{name} Produktseite',

  [SearchResults.SEARCH_SUGGESTIONS]: 'Suchvorschläge',
  [SearchResults.AUTOCOMPLETE]: `@:${PageCategory.SEARCH}.${SearchResults.SEARCH_SUGGESTIONS} (Autocomplete)`,
  [SearchResults.HISTORY]: 'Deine letzten Suchanfragen',
  [SearchResults.PRODUCTS]: 'Produkte',
  [SearchResults.COLLECTIONS]: 'Kollektionen',
  [SearchResults.SHOW_ALL_RESULTS]: 'Alle Ergebnisse anzeigen',

  [ActionType.ADD_TO_CART]: 'In den Warenkorb',
  [ActionType.PERSONALIZE]: 'Personalisieren',
  [ActionType.DOWNLOAD]: 'Download',
  [ActionType.ADD_TO_COLLECTION]: 'Zu Kollektion hinzufügen',
  [ActionType.FAVORITE]: 'Favorit',
  [ActionType.PREVIEW]: 'Vorschau',
}
