import {useApi} from "~/composables";

export const setShippingAddressesOnCart = () => {
  const {
    mutate
  } = useApi();

  const setShippingAddressesOnCartQuery =`
    mutation setShippingAddressesOnCart($input: SetShippingAddressesOnCartInput) {
    setShippingAddressesOnCart(input: $input) {
      cart {
        shipping_addresses {
          city
          company
          firstname
          lastname
          address_line_2
          address_line_3
          sap_name
          postcode
          street
          telephone
          country {
            code
            label
          }
          available_shipping_methods {
            amount {
              currency
              value
            }
            available
            carrier_code
            carrier_title
            error_message
            method_code
            method_title
            price_excl_tax {
              currency
              value
            }
            price_incl_tax {
              currency
              value
            }
          }
        }
      }
    }
  }`;

  const mutation = async (variables) => {
    return await mutate(
      setShippingAddressesOnCartQuery,
      variables
    )
  }

  return {
    mutation,
  };
};
