import {useApi} from "~/composables";

export const updateProfile = () => {
  const {
    mutate
  } = useApi();

  const updateProfileQuery =`
    mutation updateProfile($id: Int!, $input: createProfileInput) {
      updateProfile(id: $id, input: $input) {
        id
        profile_name
        is_default
        user_id
        company
        text_field
        street
        postcode
        city
        phone
        email
        additional_address_data
        website
      }
    }
  `;

  const updateProfile = async (variables) => {
    return await mutate(
      updateProfileQuery,
      variables
    )
  }

  return {
    mutation: updateProfile,
  }
}
